'use strict';

/**
 * @description Form label functionality to show/hide input label
 * * @returns {void}
 */
function initInlineLabels() {
    var $inputs = $('.inline-label input');

    $inputs.focus(function () {
        $(this).parent().find('.form-control-label').addClass('label-active');
    });

    $inputs.focusout(function () {
        $(this).parent().find('.form-control-label').removeClass('label-active');
    });

    // make labels visible in inputs if the area is autofilled
    $($inputs).each(function () {
        var $this = $(this),
            $inputType = $this.attr('type');
        // don't apply label-active class if there is a hidden input with a value on it
        if ($inputType === 'text') {
            if ($this.val() !== '') {
                $this.parent().find('.form-control-label').addClass('label-active');
            }
        }
    });
}

module.exports = function () {
    initInlineLabels();
};
