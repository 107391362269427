'use strict';

var utils = require('./utils');

var $customDropdownToggle = '.custom-dropdown-toggle';
var $mobileSelectContainer = '.mobile-select-container';
var $mobileSelect = '.mobile-select';
var $customDropdown = '.custom-dropdown';
var searchChar = '';
var timer = null;

var createDesktopMenuHeader = function (groupName) {
    var newHeader = document.createElement('h6');

    newHeader.setAttribute('class', 'dropdown-header');
    newHeader.textContent = groupName;

    return newHeader;
};

var createDesktopMenuOption = function (option, $el) {
    var newMenuOp = document.createElement('div');
    var setButtonText = function (text) {
        if (option.selected) {
            $el.children($customDropdown).children($customDropdownToggle).text(text);
        }
    };

    // add 'dropdown-item' class to tie into jQuery dropdown logic
    newMenuOp.setAttribute('class', 'dropdown-item');

    // set the data option val, if value passed
    if (option.value) {
        newMenuOp.setAttribute('data-option-val', option.value);
    }

    // set the text content
    if (option.label) {
        newMenuOp.textContent = option.label;
        setButtonText(option.label);
    } else if (option.value) {
        newMenuOp.textContent = option.value;
        setButtonText(option.value);
    }

    // set the id, if passed
    if (option.id) {
        newMenuOp.setAttribute('id', option.id);
    }

    return newMenuOp;
};

var createMobileSelectOption = function (option) {
    var newSelectOp = document.createElement('option');

    // add 'dropdown-item' class to tie into jQuery dropdown logic
    newSelectOp.setAttribute('class', 'dropdown-item');

    // set value if passed
    if (option.value) {
        newSelectOp.setAttribute('value', option.value);
    }

    // set textContent based on attributes passed
    if (option.label) {
        newSelectOp.textContent = option.label;
    } else if (option.groupName && option.value) {
        newSelectOp.textContent = option.groupName + ' - ' + option.value;
    } else if (option.value) {
        newSelectOp.textContent = option.value;
    }

    // set id if passed
    if (option.id) {
        newSelectOp.setAttribute('id', option.id);
    }

    // set selected if passed
    if (option.selected) {
        newSelectOp.setAttribute('selected', option.selected);
    }

    return newSelectOp;
};

var createConfiguratorDesktopMenuOption = function (option, $el) {
    var newMenuOp = document.createElement('div');
    var setButtonText = function (text) {
        if (option.value.selected) {
            $el.children($customDropdown).children($customDropdownToggle).text(text);
        }
    };

    // set the text content
    if (option.value.displayValue) {
        newMenuOp.textContent = option.value.displayValue;
        setButtonText(option.value.displayValue);
    }

    // add 'dropdown-item' class to tie into jQuery dropdown logic
    newMenuOp.setAttribute('class', 'dropdown-item');

    if (option.value.displayValue && option.value.showPrice) {
        newMenuOp.textContent = option.value.displayValue + ' – starting at ' + option.value.price.sales.formatted;
    } else if (option.value.displayValue) {
        var note = option.value.note ? ' (' + option.value.note + ')' : '';
        newMenuOp.textContent = option.value.displayValue + note;
    }

    // set the id, if passed
    if (option.value.id) {
        newMenuOp.setAttribute('id', option.value.id);
        newMenuOp.setAttribute('data-option-val', option.value.id);
    }

    return newMenuOp;
};

var createConfiguratorMobileSelectOption = function (option) {
    var newSelectOp = document.createElement('option');

    // add 'dropdown-item' class to tie into jQuery dropdown logic
    newSelectOp.setAttribute('class', 'dropdown-item');

    // set value if passed
    if (option.value.displayValue) {
        newSelectOp.setAttribute('value', option.value.displayValue);
    }
    // set textContent based on attributes passed
    if (option.groupName && option.value.displayValue) {
        newSelectOp.textContent = option.groupName + ' - ' + option.value.displayValue;
    } else if (option.value.displayValue) {
        newSelectOp.textContent = option.value.displayValue;
    }

    // set id if passed
    if (option.value.id) {
        newSelectOp.setAttribute('id', option.value.id);
    }

    // set selected if passed
    if (option.value.selected) {
        newSelectOp.setAttribute('selected', option.value.selected);
    }

    return newSelectOp;
};

/*
* Creates dropdown DOM elements based on configurator data
*/
var buildConfiguratorDropdown = function ($dropDownEl) {
    var isScrollable = false;
    var isSharedLink = utils.getParameter('shared');
    var pidParam = utils.getParameter('pid');

    // desktop dropdown element
    var $dropdownmenu = $dropDownEl.children($customDropdown).children('.dropdown-menu');
    // mobile select element
    var $selectmenu = $dropDownEl.children($mobileSelectContainer).children($mobileSelect);
    var dropdownData = $dropDownEl.data('dropdown-data');
    var appendOption = function (v, groupName) {
        if ($dropDownEl.hasClass('sizedropdown')) {
            v.showPrice = true;
        }

        if ((isSharedLink && v.id === dropdownData.selectedValueId) || (isSharedLink && v.id === pidParam)) {
            v.selected = true;
        }

        $dropdownmenu.append(createConfiguratorDesktopMenuOption({ value: v }, $dropDownEl));

        if (groupName && $dropdownmenu.attr('show-group-name') === 'true') {
            $selectmenu.append(createConfiguratorMobileSelectOption({ value: v, groupName: groupName }));
        } else {
            $selectmenu.append(createConfiguratorMobileSelectOption({ value: v }));
        }

        if (!isScrollable) {
            // control overflow property for IE-11
            isScrollable = true;
            $dropdownmenu.removeClass('disable-scroll');
        }
    };
    if (dropdownData) {
        $dropDownEl.addClass('dropdown-inited');

        if (dropdownData.valueGroups) {
            Object.keys(dropdownData.valueGroups).forEach(function (group) {
                // ex. primary-finish.valueGroups.standard
                var currentGroup = dropdownData.valueGroups[group];
                // ex. 'Standard'
                var groupName = currentGroup.DisplayName;
                // ex. primary-finish.valueGroups.standard.values = [ 001 ]
                var values = currentGroup.Values;
                // create the 'dropdown-header' DOM elements
                $dropdownmenu.append(createDesktopMenuHeader(groupName));
                values.forEach(function (value) {
                    dropdownData.values.forEach(function (v) {
                        if (v.id === value && v.customerVisible) {
                            appendOption(v, groupName);
                        }
                    });
                });
            });
        } else if (dropdownData.values) {
            var values = dropdownData.values;
            values.forEach(function (v) {
                appendOption(v);
            });
        }
    }
};

/*
* Creates dropdown DOM elements based on a basic array or stringified array
*/
var buildBasicDropdown = function ($dropDownEl) {
    var isScrollable = false;
    // desktop dropdown element
    var $dropdownmenu = $dropDownEl.children($customDropdown).children('.dropdown-menu');
    // mobile select element
    var $selectmenu = $dropDownEl.children($mobileSelectContainer).children($mobileSelect);
    var dropdownData = $dropDownEl.data('dropdown-data');
    var createOptions = function (value) {
        $dropdownmenu.append(createDesktopMenuOption({ value: value }));
        $selectmenu.append(createMobileSelectOption({ value: value }));
        if (!isScrollable) {
            // control overflow property for IE-11
            isScrollable = true;
            $dropdownmenu.removeClass('disable-scroll');
        }
    };

    // handle variable type of dropdown-data
    // can take an array or comma seperated string

    if (typeof dropdownData === 'string') {
        dropdownData.split(',').forEach(function (value) {
            createOptions(value);
        });
    } else if (Array.isArray(dropdownData)) {
        dropdownData.forEach(function (value) {
            var optionValue = value.hasOwnProperty('label') ? value.label : value;

            createOptions(optionValue);
        });
    }
};

/*
* Toggles invalid class if present. Used to update look when dropdown changs
*/
var removeInvalidClass = function (container) {
    if (container.hasClass('invalid-dropdown')) {
        container.removeClass('invalid-dropdown');
    }
};

/**
 * Init dropdown
 */
function initializeDropDowns() {
    var $dropdownEls = $('.custom-dropdown-container').not('.address-dropdown');

    $dropdownEls.each(function () {
        var type = $(this).data('dropdown-type') || null;

        if ($(this).data('dropdown-data')) {
            if (type === 'configurator' && !$(this).hasClass('dropdown-inited')) {
                buildConfiguratorDropdown($(this));
            } else {
                buildBasicDropdown($(this));
            }
        }
    });
}

$(document).on('initDropDowns:reinit', initializeDropDowns);

module.exports = {
    initDropDowns: function () {
        initializeDropDowns();
    },

    setVal: function () {
        $(document).on('click', '.dropdown-item:not(.address-dropdown-item)', function () {
            let dropdownmenu = $(this).parent();

            if (dropdownmenu.hasClass('variation')) { return; }

            let optionVal = $(this).data('option-val'),
                dropdownContainer = dropdownmenu.parent(),
                mobileMenu = dropdownContainer.siblings($mobileSelectContainer).children($mobileSelect),
                selectedOpt = mobileMenu.find(`option[id="${optionVal}"]`);

            if (!mobileMenu.length) {
                mobileMenu = window.lastClickedDropdown;
            }

            // remove selected attribute from any children
            mobileMenu.children().removeAttr('selected');

            if (selectedOpt.length) {
                selectedOpt.prop('selected', true).attr('selected', true);
            } else {
                mobileMenu.val(optionVal);
            }

            dropdownContainer.children('button').val(mobileMenu.val()).text(mobileMenu.val());
            mobileMenu.trigger('change');
        });

        $(document).on('change', '.mobile-select:not(.addressSelector)', function () {
            var mobileSelectVal = $(this).val(),
                textVal = $('option:selected', this).text(),
                mobileSelectContainer = $(this).parent(),
                topLevelDropdownContainer = mobileSelectContainer.parent(),
                button = mobileSelectContainer.siblings($customDropdown).children($customDropdownToggle);

            // if no select value, set button html to "-"
            if (mobileSelectVal === '' || mobileSelectVal === null) {
                button.html('-');
            } else if (topLevelDropdownContainer.data('dropdown-type') === 'geo') {
                // due to country and state codes, use the textVal rather than the select's value
                button.text(textVal);
            } else {
                button.text(mobileSelectVal);
            }

            $(document).trigger('select:changed', [$(this)]);

            window.lastClickedDropdown = $(this);

            removeInvalidClass(topLevelDropdownContainer);
        });

        $(document).on('click', '.dropdown-item.address-dropdown-item', function () {
            var optionVal = $(this).data('option-val');
            var textVal = $(this).text();
            var parent = $(this).parent();
            var dropdownButton = parent.siblings('.dropdown-toggle');
            var customDropDownContainer = parent.parent();
            var mobileMenu = customDropDownContainer.siblings($mobileSelectContainer).children('.addressSelector');
            // set the button value and html to display
            dropdownButton.val(optionVal);
            dropdownButton.text(textVal);
            // set the mobile select value
            mobileMenu.val(optionVal);
            // call change to trigger form update functions
            mobileMenu.change();

            removeInvalidClass(customDropDownContainer.parent());
        });
        $(document).on('change', '.addressSelector.mobile-select', function () {
            var mobileSelectVal = $(this).val();
            var textVal = $('option:selected', this).text();
            var mobileSelectContainer = $(this).parent();
            var button = mobileSelectContainer.siblings($customDropdown).children($customDropdownToggle);

            // set the button value
            button.val(mobileSelectVal);

            // if no select value, set button html to -
            if (mobileSelectVal === '') {
                button.text('new address');
            } else {
                button.text(textVal);
            }

            removeInvalidClass(mobileSelectContainer.parent());
        });
    },

    setInitialVal: function () {
        var $dropdownEls = $('*[data-dropdown-type="geo"], *[data-dropdown-type="quantity"]');
        $dropdownEls.each(function () {
            var selected = $(this).children($mobileSelectContainer).children($mobileSelect).find('option:selected');

            if (selected) {
                // if select element has an option with select set on load, set the button text
                var selectedText = selected.text();
                $(this).children($customDropdown).children($customDropdownToggle).text(selectedText);
            }
        });
    },

    updateValidationStyle: function () {
        var target = $('.custom-dropdown-feedback');

        // create an observer instance
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var mobileselect = $(mutation.target).siblings('.mobile-select');
                var customdropdown = mobileselect.parent().siblings('.custom-dropdown');
                var desktopdropdown = customdropdown.children('.custom-dropdown-toggle');
                var label = customdropdown.siblings('label');

                desktopdropdown.addClass('is-invalid');
                label.addClass('is-invalid');
            });
        });

        var config = { attributes: true, childList: true, characterData: true };

        target.each(function (index, element) {
            observer.observe($(element)[0], config);
        });
    },

    handleKeyboard: function () {
        $(document).on('keypress', '.custom-dropdown-container, .state-wrapper', function (e) {
            e.stopPropagation();

            searchChar += String.fromCharCode(e.which).toUpperCase();

            var matches = $(this).find('option').filter(function () {
                    var text = $(this).text().trim().toUpperCase();
                    return text.startsWith(searchChar);
                }),
                mobileMenu = $(this).find($mobileSelectContainer).children($mobileSelect);


            if (matches.length > 0) {
                $(this).find('.custom-dropdown-toggle').trigger('click');

                var firstMatch = matches.first();
                var selectedOpt = mobileMenu.find(`option[id="${firstMatch.attr('id')}"]`);

                if (selectedOpt.length) {
                    // remove previously selected value
                    mobileMenu.children().removeAttr('selected');
                    selectedOpt.prop('selected', true).attr('selected', true);
                }

                mobileMenu.val(firstMatch.val());
                mobileMenu.change();
            }

            // allow multiple character search with timer, reset after 1 second
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }

            timer = setTimeout(function () {
                timer = null;
                searchChar = '';
            }, 1000);
        });
    }
};
