'use strict';

$(document).ready(function () {
    // Loops through double featured section on homepage hidden inputs to collect and set css styles
    var root = document.documentElement;
    var cssParamsItems = document.querySelectorAll('.cssParamsLoop .cssParamsItem');

    cssParamsItems.forEach(function (cssParamsItem) {
        var cssName = cssParamsItem.name;
        var cssValue = cssParamsItem.value;
        root.style.setProperty(
            cssName, cssValue
        );
    });
});

