module.exports = {
    getParameter: function (paramName) {
        var searchString = window.location.search.substring(1),
            i,
            val,
            params = searchString.split('&');

        for (i = 0; i < params.length; i++) {
            val = params[i].split('=');

            if (val[0] === paramName) {
                return val[1];
            }
        }
        return null;
    },
    removeParam: function (key, sourceURL) {
        var rtn = sourceURL.split('?')[0],
            param,
            paramsArr = [],
            queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
        if (queryString !== '') {
            paramsArr = queryString.split('&');
            for (var i = paramsArr.length - 1; i >= 0; i -= 1) {
                param = paramsArr[i].split('=')[0];
                if (param === key) {
                    paramsArr.splice(i, 1);
                }
            }
            rtn = rtn + '?' + paramsArr.join('&');
        }
        return rtn;
    },
    updateQueryStringParameter: function (uri, key, value) {
        var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        var separator = uri.indexOf('?') !== -1 ? '&' : '?';
        if (uri.match(re)) {
            return uri.replace(re, '$1' + key + '=' + value + '$2');
        }
        return uri + separator + key + '=' + value;
    },

    arraymove: function (arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }
};
