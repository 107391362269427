'use strict';

var base = require('../product/base');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * appends params to a url
 * @param {Object} product - product main view data object to pull data from
 * @returns {Object} price object with list and sales price
 */
function getProductPrice(product) {
    var price = 0;
    var listPrice = 0;

    if (product.price.hasOwnProperty('list') && product.price.list !== null && product.price.list !== 'undefined') {
        listPrice = product.price.list.value;
    }

    if (product.price.hasOwnProperty('sales') && product.price.sales !== 'undefined') {
        price = product.price.sales.value;
    }

    if (product.price.hasOwnProperty('type') && product.price.type === 'tiered') {
        price = product.price.startingFromPrice.sales.value;
    }

    if (product.price.hasOwnProperty('type') && product.price.type === 'range') {
        price = product.price.min.sales.value;
    }

    return {
        listPrice: listPrice,
        salesPrice: price
    };
}

/**
 * Updates the product data data attribute on the .product-detail.product-wrapper element for GTM
 * @param {Object} data - response from AJAX call to update product info
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateGTMData(data) {
    var lineItem,
        $productCard;

    for (var i = 0; i < data.items.length; i++) {
        lineItem = data.items[i];
        $productCard = $('.card.product-info.uuid-' + lineItem.UUID);

        if ($productCard.length > 0) {
            var oldData = $productCard.data('productData');
            var pricing = getProductPrice(lineItem);

            // combine old and new data
            var newData = $.extend({}, oldData, {
                id: lineItem.id,
                name: lineItem.productName,
                price: pricing.salesPrice,
                listPrice: pricing.listPrice,
                quantity: lineItem.quantity
            });

            $productCard.data('productData', newData);
        }
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="w-100"> ' +
                '<div class="col-12 text-center"> ' +
                '<p class="empty-cart">' + data.resources.emptyCartMsg + '</p> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
            $('body').trigger('updateMiniCartBubble', data.numItems);
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost .inner-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('body').trigger('updateMiniCartBubble', data.numItems);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });

    $('.estimated-time').empty().append(data.leadTime);

    $('.agent-order-discount').empty().append(data.totals.discountsHtml);
    if (data.totals.discounts.length) {
        $('.agent-order-discount').show();
    } else {
        $('.agent-order-discount').hide();
    }
}

/**
 * Renders a success alert message
 * @param {Object} message - Success message to display
 */
function createSuccessNotification(message) { // eslint-disable-line
    var errorHtml = '<div class="alert alert-success lert-dismissible  ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center alert alert-info" role="alert">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog modal-dialog-centered">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right icon-close" data-dismiss="modal">'
        + '        <span class="sr-only">Close</span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 * @param {boolean} isGiftCertificate - flag to check if it is a gift certificate
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html, isGiftCertificate) {
    var $html = $('<div>').append($.parseHTML(html));

    var body;
    if (isGiftCertificate) {
        body = $html.find('.product-gift-certificate');
    } else {
        body = $html.find('.product-quickview');
    }
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 * @param {boolean} isGiftCertificate - flag to check if it is a gift certificate
 */
function fillModalElement(editProductUrl, isGiftCertificate) {
    $('.modal-body').spinner().start();

    $('#editProductModal').on('shown.bs.modal', function () {
        $(document).trigger('editProductModal:shown');
        base.initEditModalCarousel();
    });

    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate, isGiftCertificate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('product:afterAddToCart', function () {
        var action = $('.page').data('action');

        if (action && action.length > 0 && action === 'Cart-Show') {
            location.reload();
        }
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        var $this = $(this);
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('.cart-line-items').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('product:afterRemoveFromCart', [$this.data()]);

                if (data.basket.items.length === 0 && data.basket.giftCertificateItems.length === 0) {
                    $('.cart').empty().append('<div class="w-100"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<p class="empty-cart">' + data.basket.resources.emptyCartMsg + '</p> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    $('.minicart .minicart-quantity').addClass('d-none');
                    $('body').trigger('updateMiniCartBubble', data.basket.resources.minicartCountOfItems);
                } else {
                    $('.cart-line-items').empty().append(data.basket.renderedTemplate);

                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }

                $('body').trigger('cart:update', {
                    hideMiniCart: true
                });
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.cart-wrapper').on('change', '.quantity-form > .quantity:not(.quantity-selectpdp)', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var $productCard = $(this).closest('.product-info');
        var gtmOptionData = {};

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $('.cart-line-items').spinner().start();

        if ($productCard && $productCard.data('productData') !== undefined) {
            gtmOptionData = $productCard.data('productData') ? $productCard.data('productData') : {};
        }

        gtmOptionData.quantity = (quantity && preSelectQty) ? Math.abs(quantity - preSelectQty) : 1;
        gtmOptionData.hideMiniCart = true;

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.cart-line-items').empty().append(data.renderedTemplate);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                updateGTMData(data);
                $(this).data('pre-select-qty', quantity);
                $('body').trigger('cart:update', [gtmOptionData]);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    if (err.responseJSON.errorMessage) {
                        if (err.responseJSON.pli) {
                            var $pliContainer = $('.cart-line-items .uuid-' + err.responseJSON.pli);
                            if ($pliContainer.find('.valid-qty-error').length) {
                                $pliContainer.find('.valid-qty-error').remove();
                            }
                            if ($pliContainer.length) {
                                var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error valid-qty-error ' +
                                    'fade show" role="alert">' +
                                    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                                    '<span aria-hidden="true">&times;</span>' +
                                    '</button>' + err.responseJSON.errorMessage + '</div>';
                                $pliContainer.find('.action-buttons-wrapper').before(errorHtml);
                            }
                        } else {
                            createErrorNotification(err.responseJSON.errorMessage);
                        }
                    }
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').css('display', 'block');
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    /**
+     * loop through cart products
+     * if matches is-custom-product then suppress
+     * (cut opacity, prevent selection) of dropdown item
+     */
    function onCartCustomProductSuppress() {
        if ($('.is-agent').text() === 'false') {
            $('.card.product-info').each(function () {
                var $self = $(this);
                if ($self.hasClass('is-custom-product') && $self.find('.custom-dropdown-toggle').length) {
                    $self.find('.custom-dropdown-toggle').addClass('suppress-controls').css({
                        opacity: 0.4,
                        'user-select': 'none',
                        'pointer-events': 'none'
                    });
                    $self.find('.quantity').prop('disabled', true);
                }
            });
        }
    }

    onCartCustomProductSuppress();

    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else if ($('.choose-bonus-product-dialog').length) {
            $.spinner().stop();
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity
        };

        $('.cart-line-items').spinner().start();

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    // wishlist edit should reload the page
                    if (data.wishlist) {
                        location.reload();
                    }

                    if (data.cartModel) {
                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        updateCartTotals(data.cartModel);
                        updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        updateAvailability(data.cartModel, uuid);

                        $('.cart-line-items').empty().append(data.cartModel.renderedTemplate);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        validateBasket(data.cartModel);

                        $('body').trigger('cart:update');
                    }

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    // handling edit gift certificate click
    $('body').on('click', '.cart-page .gift-card-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl, true);
    });

    /**
     * Save the current quote
     */
    function saveQuoteAction() {
        // Close 'add project name' form and display skip modal
        $('#addProjectName').find('.add-project-name-skip').attr('disabled', true);
        $(document).trigger('quote:saveQuote', {
            successCallback: function (data) {
                var reviewQuoteButton = $('#addProjectSuccessModal').find('a.review-quote-btn').first();
                reviewQuoteButton.attr('href', data.actionUrls.quoteDetailsUrl);

                $('body').trigger('gtm:quote', { handler: 'save', quote_id: data.quoteNo });

                $(document).trigger('quote:clearQuote', {
                    successCallback: function () {
                        $('#addProjectName').modal('hide');
                        $('#addProjectSuccessModal').modal({
                            show: true,
                            backdrop: 'static',
                            keyboard: false
                        });
                    },
                    errorCallback: function () {
                        $.spinner().stop();
                    }
                });
            },
            errorCallback: function (err) {
                $('#addProjectName').modal('hide');
                // Call global error response handler
                $(document).trigger('quote:error', err);
            }
        });
    }

    $(document).on('click', '.save-as-quote', function (e) {
        e.preventDefault();

        var billingNotesProjectName = $('#billingNotes').val() || '';
        // save as quote modal
        if (billingNotesProjectName === '' || billingNotesProjectName === 'null') {
            $('#addProjectName').modal('show');
        } else {
            saveQuoteAction();
        }
    });

    // Event to save entered project name  as the quote level note
    $(document).on('submit', '.add-project-name-form', function (e) {
        e.preventDefault();

        var projectName = $(this).find('#add-project-name-input').val() || '';
        $('#addProjectName').find('.save-quote-modal').attr('disabled', true);

        $(document).trigger('quote:saveQuote', {
            requestParams: {
                projectName: projectName
            },
            successCallback: function (data) {
                var reviewQuoteButton = $('#addProjectSuccessModal').find('a.review-quote-btn').first();
                reviewQuoteButton.attr('href', data.actionUrls.quoteDetailsUrl);

                $('body').trigger('gtm:quote', { handler: 'save', quote_id: data.quoteNo });

                $(document).trigger('quote:clearQuote', {
                    successCallback: function () {
                        $('#addProjectName').modal('hide');
                        $('#addProjectSuccessModal').modal({
                            show: true,
                            backdrop: 'static',
                            keyboard: false
                        });
                    },
                    errorCallback: function () {
                        $.spinner().stop();
                    }
                });
            },
            errorCallback: function (err) {
                $('#addProjectName').modal('hide');
                // Call global error response handler
                $(document).trigger('quote:error', err);
            }
        });
    });

    // Close 'add project name' form and display skip modal
    $(document).on('click', 'button.add-project-name-skip', function () {
        saveQuoteAction();
    });

    $(document).on('updateCartTotals', function (event, data) {
        updateCartTotals(data);
    });
};
