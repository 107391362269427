'use strict';

/**
 * @param {string} unitsClass - class
 * setUnitsClass
 */
function setUnitsClass(unitsClass) {
    var unitsEl = $('.units');

    if (unitsClass === 'metric' && (!unitsEl.hasClass('metric') || window.localStorage.getItem('units') === 'metric')) {
        unitsEl.addClass('metric');
        $('.unit-toggler-button-metric').removeClass('d-none');
        $('.unit-toggler-button-us').addClass('d-none');
    } else if (unitsClass === 'imperial' && (unitsEl.hasClass('metric') || window.localStorage.getItem('units') === 'imperial')) {
        unitsEl.removeClass('metric');
        $('.unit-toggler-button-metric').addClass('d-none');
        $('.unit-toggler-button-us').removeClass('d-none');
    }
}

/**
 * checkUnitsState
 */
function checkUnitsState() {
    var unitsEl = $('.units');
    var action = '';

    if (window.localStorage.getItem('units') === 'null' || window.sessionUnits.units === 'null') {
        var units = 'imperial';
        action = window.Urls.unitSet;

        $.ajax({
            url: action + units,
            type: 'get',
            dataType: 'json',
            success: function () {
                if (unitsEl.hasClass('metric')) {
                    unitsEl.removeClass('metric');
                }

                window.localStorage.setItem('units', units);
            }
        });
    } else {
        action = $('.unit-toggler-selection').data('action-get');

        $.ajax({
            url: window.Urls.unitGet,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                setUnitsClass(data.units);
            }
        });
    }
}

module.exports = function () {
    var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

    sizes.forEach(function (size) {
        var selector = '.collapsible-' + size + ' .title, .collapsible-' + size + '>.card-header';
        $(selector).parents('.collapsible-' + size).each(function () {
            $(this).find('.content.subcats li').css({
                height: 0
            });
        });
        $('body').on('click', selector, function (e) {
            e.preventDefault();
            var $this = $(this),
                $parent = $this.parents('.collapsible-' + size);

            $parent.toggleClass('active');

            var $subcatElement = $parent.find('.content.subcats li');
            if ($parent.hasClass('active')) {
                $subcatElement.css({
                    height: 25,
                    opacity: 1
                });
            } else {
                $subcatElement.css({
                    height: '0',
                    opacity: '0'
                });
            }

            if ($parent.hasClass('active')) {
                $(document).trigger('collapse:open');
                $this.attr('aria-expanded', true);
            } else {
                $(document).trigger('collapse:close');
                $this.attr('aria-expanded', false);
            }
        });
    });

    $('body').on('click', '.unit-selection', function () {
        var units = $(this).data('units');
        var action = $(this).parent().data('action-set');

        $.ajax({
            url: action + units,
            type: 'get',
            dataType: 'json',
            success: function () {
                setUnitsClass(units);
                window.localStorage.setItem('units', units);
            }
        });
    });

    checkUnitsState();
};
