'use strict';

var carouselConfig = require('../config/carousel');

/**
 * @package     Blueacorn/Carousel
 * @version     1.0
 * @author      Jamie Smith <jamie@blueacorn.com>
 * @copyright   Copyright © 2019 Blue Acorn.
 * @param {string} type - type of carousel to load, pulled from carousel.js config
 * @param {string} selector - selector class used to overwrite the carousel config selector value
 * @return {Object} Carousel
 */
module.exports = function (type, selector) {
    this.type = type;
    this.selector = selector || null;
    this.$element = null;
    this.config = {};

    this.settings = {
        moduleName: 'Carousel',
        initialized: false
    };

    this.setConfig = function (config) {
        var newConfig = {};
        if (typeof this.type === 'string') {
            newConfig = $.extend(config, carouselConfig.defaultSettings, carouselConfig[this.type].settings);
        } else {
            newConfig = $.extend(config, carouselConfig.defaultSettings);
        }

        this.config = newConfig;
    };

    this.init = function () {
        this.initialized = true;

        if (typeof this.type === 'string' && carouselConfig.hasOwnProperty(this.type) && !this.selector) {
            this.$element = $(carouselConfig[type].selector);
        } else if (typeof this.type === 'string' && carouselConfig.hasOwnProperty(this.type) && this.selector) {
            this.$element = $(this.selector);
        }

        return this;
    };

    this.activate = function () {
        if (!this.initialized) {
            this.init();
        }

        if (this.$element.data('carouselEnabled') === false || this.$element.length <= 0) {
            return;
        }

        this.setConfig(this.config);

        this.$element.slick(this.config);
    };

    return this.init();
};
