'use strict';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Generates the modal window on the first call. *
 */
function initQuoteModalHtmlElement() {
    if ($('#quoteModal').length !== 0) {
        $('#quoteModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quoteModal" tabindex="-1" role="dialog">'
        + '<div class="modal-dialog modal-dialog-centered quote-default-popup resuable-quote-modal" role="document">'
        + '<!-- Modal content-->'
        + '<div class="modal-content border-0">'
        + '<div class="modal-header border-0">'
        + '    <button type="button" class="close" data-dismiss="modal" aria-label="Close">'
        + '    </button>'
        + '</div>'
        + '<div class="modal-text-wrapper text-center">'
        + '<h4 class="modal-title">'
        + 'ATTENTION!'
        + '</h4>'
        + '<h5 class="modal-subtitle">'
        + '</h5>'
        + '</div>'
        + '<div class="modal-body text-center">'
        + '<div class="row row d-flex justify-content-center">'
        + '<button type="button" class="btn btn-primary close-button quote-modal-close-buton"  data-dismiss="modal" aria-label="Close">Close</button>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Generates modal HTML with passed message.
 *
 * @param {string} message Message to display
 */
function createQuoteNotification(message) {
    initQuoteModalHtmlElement();

    $('#quoteModal .modal-subtitle').empty();
    $('#quoteModal .modal-subtitle').text(message);
    $('#quoteModal').modal('show');
}

/**
 * Update minicart count and state with response data.
 *
 * @param {Object} data AJAX response data
 */
function updateMiniCart(data) {
    var minicart = $('.minicart');
    if (Object.hasOwnProperty.call(data, 'quantityTotal')) {
        minicart.trigger('count:update', data);
    }

    minicart.trigger('cart:update', { hideMiniCart: true });
}

/**
 * Handles successful AJAX request/response.
 *
 * @param {Object} data AJAX response data
 */
function handleQuoteSuccessResponse(data) {
    if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
    } else if (data.actionUrls && data.actionUrls.quoteDetailsUrl) {
        window.location.href = data.actionUrls.quoteDetailsUrl;
    } else if (data.message) {
        createQuoteNotification(data.message);
    }
}

/**
 * Handles unsuccessful AJAX request/response.
 *
 * @param {Object} err AJAX error data
 */
function handleQuoteErrorResponse(err) {
    if (err.responseJSON.loggedin === false) {
        $('body').trigger('display:loginModal');
    } else if (err.responseJSON.errorMessage) {
        createQuoteNotification(err.responseJSON.errorMessage);
    } else if (err.responseJSON.redirectUrl) {
        window.location.href = err.responseJSON.redirectUrl;
    }
}

/**
 * Takes passed configuration parameters and merges with defaults.
 *
 * @param {Object} event Triggered event
 * @param {Object} configuration Config object
 * @returns {Object} Prepared request configuration
 */
function prepareConfig(event, configuration) {
    var config = $.extend({
        continueUrl: null,
        requestParams: {},
        requestUrl: null,
        errorCallback: handleQuoteErrorResponse,
        successCallback: handleQuoteSuccessResponse
    }, configuration);

    if (!config.requestUrl) {
        var eventActionUrl = window.Urls[event.type.split(':').pop()] || '';
        config.requestUrl = appendToUrl(eventActionUrl, config.requestParams);
    }

    return config;
}

/**
 * Retrieves request data parameters from the element firing the event.
 *
 * @param {Object} element Element triggering event
 * @returns {Object} Element configuration data
 */
function getElementConfig(element) {
    var config = {
        requestParams: {}
    };

    var continueUrl = element.data('continue');
    if (continueUrl) config.continueUrl = continueUrl;

    var rurl = element.data('rurl');
    if (rurl) config.requestParams.rurl = rurl;

    var querystring = element.data('querystring');
    if (querystring) {
        var segments = querystring.split('&');
        segments.forEach(function (keyValueString) {
            var parameterArray = keyValueString.split('=');
            if (parameterArray.length === 1) {
                config.requestParams[parameterArray[0]] = true;
            } else {
                config.requestParams[parameterArray[0]] = parameterArray[1];
            }
        });
    }

    return config;
}

/**
 * Executes an AJAX request to one of the quote endpoints.
 *
 * @param {Object} event Triggered event
 * @param {Object} configuration Config object
 */
function executeQuoteAction(event, configuration) {
    var config = prepareConfig(event, configuration);
    $.ajax({
        url: config.requestUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.quantityTotal) updateMiniCart(data);
            config.successCallback(data);
        },
        error: function (err) {
            config.errorCallback(err);
        }
    });
}

module.exports = {

    initQuoteListeners: function () {
        $(document).on('quote:saveQuote quote:clearQuote quote:editQuote quote:loginStatus quote:request', executeQuoteAction);
        $(document).on('quote:error', function (event, err) {
            handleQuoteErrorResponse(err);
        });
        $(document).on('quote:success', function (event, data) {
            handleQuoteSuccessResponse(data);
        });
    },

    saveQuote: function () {
        // Event for previously created quote
        $('.cart-wrapper, .minicart, .quote-default-popup, .save-international-quote').on('click', '.save-quote-edited', function (element) {
            element.preventDefault();

            var billingNotesProjectName = $('#billingNotes').val() || '';
            // save as quote modal
            if (billingNotesProjectName === '' || billingNotesProjectName === 'null') {
                $('#addProjectName').modal('show');
            } else {
                var config = getElementConfig($(this));
                // Trigger quote save, clearing the cart on success
                $(document).trigger('quote:saveQuote', $.extend({
                    successCallback: function (saveData) {
                        // Clear quote and use save data in success response handling
                        $(document).trigger('quote:clearQuote', {
                            successCallback: function () {
                                if (config.continueUrl) {
                                    // If continue URL passed, call at the end of the chain
                                    $(document).trigger('quote:request', {
                                        requestUrl: config.continueUrl
                                    });
                                } else {
                                    // Else call the response handler with the save response data
                                    handleQuoteSuccessResponse(saveData);
                                }
                            }
                        });
                    }
                }, config));
            }
        });
    },

    saveMiniCartQuote: function () {
        // Event for previously created quote
        $('.minicart').on('click', '.save-minicart-quote-edited', function (element) {
            element.preventDefault();

            var config = getElementConfig($(this));
            // Trigger quote save, clearing the cart on success
            $(document).trigger('quote:saveQuote', $.extend({
                successCallback: function (saveData) {
                    $('body').trigger('gtm:quote', { handler: 'save', quote_id: saveData.quoteNo });

                    // Clear quote and use save data in success response handling
                    $(document).trigger('quote:clearQuote', {
                        successCallback: function () {
                            if (config.continueUrl) {
                                // If continue URL passed, call at the end of the chain
                                $(document).trigger('quote:request', {
                                    requestUrl: config.continueUrl
                                });
                            } else {
                                // Else call the response handler with the save response data
                                handleQuoteSuccessResponse(saveData);
                            }
                        }
                    });
                }
            }, config));
        });
    },

    clearQuote: function () {
        $('.cart-wrapper, .minicart, .quote-default-popup').on('click', '.clear-quotes-bth', function (element) {
            element.preventDefault();

            var config = getElementConfig($(this));
            // If continue URL passed, replace primary call success callback with secondary call
            if (config.continueUrl) {
                config.successCallback = function () {
                    $(document).trigger('quote:request', {
                        requestUrl: config.continueUrl
                    });
                };
            }
            // Trigger quote clear
            $(document).trigger('quote:clearQuote', config);
        });
    },

    onQuoteCustomProductSuppress: function () {
        if ($('.is-agent').text() === 'false') {
            $('.product-line-item').each(function () {
                var $self = $(this);
                if ($self.hasClass('is-custom-product') && $self.find('.custom-dropdown-toggle').length) {
                    $self.find('.custom-dropdown-toggle').addClass('suppress-controls').css({
                        opacity: 0.4,
                        'user-select': 'none',
                        'pointer-events': 'none'
                    });
                    $self.find('.quantity').prop('disabled', true);
                }
            });
        }
    },

    onExpirationDateChange: () => {
        $('.updatable-attr').on('change', function () {
            let btn = $(this).siblings('button')[0];

            this.classList.add('updated');
            btn.style.visibility = 'visible';
        });

        $('button#save-quote').on('click', function () {
            let that = this,
                $updatedAttrs = $('.updatable-attr.updated'),
                valid = true,
                data = {
                    quoteNo: $('#quote-no').text().trim(),
                    attrs: []
                };

            $updatedAttrs.each((idx, el) => { // eslint-disable-line
                valid = el.reportValidity();

                if (!valid) { return false; }

                data.attrs.push({ key: el.dataset.dataName, value: el.value });
            });

            if (valid) {
                $.ajax({
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    url: window.Urls.updateQuote,
                    data: JSON.stringify(data),
                    dataType: 'json'
                }).done(resData => {
                    if (resData.error) {
                        alert(resData.message); // eslint-disable-line
                    } else {
                        $updatedAttrs.removeClass('updated');
                        that.style.visibility = 'hidden';
                        alert('Quote successfully updated!'); // eslint-disable-line
                    }
                }).fail((jqXHR, status) => {
                    alert(status); // eslint-disable-line
                });
            }
        });
    }
};
