'use strict';

var cart = require('../cart/cart');

module.exports = function () {
    var action = $('.page').data('action');
    var $dropdown = $('.minicart .popover');

    if (action && action.length > 0 && action !== 'Cart-Show') {
        cart();
    }

    /**
     * Detemine Truncation value based on row heights
     *
     */
    function updateTruncation() {
        var firstRowHeight = $('.product-summary.truncated .row:nth-child(1)').outerHeight(),
            secondRowHeight = $('.product-summary.truncated .row:nth-child(2)').outerHeight(),
            thirdRowHeight = $('.product-summary.truncated .row:nth-child(3)').outerHeight(),
            firstThreeRowsTotal = firstRowHeight + secondRowHeight + thirdRowHeight,
            $productSummary = $('.product-summary.truncated');

        $productSummary.outerHeight(firstThreeRowsTotal - 1);
    }

    /**
     * Truncate the minicart product summary if there are more than three prods
     *
     */
    function truncateMinicartProdList() {
        $('.product-summary.truncated').after('<div class="see-all-products"></div>');
        $(window).resize();
    }

    /**
     * Update the minicart popover contents and then show it again
     * @param {boolean} hideMiniCart - Hide mini cart
     */
    function updateMinicartAndShowIt(hideMiniCart) {
        var url = $('.minicart').data('action-url');
        var selUserMenu = '.navbar-header .user';
        var selPopover = selUserMenu + ' .popover';
        var btnMyAccount = '#btn-my-account';

        // close open account menu
        $(btnMyAccount).attr('aria-expanded', 'false');
        $(selPopover).removeClass('show');

        // hide notifications
        $('.notifications .popover').removeClass('show');

        // close open mega menu
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();
        $('.modal-background').removeClass('open-menu');
        $('.main-menu').attr('aria-hidden', 'true');
        $('.menu-toggleable-left').removeClass('position-static-mobile');

        if ($('.minicart .popover.show').length === 0) {
            if (!hideMiniCart) {
                $('.minicart .popover').addClass('show');
            }
            $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                truncateMinicartProdList();
                $.spinner().stop();
                if (!hideMiniCart) {
                    $('.modal-background').show();
                }
            });

            if (!hideMiniCart) {
                $('.modal-background').show();
            }
        }
    }

    $(window).resize(function () {
        if ($dropdown.hasClass('show')) {
            updateTruncation();
        }
    });

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });

            $('body').trigger('updateMiniCartBubble', count.quantityTotal);
        }
    });

    // Hide minicart if remove product from minicart was clicked
    $(document).on('show.bs.modal', '#removeProductModal', function () {
        $('.minicart .popover').removeClass('show');
    });

    $(document).on('click', '.minicart-link', function () {
        var $popover = $(this).closest('.minicart').find('.popover');

        // Toggle minicart
        if ($popover.hasClass('show')) {
            $popover.removeClass('show');
            $('.modal-background').hide();
        } else {
            updateMinicartAndShowIt();
        }
    });

    $(document).on('click', '.notification-total', function () {
        var $popover = $(this).closest('.notifications').find('.popover');

        $('.minicart .popover').removeClass('show');

        // Toggle notification
        if ($popover.hasClass('show')) {
            $popover.removeClass('show');
            $('.modal-background').hide();
        } else {
            var selUserMenu = '.navbar-header .user';
            var selPopover = selUserMenu + ' .popover';
            var btnMyAccount = '#btn-my-account';

            // close opened account menu
            $(btnMyAccount).attr('aria-expanded', 'false');
            $(selPopover).removeClass('show');

            // close opened mega menu
            $('.menu-toggleable-left').removeClass('in');
            $('.modal-background').hide();
            $('.modal-background').removeClass('open-menu');
            $('.main-menu').attr('aria-hidden', 'true');

            if ($('.notifications .popover.show').length === 0) {
                $popover.addClass('show');
                $('.menu-toggleable-left').addClass('position-static-mobile');
                $('.modal-background').show();
            }
        }
    });

    $('body').on('click', '.notification-close', function () {
        $('.notifications .popover').removeClass('show');
        $('.menu-toggleable-left').removeClass('position-static-mobile');
        $('.modal-background').hide();
    });

    $('body').on('click', '.minicart .close-mini-cart', function () {
        $('.minicart .popover').removeClass('show');
        $('.modal-background').hide();
    });

    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('cart:update', function (event, data) {
        $('.minicart .popover').removeClass('show');
        updateMinicartAndShowIt(data.hideMiniCart);
    });

    $('body').on('click', '.minicart .see-all-products', function () {
        if ($('.minicart .product-summary').hasClass('truncated')) {
            $('.minicart .product-summary').removeClass('truncated');


            if ($(window).height() > 937) {
                $('.product-summary').animate({
                    height: '391px'
                }, 500);
            } else {
                $('.product-summary').animate({
                    height: '250px'
                }, 500);
            }
            if ($('.minicart .product-summary').data('shouldscroll')) {
                $('.minicart .product-summary').addClass('overflowy-scroll');
            }
        } else {
            $('.minicart .product-summary').addClass('truncated');
            $('.product-summary').animate({
                height: '212px'
            }, 500);
        }
        $(this).toggleClass('flip');
    });

    $('body').on('updateMiniCartBubble', function (e, total) {
        if (total > 0) {
            if (total > 99) {
                $('.minicart .minicart-quantity').removeClass('d-none');
                $('.minicart .minicart-quantity').text('99');
                $('.minicart .minicart-quantity-plus').removeClass('d-none');
            } else {
                $('.minicart .minicart-quantity').removeClass('d-none');
                $('.minicart .minicart-quantity-plus').addClass('d-none');
            }
        } else {
            $('.minicart .minicart-quantity').addClass('d-none');
            $('.minicart .minicart-quantity-plus').addClass('d-none');
        }
    });
};
