/* eslint-disable */
(function (window) {
    window.recaptchaData = window.recaptchaData || {};
    window.recaptchaData.recaptchaEventsHolder = window.recaptchaData.recaptchaEventsHolder || {};
    window.recaptchaData.recaptchaCallbacksArr = window.recaptchaData.recaptchaCallbacksArr || [];

    window.onRecaptchaLoad = function () {
        var recaptchaCallbacksArr = window.recaptchaData.recaptchaCallbacksArr;
        for (var i = 0; i < recaptchaCallbacksArr.length; i += 1) {
            if (typeof recaptchaCallbacksArr[i] === 'function') {
                recaptchaCallbacksArr[i]();
            }
        }

        window.recaptchaData.recaptchaCallbacksArr = [];
    };
}(window));
/* eslint-enable */

module.exports = {
    init: function () {
        if (!window.grecaptcha) {
            var url = window.SitePreferences.RECAPTCHA_API_URL;
            var script = document.createElement('script');

            script.type = 'text/javascript';
            script.async = true;
            script.src = url;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    },

    attach: function (form, callback, invisible) {
        var $holder = form.find('.js-recaptcha-input')[0];
        var recaptchaData = {
            sitekey: window.SitePreferences.RECAPTCHA_SITE_KEY,
            callback: typeof callback === 'function' ? callback : null
        };

        if (invisible) {
            recaptchaData.theme = 'light';
            recaptchaData.size = 'invisible';
            recaptchaData.badge = 'bottomright';
            recaptchaData.isolated = true;
        }
        window.recaptchaData.recaptchaCallbacksArr.push(function () {
            var holderId = window.grecaptcha.render($holder, recaptchaData);
            window.recaptchaData.recaptchaEventsHolder[form.attr('name')] = holderId;
        });

        window.onRecaptchaLoad();
    },

    showReCaptcha: function (formName) {
        if (window.grecaptcha && formName && formName in window.recaptchaData.recaptchaEventsHolder) {
            window.grecaptcha.execute(window.recaptchaData.recaptchaEventsHolder[formName]);
        }
    },

    reset: function (form) {
        window.grecaptcha.reset(window.recaptchaData.recaptchaEventsHolder[form.attr('name')]);
    }

};
