'use strict';

module.exports = {
    defaultSettings: {
        dots: false,
        infinite: false,
        mobileFirst: true,
        arrows: true,
        adaptiveHeight: true
    },
    pdpImageCarousel: {
        selector: '.variation-simple .primary-images .carousel.main',
        settings: {
            infinite: true,
            slidesToShow: 1,
            dots: false,
            fade: true,
            slidesToScroll: 1
        }
    },
    pdpProductSetImageCarousel: {
        selector: '.set-item .primary-images .carousel.main',
        settings: {
            infinite: false,
            slidesToShow: 1,
            dots: true,
            fade: true,
            slidesToScroll: 1
        }
    },
    pdpImageThumbnailsCarousel: {
        selector: '.variation-simple .primary-images .carousel.thumbnails',
        settings: {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.variation-simple .primary-images .carousel.main',
            variableWidth: true,
            focusOnSelect: true
        }
    },
    bundleImageCarousel: {
        selector: '.product-detail.bundle .primary-images .carousel.main',
        settings: {
            infinite: false,
            slidesToShow: 1,
            dots: true,
            fade: true,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: globalConfig.bp.large,
                    settings: {
                        asNavFor: '.product-detail.bundle .primary-images .carousel.thumbnails',
                        dots: false,
                        arrows: false
                    }
                }
            ]
        }
    },
    bundlepdpImageThumbnailsCarousel: {
        selector: '.product-detail.bundle .primary-images .carousel.thumbnails',
        settings: {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.product-detail.bundle .primary-images .carousel.main',
            variableWidth: true,
            focusOnSelect: true
        }
    },
    editModalImageCarousel: {
        selector: '#edickViewModal .primary-images .carousel.main',
        settings: {
            infinite: false,
            slidesToShow: 1,
            dots: true,
            fade: true,
            slidesToScroll: 1
        }
    },
    bonusImageCarousel: {
        selector: '#chooseBonusProductModal .carousel.main',
        settings: {
            infinite: false,
            slidesToShow: 1,
            dots: false,
            fade: true,
            slidesToScroll: 1
        }
    },
    quickViewImageCarousel: {
        selector: '#quickViewModal .primary-images .carousel.main',
        settings: {
            infinite: false,
            slidesToShow: 1,
            dots: true,
            fade: true,
            slidesToScroll: 1
        }
    },
    productTileCarousel: {
        selector: '.product-listing.slider',
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: globalConfig.bp.small,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: globalConfig.bp.large,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 3
                    }
                }
            ]
        }
    },
    featureCarousel: {
        selector: '.carousel.feature-slider',
        settings: {
            infinite: true,
            dots: true,
            customPaging: function (slider, i) {
                return (i + 1) + ' / ' + slider.$slides.length;
            }
        }
    },
    heroBannerCarouselAutoplay: {
        selector: '.carousel.page-builder:not(".autoplay")',
        settings: {
            infinite: true,
            fade: true,
            speed: 1000,
            cssEase: 'ease-in-out',
            touchThreshold: '50',
            autoplay: false,
            autoplaySpeed: 4000,
            adaptiveHeight: false
        }
    },
    heroBannerCarousel: {
        selector: '.carousel.page-builder.autoplay',
        settings: {
            infinite: true,
            fade: true,
            speed: 1000,
            cssEase: 'ease-in-out',
            touchThreshold: '50',
            autoplay: true,
            pauseOnHover: false,
            autoplaySpeed: 4000,
            adaptiveHeight: false
        }
    },
    imageZoomCarousel: {
        selector: '.carousel.image-zoom',
        settings: {
            infinite: false,
            slidesToShow: 1,
            fade: true,
            slidesToScroll: 1,
            dots: false
        }
    },
    topBannerPlpCarousel: {
        selector: '.carousel.banner-custom-carousel',
        settings: {
            infinite: true,
            slidesToShow: 1,
            fade: true,
            slidesToScroll: 1,
            dots: false
        }
    },
    portfolioCarousel: {
        selector: '.carousel.portfolio-carousel',
        settings: {
            slidesToShow: 1,
            infinite: true,
            dots: false,
            mobileFirst: true,
            arrows: false,
            focusOnSelect: false,
            variableWidth: true,
            asNavFor: '.carousel.portfolio-modal-carousel',
            responsive: [
                {
                    breakpoint: globalConfig.bp.medium,
                    settings: {
                        slidesToShow: 3,
                        arrows: true,
                        variableWidth: true
                    }
                },
                {
                    breakpoint: globalConfig.bp.xlarge,
                    settings: {
                        slidesToShow: 4,
                        arrows: true,
                        variableWidth: false
                    }
                }
            ]
        }
    },
    portfolioModalCarousel: {
        selector: '.carousel.portfolio-modal-carousel',
        settings: {
            infinite: true,
            slidesToShow: 1,
            fade: true,
            speed: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: globalConfig.bp.large,
                    settings: {
                        swipe: false
                    }
                }
            ]
        }
    },
    pdpLifestyleGalleryCarousel: {
        selector: '.lifestyle-gallery',
        settings: {
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            responsive: [
                {
                    breakpoint: globalConfig.bp.medium,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    },
    pdpLifestyleGalleryModalCarousel: {
        selector: '.lifestyle-gallery-modal',
        settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            adaptiveHeight: true,
            speed: 1,
            fade: true,
            responsive: [
                {
                    breakpoint: globalConfig.bp.large,
                    settings: {
                        swipe: false
                    }
                }
            ]
        }
    },
    productConfiguratorSwatches: {
        selector: '.swatch-slider',
        settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
            dots: false,
            adaptiveHeight: true,
            fade: true
        }
    },
    gridComponentCarousel: {
        selector: '.gridcomponentcarousel',
        settings: {
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            speed: 750,
            arrows: true,
            autoplay: true,
            pauseOnHover: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: globalConfig.bp.medium,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    }
};
