'use strict';

var errorNotification = require('base/components/errorNotification');

var validExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
var MAX_FILE_SIZE_MB = 25;

/**
 * Create error notification with timeout
 * @param {Object} element - error element
 * @param {string} message - error message
 */
function createErrorNotification(element, message) {
    errorNotification(element, message);
    $(element).find('.alert').delay(4000).slideUp(200, function () {
        $(this).alert('close');
    });
}

/**
 * Create success notification with timeout
 * @param {Object} element - Element
 * @param {string} message - Message for the alert
 */
function createSuccessNotification(element, message) {
    var errorHtml = '<div class="alert alert-success alert-dismissible ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';
    $(element).append(errorHtml);
    $(element).find('.alert').delay(4000).slideUp(200, function () {
        $(this).alert('close');
    });
}

/**
 * Generate file name for use in the notifications to customer
 * @param {string} key - Key
 * @returns {string} - File name
 */
function buildFilename(key) {
    var fileNameRegexp = /(.*\/)?(?:$|(.+?)[-]?(?!.*[-])(.+?)(?:(\.[^.]*$)|$))/;
    var fileMatch = key.match(fileNameRegexp);
    if (fileMatch.length) {
        return decodeURI(fileMatch[2] + fileMatch[4]);
    }
    return key;
}
/**
 * Update uploaded files count
 *
 * @param {Object} targetCount - count object
 * @param {number} targetCount.target - number of total files to upload
 * @param {number} targetCount.count - number of uplaoded files so far (including failed ones)
 * @param {Object} targetCount.error - should be true if there were any errors
 * @param {Object} targetCount.errorMessage - error message for the latest error
 * @param {boolean} error - was there an error
 * @param {string|undefined} errorMessage - error message or null
 */
function updateCount(targetCount, error, errorMessage) {
    targetCount.count += 1; // eslint-disable-line no-param-reassign
    targetCount.error = error ? true : targetCount.error; // eslint-disable-line no-param-reassign
    targetCount.errorMessage = error ? errorMessage : targetCount.errorMessage; // eslint-disable-line no-param-reassign
}

/**
 * Check if the upload is finished uploading all files. Return value is only used in the case that no asychronous methods were called
 * and we need to decide if we want to let the form be executed or stop it.
 *
 * @param {Object} targetCount - count object
 * @param {number} targetCount.target - number of total files to upload
 * @param {number} targetCount.count - number of uplaoded files so far (including failed ones)
 * @param {Object} targetCount.error - should be true if there were any errors
 * @param {Object} targetCount.errorMessage - error message for the latest error
 * @param {Object} $form - jQuery form
 * @param {boolean} noSubmit - should we re-submit the form
 * @param {function} callbackFunction - if the whole process was trigered instead of the form hijacked we can also execute the callback functio from the trigering side
 * @returns {boolean} value used to stop or allow further form execution
 */
function finalizeForm(targetCount, $form, noSubmit, callbackFunction) {
    if (targetCount.count === targetCount.target) {
        if (targetCount.error) {
            createErrorNotification($('.error-messaging'), targetCount.errorMessage);
            $form.spinner().stop();
            return false;
        }

        if (callbackFunction) {
            callbackFunction();
        }
        if (noSubmit) {
            $form.spinner().stop();
            return true;
        }
        $form.submit();
    }
    return false;
}

/**
 * Replaces a file input with an appropriate read only text fiels, without the aws s3 class, so the form can be submited normally
 *
 * @param {Object} $s3input - jQuery original s3input
 * @param {Object} data - returned data
 * @param {Object} data.key - encoded pre-signed aws s3 key value
 * @param {Object} data.fileName - original file name
 * @param {string} errorMessage - target error message
 */
function replaces3element($s3input, data) {
    var replacementFieldHidden = $('<input>', {
        type: 'hidden',
        id: $s3input.attr('id'),
        name: $s3input.attr('id'),
        value: data ? JSON.stringify({
            key: data.key,
            fileName: data.fileName,
            uploadedAt: (new Date()).getTime()
        }) : null
    });

    // This hidden input is needed to update data on BD side
    if ($s3input.siblings('input[type=hidden]').length) {
        // update data in existed hidden input
        $s3input.siblings('input[type=hidden]').val(
            data ? JSON.stringify({
                key: data.key,
                fileName: data.fileName,
                uploadedAt: (new Date()).getTime()
            }) : null
        );
    } else {
        // add new hidden input with necessary data
        replacementFieldHidden.insertBefore($s3input);
    }
}

/**
 * Updates the native object with file information. If callbackUrl is empty, call will not be made.
 * Use this in order to upload the file, but update the native object in the form submit controller
 *
 * @param {string} callbackUrl - Route with params
 * @param {string} key - generated key
 * @param {Object} targetCount - counting object
 * @param {Object} $form - original form
 * @param {boolean} shouldNotResubmit - if the form should not be resubmited after successfull upload
 * @param {function} callbackFunction - if the whole process was trigered instead of the form hijacked we can also execute the callback functio from the trigering side
 */
function updateNativeObject(callbackUrl, key, targetCount, $form, shouldNotResubmit, callbackFunction) {
    if (!callbackUrl || callbackUrl === '') {
        updateCount(targetCount, false);
        finalizeForm(targetCount, $form, shouldNotResubmit, callbackFunction);
        return;
    }
    // replace the missing key
    var pattern = new RegExp(encodeURI('{{}}'), 'gi');

    $.ajax({
        url: callbackUrl.replace(pattern, key),
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.success) {
                updateCount(targetCount, false);
                finalizeForm(targetCount, $form, shouldNotResubmit, callbackFunction);
                createSuccessNotification($('.error-messaging'), buildFilename(key) + ' uploaded');
            } else {
                updateCount(targetCount, true, buildFilename(key) + ' cannot be uploaded. Please try again');
                finalizeForm(targetCount, $form, shouldNotResubmit, callbackFunction);
            }
        },
        error: function () {
            updateCount(targetCount, true, 'Error uploading file ' + buildFilename(key));
            finalizeForm(targetCount, $form, shouldNotResubmit, callbackFunction);
        }
    });
}
/**
 * Upload a file to AWS S3 service
 *
 * @param {Object} response demandware service response
 * @param {Object} response.uploadLink - pre-signed upload url
 * @param {Object} response.type - pre-signed url type
 * @param {Object} response.key - pre-signed key
 * @param {Object} file - File uploaded to file input field
 * @param {Object} $form - Original jQuery form containing the input file
 * @param {Object} $inputElement - jQuery original s3input
 * @param {Object} targetCount - object used to count the total uploaded files
 * @param {Object} callbackUrl - url used to update the demandware object
 * @param {boolean} shouldNotResubmit - if the form should not be resubmited after successfull upload
 * @param {function} callbackFunction - if the whole process was trigered instead of the form hijacked we can also execute the callback functio from the trigering side
 */
function uploadFile(response, file, $form, $inputElement, targetCount, callbackUrl, shouldNotResubmit, callbackFunction) {
    var formdata = new FormData();
    formdata.append('file', file);
    $.ajax({
        url: response.uploadLink,
        method: response.type,
        data: file, // formdata,
        processData: false,
        contentType: 'binary/octet-stream',
        success: function () {
            // add 'file-uploaded' to prevent infinite looping if file was uploaded alredy
            $inputElement.addClass('file-uploaded');

            replaces3element($inputElement, {
                key: response.key,
                fileName: file.name
            }, 'Error uplaoding file.');

            updateNativeObject(callbackUrl, response.key, targetCount, $form, shouldNotResubmit, callbackFunction);
        },
        error: function () {
            updateCount(targetCount, true, 'Error uploading data');
            finalizeForm(targetCount, $form, shouldNotResubmit, callbackFunction);
        }
    });
}

/**
 * Check if the file extension is acceptable
 *
 * @param {Object} file - selected file for upload
 * @returns {boolean} is it acceptable extension
 */
function isValidFileExtension(file) {
    var ext = file.name.match(/.([^.]+)$/)[1].toLowerCase();

    return validExtensions.find(function (validExtension) {
        return ext === validExtension;
    });
}

/**
 * Process the file upload logic be it by hijacking a form, or by processing a submited trigger. If it was trigered it will not call any form functions like submit or prevent default
 *
 * @param {Object} $form - original form containing the files to be uploaded
 * @param {Object|null} e - triger event. if undefined we will not triger any form functions
 * @param {function} callbackFunction - if the whole process was trigered instead of the form hijacked we can also execute the callback functio from the trigering side
 * @returns {boolean} boolean value for preventing the default form execution
 */
function processFileUpload($form, e, callbackFunction) {
    // if we don't pass event we will not resubmit the form or do any form actions
    var shouldNotResubmit = !e;
    var s3FormElements = $form.find('.s3upload').not('.file-uploaded');

    if (s3FormElements.length > 0) {
        if (!shouldNotResubmit) {
            e.preventDefault();
        }

        $form.spinner().start();

        // check if the files are valid, before trying to upload them
        var errorPrecheck = false;

        // before submiting anything, check for errors
        s3FormElements.each(function (index, inputElement) {
            var $inputElement = $(inputElement);
            var file = $inputElement.prop('files')[0];

            if (!file) {
                return;
            }

            if (!isValidFileExtension(file)) {
                errorPrecheck = 'Invalid file extension. Only jpg, jpeg, png and pdf are accepted.';
            }

            if (MAX_FILE_SIZE_MB < file.size / 1024 / 1024) {
                errorPrecheck = 'The maximum size of the file is ' + MAX_FILE_SIZE_MB + ' MB.';
            }

            if (errorPrecheck) {
                $('body').trigger('filePrecheck:error', { element: $inputElement, message: errorPrecheck });
            }
        });

        if (errorPrecheck) {
            $form.spinner().stop();
            createErrorNotification($('.error-messaging'), errorPrecheck);
            e.preventDefault();
            return false;
        }

        // keep count on uploaded fiels, so we know when to call the same form again, or return an error
        var targetCount = {
            target: s3FormElements.length,
            error: false,
            count: 0
        };

        s3FormElements.each(function (index, inputElement) {
            var $inputElement = $(inputElement);
            var baseUrl = $inputElement.attr('data-url');
            var callbackUrl = $inputElement.attr('data-callbackurl');
            var type = $inputElement.attr('data-type');
            // In the case we are registering a company and we skipped the customer No, we will use the businessname value for file storage on AWS
            var customerNo = $inputElement.attr('data-customerno') || $('#businessname').val();
            customerNo = customerNo || 'guest';
            var file = $inputElement.prop('files')[0];

            if (!file) {
                updateCount(targetCount, false);
                replaces3element($inputElement, null, 'No file');
                // add 'file-uploaded' to prevent infinite looping if file was uploaded alredy
                $inputElement.addClass('file-uploaded');
                return;
            }

            var fileName = file.name;

            // we stop any js code listening to the same event
            // after the file has been uploaded, we will call the same form again
            // which will execute all the code then
            if (!shouldNotResubmit) {
                e.stopImmediatePropagation();
            }

            $.ajax({
                url: baseUrl,
                method: 'GET',
                dataType: 'json',
                data: {
                    customerNo: customerNo,
                    type: type,
                    fileName: fileName
                },
                success: function (response) {
                    // add 'file-uploaded' to prevent infinite looping if file was uploaded alredy
                    $inputElement.addClass('file-uploaded');

                    // add 'remove link' as attr, so user could remove file if it was uploaded to aws
                    $inputElement.siblings('.file-upload-remove-container').attr('data-removeLink', response.removeLink);
                    $inputElement.attr('data-callbackurl', response.deleteCallbackUrl);

                    uploadFile(response, file, $form, $inputElement, targetCount, callbackUrl, shouldNotResubmit, callbackFunction);
                    $form.spinner().stop();
                },
                error: function (err) {
                    updateCount(targetCount, true, targetCount.errorMessage);
                    finalizeForm(targetCount, $form, shouldNotResubmit, callbackFunction);
                    createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                }
            });
        });

        return finalizeForm(targetCount, $form, true, callbackFunction);
    }
    if (callbackFunction) {
        callbackFunction();
    }
    return true;
}

/**
 * @returns {string} is time when file was uploaded
 */
function fileUploadedTime() {
    var todayDate = new Date();
    var getTodayDate = todayDate.getDate();
    var getTodayMonth = todayDate.getMonth() + 1;
    var getTodayFullYear = todayDate.getFullYear();
    var getCurrentHours = todayDate.getHours();
    var getCurrentMinutes = todayDate.getMinutes();
    var getCurrentAmPm = getCurrentHours >= 12 ? 'PM' : 'AM';
    var getUserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // eslint-disable-line

    getCurrentHours = getCurrentHours % 12 || 12;
    getCurrentMinutes = getCurrentMinutes < 10 ? '0' + getCurrentMinutes : getCurrentMinutes;

    var getCurrentDateTime = getTodayMonth + '/' + getTodayDate + '/' + getTodayFullYear + ' - ' + getCurrentHours + ':' + getCurrentMinutes + getCurrentAmPm + ' ' + getUserTimeZone;

    return getCurrentDateTime;
}

/**
 * Remove file from S3
 * @param {Object} el - Triggered Element
 */
function removeS3File(el) {
    var $this = $(el),
        $thisGrandParent = $this.parent().parent(),
        $thisRemoveLabel = $thisGrandParent.siblings('.file-upload-label'),
        $thisFileUploadAfterFind = $thisRemoveLabel.find('.file-upload-after'),
        $thisFileUploadAfterSiblings = $thisRemoveLabel.siblings('.file-upload-after'),
        $s3upload = $thisGrandParent.siblings('.s3upload');

    // remove the file that was uloaded
    $s3upload.val('');

    // change labels
    $this.addClass('d-none');
    $thisRemoveLabel.find('.file-upload-before').removeClass('d-none');
    $thisRemoveLabel.find('.file-upload-label-size').removeClass('d-none');
    if ($thisFileUploadAfterFind) {
        $thisFileUploadAfterFind.addClass('d-none');
    }
    if ($thisFileUploadAfterSiblings) {
        $thisFileUploadAfterSiblings.addClass('d-none');
    }

    // remove the file that was uloaded to aws
    if ($this.attr('data-removeLink')) {
        $.ajax({
            url: $this.attr('data-removeLink'),
            method: 'DELETE',
            dataType: 'json',
            success: function () {
                // remove 'file-uploaded' class to allow this 's3upload' be in the loop
                $s3upload.removeClass('file-uploaded');
                $this.attr('data-removeLink', '');

                // clean hidden input for s3uploadInput in case any file was uploaded before
                if ($thisGrandParent.siblings('input[type=hidden]').length) {
                    $thisGrandParent.siblings('input[type=hidden]').val('');
                }
            },
            error: function () {
                createErrorNotification($('.error-messaging'), 'Error removing the file');
            }
        });
    }
}

module.exports = function () {
    var $s3uploadInput = $('.s3upload'),
        $s3uploadLabel = $('.file-upload-label'),
        $s3uploadRemove = $('.file-upload-remove-container');

    // Change the UI once file is uploaded
    $s3uploadInput.on('change', function () {
        var $this = $(this),
            $thisClose = $this.siblings('.file-upload-after').find('.file-upload-remove-container'),
            $s3Label = $this.siblings('.file-upload-label'),
            fileName = $this.val().split('\\').slice(-1)[0],
            uploadedTime = fileUploadedTime();

        $thisClose.removeClass('d-none');
        $s3Label.find('.file-upload-before').addClass('d-none');
        $s3Label.find('.file-upload-label-size').addClass('d-none');
        $s3Label.parent().find('.file-upload-after').removeClass('d-none');
        $s3Label.parent().find('.file-upload-name').html(fileName);
        $s3Label.parent().find('.file-upload-name').attr('href', $this.prop('files')[0].name);
        $s3Label.parent().find('.file-uloaded-time').html(uploadedTime);
    });

    // If there is hidden input for s3uploadInput,
    // we need trigger click to be able to upload the file
    $s3uploadLabel.on('click', function () {
        if ($(this).siblings('input[type=hidden]').length) {
            $(this).siblings('.s3upload').trigger('click');
        }
    });

    // Remove uloaded file clicking cross icon near uploaded file
    // ajax call neeeded if file was uploaded to aws
    $s3uploadRemove.on('click', function () {
        var that = this;
        if ($(this).closest('.purchase-order-block').length) {
            $('#deletefilemodal').modal('show');
            $('body').on('shown.bs.modal', '#deletefilemodal', function () {
                $('#deletefilemodal .delete-confirmation-btn').off().on('click', function (e) {
                    e.preventDefault();
                    removeS3File(that);
                });
            });
        } else {
            removeS3File(that);
        }
        $('body').trigger('aws:clearfiles', { element: $(that) });
    });

    $('form').on('uploadS3Files', function (e, callbackFunction) {
        var $form = $(this);
        return processFileUpload($form, false, callbackFunction);
    });

    $('form:not(.no-file)').submit(function (e) {
        var $form = $(this);
        var processResult = processFileUpload($form, e);
        if (!processResult) {
            e.stopImmediatePropagation();
        }
        return processResult;
    });

    $(document).on('removeS3File', function (e, callbackFunction) {
        var $link = $(e.target);

        removeS3File($link, callbackFunction);
    });

    $('.s3remove').click(function () {
        var $link = $(this);
        var that = $(this);
        if ($(this).closest('.purchase-order-block').length) {
            $('#deletefilemodal').modal('show');
            $('body').on('shown.bs.modal', '#deletefilemodal', function () {
                $('#deletefilemodal .delete-confirmation-btn').off().on('click', function () {
                    var $container = $(that).closest('.s3file-container');
                    $container.spinner().start();
                    var callbackUrl = $link.attr('data-callbackurl');

                    $.ajax({
                        url: $link.attr('href'),
                        method: 'DELETE',
                        dataType: 'json',
                        success: function () {
                            // even if the file does not exist on aws, success will be called
                            if (!callbackUrl) {
                                $container.spinner().stop();
                                $container.remove();
                                return;
                            }

                            $.ajax({
                                url: callbackUrl,
                                method: 'GET',
                                dataType: 'json',
                                success: function (response) {
                                    if (response.success) {
                                        $(document).trigger('s3removal:success');
                                        $container.spinner().stop();
                                        $container.remove();
                                    } else {
                                        createErrorNotification($('.error-messaging'), 'Error removing the file');
                                    }
                                },
                                error: function () {
                                    $container.spinner().stop();
                                    createErrorNotification($('.error-messaging'), 'Error removing the file');
                                }
                            });
                        },
                        error: function () {
                            $container.spinner().stop();
                            createErrorNotification($('.error-messaging'), 'Error removing the file');
                        }
                    });
                });
            });
        } else {
            var $container = $(this).closest('.s3file-container');
            $container.spinner().start();
            var callbackUrl = $link.attr('data-callbackurl');
            $.ajax({
                url: $link.attr('href'),
                method: 'DELETE',
                dataType: 'json',
                success: function () {
                    // even if the file does not exist on aws, success will be called
                    if (!callbackUrl) {
                        $container.spinner().stop();
                        $container.remove();
                        return;
                    }

                    $.ajax({
                        url: callbackUrl,
                        method: 'GET',
                        dataType: 'json',
                        success: function (response) {
                            if (response.success) {
                                $container.spinner().stop();
                                $(document).trigger('s3removal:success', { link: $link });
                                $container.remove();
                                $link.closest('.file-data').remove();
                                createSuccessNotification($('.error-messaging'), buildFilename(response.key) + ' removed');
                            } else {
                                createErrorNotification($('.error-messaging'), 'Error removing the file');
                            }
                        },
                        error: function () {
                            $container.spinner().stop();
                            createErrorNotification($('.error-messaging'), 'Error removing the file');
                        }
                    });
                },
                error: function () {
                    $container.spinner().stop();
                    createErrorNotification($('.error-messaging'), 'Error removing the file');
                }
            });
        }
        return false;
    });
};
