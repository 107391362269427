'use strict';

module.exports = function () {
    $(document).on('mouseenter click', '.info-icon .icon', function () {
        $(this).siblings('.tooltip').removeClass('d-none');

        if ($('.shipping-method-option-container').length) {
            $(this).closest('.shipping-method-option-container').addClass('shipping-overflow');
        }
    });

    $(document).on('click', '.tooltip .icon-close', function (e) {
        e.stopPropagation();

        $('.info-icon').trigger('blur');
    });

    $(document).on('mouseleave focusout', '.info-icon', function () {
        $(this).find('.tooltip').addClass('d-none');

        if ($('.shipping-method-option-container').length) {
            $('.shipping-method-option-container').removeClass('shipping-overflow');
        }
    });

    $(document).on('click', '.shipping-method-list .info-icon', function (e) {
        e.stopPropagation();
        e.preventDefault();

        $('.shipping-method-option-container').removeClass('shipping-overflow');
        $(this).closest('.shipping-method-option-container').addClass('shipping-overflow');

        setTimeout(function () {
            $(this).find('.tooltip').addClass('d-none');
            $(this).closest('.shipping-method-option-container').removeClass('shipping-overflow');
        }.bind(this), 5000);
    });

    $(document).on('click', '.config-estimate-lead-time > .info-icon', function (e) {
        e.stopPropagation();
        e.preventDefault();

        $(this).find('.tooltip').removeClass('d-none');

        setTimeout(function () {
            $(this).find('.tooltip').addClass('d-none');
        }.bind(this), 5000);
    });
};
