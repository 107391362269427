window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/inlineLabel'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/lazyLoading'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./components/global'));
    processInclude(require('./components/dropdown'));
    processInclude(require('./components/cms'));
    processInclude(require('./components/flipcard'));
    processInclude(require('./checkout/confirmation'));
    processInclude(require('./components/pageDesigner'));
    processInclude(require('./components/quote'));
    processInclude(require('./homePage'));
});

processInclude(require('./components/loading'));

require('./components/reCaptcha');
require('./config/global');
require('./thirdParty/bootstrap');
require('./thirdParty/objectFitPolyfill.basic.min.js');
require('ba-base/thirdParty/slick.min');
require('./components/spinner');
