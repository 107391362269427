/* eslint-disable */
/**
 * @package     Blueacorn/Globalconfig
 * @version     2.0
 * @author      Blue Acorn <code@blueacorn.com>
 * @copyright   Copyright © 2019 Blue Acorn.
 */

(function (window) {
    // Set global enquire
    window.enquire = require('enquire.js/dist/enquire.min');

    // Set global config
    window.globalConfig = {
        bp: {
            xxsmall: 319,
            xsmall: 479,
            small: 599,
            medium: 767,
            large: 1023,
            xlarge: 1279
        },
        toastTimeout: 20000
    };
} (window));

module.exports = {
    components: {
        global: {
            carouselArr: [
                'productTileCarousel',
                'featureCarousel',
                'heroBannerCarousel',
                'heroBannerCarouselAutoplay',
                'gridComponentCarousel'
            ],
            tooltipElement: '[data-toggle="tooltip"]',
            modal: {
                template: '<!-- Modal -->'
                    + '<div class="modal fade" id="cmsModal" role="dialog">'
                    + '<div class="modal-dialog modal-lg modal-dialog-centered cms-modal-dialog">'
                    + '<!-- Modal content-->'
                    + '<div class="modal-content">'
                    + '<div class="modal-header">'
                    + '    <button type="button" class="close pull-right icon-close" data-dismiss="modal">'
                    + '        <span class="sr-only">Close</span>'
                    + '    </button>'
                    + '</div>'
                    + '<div class="modal-body">'
                    + '</div>'
                    + '</div>'
                    + '</div>',
                elements: [
                    '.js-cms-popup'
                ]
            }
        }
    }
};

/* eslint-enable */
