'use strict';

$(document).on('click', '.registration-button', function () {
    $('#checkstate').addClass('is-invalid');
});

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if ((this.checkValidity && !this.checkValidity()) || (this[0].checkValidity && !this[0].checkValidity())) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');

    // Clear errors for custom selects
    var $invalidSelect = $(form).find('.custom-dropdown-toggle.is-invalid');
    $invalidSelect.removeClass('is-invalid');
    $invalidSelect.parent().siblings('.form-control-label').removeClass('is-invalid');
}

/**
 * Show validation error
 * @param {jQuery.event} e - Event to be canceled if form is invalid.
 * @returns {void}
 */
function showErrors(e) {
    e.preventDefault();
    this.setCustomValidity('');
    if (!this.validity.valid) {
        var validationMessage = this.validationMessage;
        $(this).addClass('is-invalid');
        if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
            validationMessage = $(this).data('pattern-mismatch');
        }
        if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
            && $(this).data('range-error')) {
            validationMessage = $(this).data('range-error');
        }
        if ((this.validity.tooLong || this.validity.tooShort)
            && $(this).data('range-error')) {
            validationMessage = $(this).data('range-error');
        }
        if (this.validity.valueMissing && $(this).data('missing-error')) {
            validationMessage = $(this).data('missing-error');
        }
        if (this.validity.valueMissing && $(this).closest('form').hasClass('share-form')) {
            validationMessage = 'Required field';
        }
        $(this).parents('.form-group').find('.invalid-feedback')
            .text(validationMessage);

        // Add 'is-invalid' for custom select
        if ($(this).hasClass('mobile-select')) {
            var customdropdown = $(this).parent().siblings('.custom-dropdown');
            var desktopdropdown = customdropdown.children('.custom-dropdown-toggle');
            var label = customdropdown.siblings('label');

            desktopdropdown.addClass('is-invalid');
            label.addClass('is-invalid');
        }

        var $invalidEl = $(document).find('.is-invalid').parent();

        if ($invalidEl.length && $('.create-account-form').length) {
            var $header = $('header');
            var headerHeight = $header.length ? $header.height() : 0;
            var target = $invalidEl.eq(0).offset().top - headerHeight;
            $('html, body').animate({
                scrollTop: target
            }, 0);
        }

        // Return to comShade modal part 1
        if ($(this).attr('name') === 'comName' || $(this).attr('name') === 'comManufacturer') {
            var componentConfig = require('./configurator');
            componentConfig.handleComShadeInputs();
        }
    }
}

/**
 * Reinit validation events
 * @returns {void}
 */
function reinitValidation() {
    $('form input, form select, form textarea').off('invalid', showErrors).on('invalid', showErrors);
}

module.exports = {
    invalid: function () {
        $('form input, form select, form textarea').on('invalid', showErrors);
        $(document).on('validate:reinit', reinitValidation);
    },

    submit: function () {
        $(document).on('submit', 'form', function (e) {
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $(document).on('click', 'form button[type="submit"], form input[type="submit"]', function () {
            clearForm($(this).parents('form'));
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        reinitValidation: reinitValidation,
        clearForm: clearForm
    }
};
