'use strict';

module.exports = {
    initSlideUp: function () {
        $('.confirmation-page .product-summary-block .product-line-item:nth-child(n+5)').slideUp();

        $('body').on('click', '.confirmation-page .order-product-summary .see-all-products', function () {
            // done function is used here to prevent slideUp and slideDown from holding onto style attributes if the function glitches
            if ($(this).hasClass('see-all-expanded')) {
                $('.product-summary-block .product-line-item:nth-child(n+5)').slideUp(400, function () {
                    $('.product-summary-block .product-line-item:nth-child(n+5)').each(function () {
                        $(this).attr('style', 'display: none;');
                    });
                });
                $(this).removeClass('see-all-expanded');
            } else {
                $(this).addClass('see-all-expanded');
                $('.product-summary-block .product-line-item:nth-child(n+5)').slideDown(400, function () {
                    $('.product-summary-block .product-line-item:nth-child(n+5)').each(function () {
                        $(this).removeAttr('style');
                    });
                });
            }
        });
    }
};
