'use strict';

var kcFooter = $('.footer-email-signup-actual');
var kcFooterEmail = $('.footer-email-signup-actual .kc-form-email-footer');
var kcFooterSMS = $('.footer-email-signup-actual .kc-form-sms-footer');
var focusOnMe;

/**
 * replace keep current sms section with email content
 */
function keepCurrentEmailF() {
    $('.kc-form-feedback-footer').html('');
    $('#footerkeepcurrent-input').val('');
    $('.kc-success').addClass('d-none');
    $('.icon-email-send-footer').removeClass('d-none');
    $('.activate-input').addClass('empty');
    $('.activate-details').addClass('empty');
    $('.kc-form-sms-footer').fadeOut(0).addClass('d-none').removeClass('active');
    $('.kc-form-email-footer').fadeIn(500).removeClass('d-none').addClass('active');
    $('.footer-mc-subscribe-email').removeClass('been-clicked');
    $('.footer-mc-subscribe-sms').removeClass('been-clicked');
}

/**
 * replace keep current email section with sms content
 * @param {*} focusSMS true/false focus on mobile input
 */
function keepCurrentSMSF(focusSMS) {
    $('.kc-form-feedback-footer').html('');
    $('#sms-number-kc-footer').val('');
    $('.kc-success').addClass('d-none');
    $('.icon-email-send-footer').removeClass('d-none');
    $('.activate-input').addClass('empty');
    $('.activate-details').addClass('empty');
    $('.kc-form-email-footer').fadeOut(0).addClass('d-none').removeClass('active');
    $('.kc-form-sms-footer').fadeIn(500).removeClass('d-none').addClass('active');
    $('.footer-mc-subscribe-sms').removeClass('been-clicked');
    $('.footer-mc-subscribe-email').removeClass('been-clicked');

    if (focusSMS) {
        $('#sms-number-kc-footer').focus();
    }
}

/**
 * render footer keep current form
 * @param {*} formData data
 * @return {string} content
 */
function renderKCFormF(formData) {
    var form = $('#footer-email-alert-signup');
    var url = form.attr('action');
    $(this).attr('disabled', true);
    $('.kc-form-feedback-footer').removeClass('kc-error kc-error-footer').html('');
    $.ajax({
        url: url,
        type: 'post',
        data: formData,
        success: function (data) {
            if (data.success) {
                $('input[name=mcEmailSignupFooter]').val('');
                $('.kc-form-feedback-footer').removeClass('kc-error-footer d-none').fadeIn(100).html(data.msg);
                $('.kc-form-email-footer .mc-disclaimer .load-message').addClass('empty');
                $('.kc-form-portion.active .icon-email-send-footer').addClass('d-none');
                $('.kc-form-portion.active .kc-success').removeClass('d-none');
                $('.footer-mc-subscribe-email').removeClass('been-clicked');

                if (kcFooterSMS.length > 0) {
                    setTimeout(function () {
                        $('.kc-form-email-footer').fadeOut(0);
                        focusOnMe = true;
                        keepCurrentSMSF(focusOnMe);
                    }, 4000);
                }
            } else {
                $('.kc-form-feedback-footer').addClass('kc-error-footer').html(data.msg);
            }
        },
        error: function () {
            $('input[name=mcEmailSignupFooter]').val('');
        }
    });
    return false;
}

/**
 * submit keep current email by enter key or button click
 */
function submitKCEmailF() {
    var $email = $('input[name=mcEmailSignupFooter]');
    if ($email.val().length > 0) {
        var emailId = $email.val();
        $.spinner().start();
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(window.SitePreferences.NEW_RECAPTCHA_SITE_KEY, { action: 'submit' })
                .then(function (token) {
                    var csrfElement = $('#csrfToken');
                    var formData = { emailId: emailId, grecaptchaResponse: token };
                    formData[csrfElement.attr('name')] = csrfElement.val();
                    renderKCFormF(formData);
                })
                .catch(function (error) {
                    /* eslint-disable no-console */
                    console.log('reCaptcha Error:', error);
                    /* eslint-enable no-console */
                });
        });
        $.spinner().stop();
    } else {
        $('.kc-form-feedback-footer').addClass('kc-error').html('Please fill out this field.');
        $email.focus();
    }
}

/**
 * submit keep current sms by enter key or button click
 */
function submitKCSMSF() {
    $('.kc-form-feedback-footer').removeClass('kc-error').html('');
    var phoneDataF = document.getElementById('sms-number-kc-footer');
    var validityFooter = null;

    if ($(phoneDataF).val().length > 0) {
        $.spinner().start();
        validityFooter = $(phoneDataF).validity;

        if ($(validityFooter).patternMismatch === true) {
            phoneDataF.setCustomValidity(window.Resources.MOBILE_TEL_PATTERN_MISMATCH);
        } else {
            phoneDataF.setCustomValidity('');
        }
        if (phoneDataF.reportValidity()) {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(window.SitePreferences.NEW_RECAPTCHA_SITE_KEY, { action: 'submit' })
                    .then(function (token) {
                        var formData = { smsNumber: phoneDataF.value, grecaptchaResponse: token };
                        var csrfElement = $('#csrfToken');
                        formData[csrfElement.attr('name')] = csrfElement.val();
                        $.ajax({
                            url: window.Urls.attentiveSubscribe,
                            method: 'post',
                            data: formData
                        }).done((data) => {
                            if (!data.error && data.code === 202) {
                                $('#sms-number-kc-footer').val('');
                                $('.kc-form-sms-footer .attn-disclaimer .load-message').addClass('empty');
                                $('.kc-form-feedback-footer').removeClass('kc-error d-none').fadeIn(100).html(data.message);
                                $('.kc-form-portion.active .icon-email-send-footer').addClass('d-none');
                                $('.kc-form-portion.active .kc-success').removeClass('d-none');
                                $('.footer-mc-subscribe-sms').removeClass('been-clicked');

                                if (kcFooterEmail.length > 0) {
                                    setTimeout(function () {
                                        $('.kc-form-sms-footer').fadeOut(20);
                                        keepCurrentEmailF();
                                    }, 4000);
                                }
                            } else {
                                $(phoneDataF).val('');
                            }
                        });
                    })
                    .catch(function (error) {
                        /* eslint-disable no-console */
                        console.log('reCaptcha Error:', error);
                        /* eslint-enable no-console */
                    });
            });
        } else {
            $('.kc-form-feedback-footer').addClass('kc-error').html(phoneDataF.getAttribute('data-pattern-mismatch'));
        }
        $.spinner().stop();
    } else {
        $('.kc-form-feedback-footer').addClass('kc-error').html('Please fill out this field.');
        $(phoneDataF).focus();
    }
}

/**
 * KC Email + SMS interactions
 */
function kCUECo() {
    $(document).ready(function () {
        var url = 'https://www.google.com/recaptcha/api.js?render=' + window.SitePreferences.NEW_RECAPTCHA_SITE_KEY;
        var script = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);

        if (kcFooter.length > 0) {
            if (kcFooterEmail.length > 0) {
                keepCurrentEmailF();
            } else if (kcFooterSMS.length > 0) {
                focusOnMe = false;
                keepCurrentSMSF(focusOnMe);
            }
        }
    });

    // Activate input field
    var $inputF = $('.kc-form-portion .footer-actual-form input.keep-current-subscribe');
    var $activateFI = document.querySelectorAll('.kc-form-portion .activate-input');
    var $activateFID = document.querySelectorAll('.kc-form-portion .activate-details');

    $inputF.on('focus focusin', function () {
        $($activateFID).removeClass('empty');
        $($activateFI).removeClass('empty');
        $('.kc-form-portion .load-message').addClass('hover-able');
    });

    $($inputF).on('focusout blur', function () {
        if ($('.been-clicked').length === 0) {
            $($activateFID).addClass('empty');
            $('.kc-error, .kc-error-footer').html('');

            if ($(this).val().length === 0) {
                $($activateFI).addClass('empty');
                $('.kc-error, .kc-error-footer').html('');
            }
        } else {
            $('.kc-form-portion .load-message').addClass('hover-able');
        }
    });

    $('.footer-mc-subscribe-email').on('click', function (e) {
        e.preventDefault();
        $('.footer-mc-subscribe-email').addClass('been-clicked');
        $($activateFID).removeClass('empty');
        submitKCEmailF();
    });

    $('input[name=mcEmailSignupFooter]').keypress(function (e) {
        var code = e.keyCode || e.which;
        if (code === 13) {
            e.preventDefault();
            $($activateFID).removeClass('empty');
            $($activateFI).removeClass('empty');
            $('.footer-mc-subscribe-email').addClass('been-clicked');
            submitKCEmailF();
        }
    });

    $('input[name=mcEmailSignupFooter], input[name=sms-number-kc-footer]').on('click', function () {
        this.select();
    });

    $('input[name=sms-number-kc-footer]').keypress(function (e) {
        var code = e.keyCode || e.which;
        if (code === 13) {
            e.preventDefault();
            $($activateFID).removeClass('empty');
            $($activateFI).removeClass('empty');
            $('.footer-mc-subscribe-sms').addClass('been-clicked');
            submitKCSMSF();
        }
    });

    $('#sms-optin-submit-footer, .footer-mc-subscribe-sms').on('click', function (e) {
        e.preventDefault();
        $('.footer-mc-subscribe-sms').addClass('been-clicked');
        $($activateFID).removeClass('empty');
        submitKCSMSF();
    });
}

module.exports = {
    kCUECo: kCUECo
};
