'use strict';

// const { outputJSON } = require('fs-extra');
// const configurator = require('../../../../models/configurator');
var Carousel = require('./carousel'),
    utils = require('../components/utils'),
    recaptcha = require('../components/reCaptcha');

// DOM Elements
var $cache = {
    tileContainer: $('.display-tile-container'),
    swatchTooltip: $('.main-swatch-tooltip-icon'),
    resultImage: $('.configurator-results-image'),
    panelTitle: $('.panel-title'),
    sidemarkWrapper: $('.sidemark-popup-wrapper'),
    sidemarkPlaceholder: $('.sidemark-placeholder'),
    sidemarkInput: $('.sidemark-input'),
    mailHelpSection: $('.modal-contact-info'),
    overallHeightDropdown: $('.overall-height-select'),
    optionDescription: $('.config-option-description'),
    resultValue: $('.result-value'),
    swatchSlider: $('.swatch-slider'),
    dropdownsWrapper: $('.dropdowns-wrapper'),
    configuratorForm: $('.configurator-form'),
    saveConfiguratorBtn: $('.save-configurator'),
    editNotLoggedinPopup: $('#editNotLoggedinPopup'),
    priceSummaryFigure: $('.configurator-summary-figure'),
    optionName: $('.config-option-name'),
    configuratorQuantity: $('.configurator-quantity'),
    modalAnimationBackground: $('.modal-animation-background'),
    panelModal: $('#panel-modal'),
    swatchModal: $('#swatch-modal'),
    dropdownContainer: $('.custom-dropdown-container'),
    variationDropdownCtnr: $('.custom-dropdown-container.variation'),
    resultsWrapper: $('.configurator-results-wrapper'),
    configuratorPageWrapper: $('.configurator-page'),
    invalidPopup: $('#invalidOptionPopup')
};

// Dynamic product configurator items that can be changed due to events
var global = {
    shareConfObj: {},
    shareCustomForms: {},
    popupSwatchImages: [],
    activateSliderId: '',
    itemPriceVal: '',
    swatchPrice: '',
    isSubmitPressed: false,
    isSwatchSelected: false,
    openCustomSwatchPopup: true,
    isUserLoggedIn: $('.configurator-page').data('is-loggedin'),
    grayBlockSrc: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Solid_grey.svg'
};

// Keys items
var keys = {
    variant: 'variant',
    variation: 'variationKey',
    sidemark: 'sidemark',
    saveAndReview: 'saveAndReview',
    swatch: 'swatch',
    overallHeight: 'oah',
    animationTimeout: 500
};

// Parameters values
var param = {
    pid: utils.getParameter('pid'),
    isSharedLink: utils.getParameter('shared'),
    sharedQty: utils.getParameter('quantity'),
    sharedSidemark: utils.getParameter('sidemark'),
    customForm: utils.getParameter('custom')
};

var CONST = {
    CUSTOM_COLOR: {
        POWDER_COAT: 'Powder Coat',
        PAINT_SELECTION: 'Paint Selection',
        COM: 'COM'
    },
    BUTTON_TYPE: {
        DEFAULT: 'default',
        VARIATION: 'variation'
    }
};

// Com Shade Modal Items
var comFormGroup = $('.popup-swatch-comShade .custom-swatch-form .form-group');
var comSubmit = $('.popup-swatch-comShade button.save-customPaint');
var comBackButton = $('.popup-swatch-comShade button.back-comShade');
var comNextButton = $('.popup-swatch-comShade button.next-comShade');

/**
 * format numbers to include commas
 * @param {number} x - number
 * @returns {string} formatted number
 */
function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

/**
 * get active dropdown element
 * @param {string} id - active dropdown id
 * @returns {Object} active dropdown element
 */
function getActiveDropdownById(id) {
    var activeDropdownEl = $('.custom-dropdown-container[data-dropdown-id="' + id + '"]');
    if (!activeDropdownEl.length) {
        activeDropdownEl = $('.custom-dropdown-container[data-dropdown-id="variation-' + id + '"]');
    }

    return activeDropdownEl;
}

/**
 * remove selected id from shareConfObj object
 * @param {string} id - selected id
 */
function deleteIdFromShareConfObj(id) {
    delete global.shareConfObj[id];
}

/**
 * clears the display
 */
function clearDisplay() {
    $cache.tileContainer.empty();
}

/**
 * returns true if object is an empty
 * @param {Object} obj - selected id
 * @returns {boolean} is object empty
 */
function isObjEmpty(obj) {
    return !Object.keys(obj).length;
}

/**
 * updates counter for inputs that have a character limit
 * @param {Object} $el - current input element
 */
function updateCharCounter($el) {
    var charsTyped = $el.val().length;
    var maxChars = $el.attr('maxlength');
    if (charsTyped >= maxChars) {
        $el.siblings('.char-counter').addClass('maxed');
    } else {
        $el.siblings('.char-counter').removeClass('maxed');
    }
    $el.siblings('.char-counter').text(charsTyped + ' / ' + maxChars);
}

/**
 * show / hide Panel sections according to active display type
 * @param {string} displayType - active dropdown id
 */
function buildPanelView(displayType) {
    if (!$('.display-tile-container').attr('style')) {
        $('.display-tile-container').fadeOut(0);
    }

    $cache.overallHeightDropdown.empty();
    $cache.panelTitle.addClass('d-none');
    $('.panel-modal-close-wrapper').find('.configurator-option-title').addClass('d-none');
    $cache.sidemarkWrapper.addClass('d-none');
    $cache.mailHelpSection.addClass('d-none');

    switch (displayType) {
        case keys.swatch:
            $cache.panelTitle.removeClass('d-none');
            $('.panel-modal-close-wrapper').find('.configurator-option-title').removeClass('d-none');
            global.popupSwatchImages = [];
            $cache.swatchSlider.empty();
            $cache.swatchSlider.removeClass('slick-initialized slick-slider');
            $cache.tileContainer.addClass('no-top-spacing-mobile');
            break;
        case keys.overallHeight:
            $cache.optionDescription.text('');
            break;
        case keys.sidemark:
            $cache.sidemarkWrapper.removeClass('d-none');
            break;
        case keys.saveAndReview:
            clearDisplay();
            $cache.dropdownsWrapper.addClass('d-none');
            $cache.resultsWrapper.removeClass('d-none');
            $cache.configuratorPageWrapper.addClass('saveand-review-display');
            $cache.resultValue.addClass('d-none');
            break;
        default:
            $cache.mailHelpSection.removeClass('d-none');
            break;
    }

    $('.display-tile-container').fadeIn(500);
}

/**
 * builds out the tile
 * @param {Object} v - option value
 * @param {string} className - class to use for the tile, based on displayType
 * @param {boolean} addParens - add parenthesis on mobile tile display
 * @param {boolean} showMobileCaption - show caption text on mobile tile display
 * @param {string} groupDisplayName - descriptor copy representing the valueGroup display name
 * @returns {Object} newTile DOM element
 */
function buildTile(v, className, addParens, showMobileCaption, groupDisplayName) {
    var newTile = document.createElement('div'),
        imgSrc = '';

    if (className && className.includes('variant-tile-item') && v.images.src) {
        imgSrc = v.images.src;
    } else if (v.images && v.images.startImage && v.images.startImage !== 'string') {
        imgSrc = v.images.startImage;
    } else {
        imgSrc = v.swatchImage || v.image;
    }

    // create descriptor text
    if (groupDisplayName) {
        var displayName = document.createElement('div');
        displayName.textContent = groupDisplayName;
        displayName.classList.add('group-display-name');
        newTile.appendChild(displayName);
    }

    var tileImage = document.createElement('img');
    tileImage.setAttribute('src', imgSrc || global.grayBlockSrc);
    tileImage.setAttribute('swatch-id', v.id);

    if (groupDisplayName) {
        tileImage.classList.add('group-name-image-margin');
    }

    if (/^29$/.test(v.optionid) || /^shade$/.test(v.id)) {
        tileImage.addEventListener('load', (e) => {
            e.target.setAttribute('style', `width: ${e.target.naturalWidth}px`);
        });
    }

    // create text block
    var textblock = document.createElement('div');
    textblock.classList.add('item-textblock');

    // create name
    var tileName = document.createElement('p');

    $(tileName).addClass('item-name');

    if (v.buttonType === CONST.BUTTON_TYPE.VARIATION && v.value) {
        tileName.textContent = v.value;
    } else {
        tileName.textContent = v.displayValue ? v.displayValue : v.description;
    }

    // append image and name
    if (imgSrc) {
        newTile.appendChild(tileImage);
        newTile.setAttribute('class', className);
    } else {
        newTile.setAttribute('class', className + ' d-md-none');
    }

    textblock.appendChild(tileName);
    textblock.setAttribute('swatch-id', v.id);

    if (v.note && imgSrc) {
        var tileDetails = document.createElement('p');

        tileDetails.classList.add('item-note');

        if (!addParens) {
            tileDetails.classList.add('remove-parens');
        }

        tileDetails.textContent = v.note;
        textblock.append(tileDetails);
    }

    if (v.caption && imgSrc) {
        var tileDetailsCaption = document.createElement('p');
        if (showMobileCaption) {
            $(tileDetailsCaption).addClass('item-caption d-block');
            if (!addParens) {
                $(tileDetailsCaption).addClass('remove-parens');
            }
        } else {
            $(tileDetailsCaption).addClass('item-caption d-none d-md-block');
        }

        tileDetailsCaption.textContent = v.caption;
        textblock.append(tileDetailsCaption);
    }

    if (v.swatchDetails && imgSrc) {
        var tileDetailsSwatch = document.createElement('p');
        $(tileDetailsSwatch).addClass('item-details');
        tileDetailsSwatch.textContent = v.swatchDetails;
        newTile.append(tileDetailsSwatch);
    }

    if (v.buttonType) {
        var classList = imgSrc ? ['btn', 'btn-lt-gray', 'select-mobile-option', 'd-md-none'] : ['btn', 'btn-lt-gray', 'select-mobile-option', 'd-md-none', 'no-image-option'],
            hasGroup = !!$(newTile).children('.group-display-name').length,
            tileButton = null;

        switch (v.buttonType) {
            case CONST.BUTTON_TYPE.VARIATION:
                tileButton = document.createElement('button');

                tileButton.classList.add(...classList);
                tileButton.value = v.value;
                tileButton.dataset.optionVal = v.value;
                tileButton.dataset.attrid = v.id;
                tileButton.textContent = v.displayValue || v.value;

                break;
            case CONST.BUTTON_TYPE.DEFAULT:
                tileButton = document.createElement('button');

                // Build tile button to be displayed for mobile
                tileButton.classList.add(...classList);
                tileButton.value = v.id;
                tileButton.dataset.optionVal = v.id;
                tileButton.textContent = v.displayValue || newTile.textContent;

                break;
            default:
                // noop
        }

        if (hasGroup) {
            tileButton.classList.add('group-name-button-margin');
        }

        newTile.append(tileButton);
    }
    newTile.append(textblock);

    $cache.tileContainer.append(newTile);

    return newTile;
}

/**
 * Creates a list of options for share page
 * @param {Object} prodAttrsJson - json payload of selection data
 * @returns {string} options selected by user
 */
function createOptionsObject(prodAttrsJson) {
    var options = {};
    for (var i = 0; i < prodAttrsJson.length; i++) {
        var item = prodAttrsJson[i];
        item.selectedId = item.selectedValueId;
        options[item.id] = item;
    }
    return options;
}

/**
 * builds out the design matrix based on user selections
 */
function buildDesignMatrix() {
    var prodAttrsJson = $('.display-tile-container').data('config-data');
    var $matrixItem = '';
    var $matrixItemMobile = '';
    var options = $('.is-shared-page').length ? createOptionsObject(prodAttrsJson) : global.shareConfObj;

    var optionOrder = [];
    // applies to configurator SaveAndReview options
    $('.configurator-results-wrapper .result-value').each(function () {
        var opId = $(this).data('result-id');
        optionOrder.push(opId);
    });
    // applies to shared configurator options
    $('.configurator-results-wrapper p.result-list-item').each(function () {
        var opId = $(this).data('result-id');
        if (optionOrder.indexOf(opId) < 0) {
            optionOrder.push(opId);
        }
    });

    for (var idx = 0; idx < optionOrder.length; idx++) { // eslint-disable-line
        var optionId = optionOrder[idx].toString();
        if (Object.prototype.hasOwnProperty.call(options, optionId)) {
            var colorId = options[optionId].selectedId;
            if (optionId && colorId) {
                for (var i = 0; i < prodAttrsJson.length; i++) {
                    var item = prodAttrsJson[i];
                    if (item && item.id && item.id === optionId && item.displayType && item.displayType === 'swatch' && item.values && item.values.length) {
                        for (var j = 0; j < item.values.length; j++) {
                            var colorObj = item.values[j];
                            if (colorId === colorObj.id) {
                                $matrixItem += '<div class="design-matrix__item col-4 col-lg-3 col-xl-2 swatch-modal"><div class="design-matrix__swatch"><img src="' + colorObj.swatchImage + '" swatch-id="' + colorId + '"></div><div class="design-matrix__swatch-label">' + item.name + ':<br/>' + colorObj.displayValue + '</div></div>';
                                $matrixItemMobile += '<div class="design-matrix__item col-sm-2 swatch-modal"><div class="design-matrix__swatch"><img src="' + colorObj.swatchImage + '" swatch-id="' + colorId + '"></div><div class="design-matrix__swatch-label">' + item.name + ':<br/>' + colorObj.displayValue + '</div></div>';
                            }
                        }
                    }
                }
            }
        }
    }

    var $designMatrix = $('<div class="design-matrix row swatch-value-group w-100 value-group-2"><h6 class="design-matrix__title">Design Selections</h6><div class="design-matrix__items">' + $matrixItem + '</div></div>');
    var $designMatrixMobile = $('<div class="d-sm-none design-matrix row swatch-value-group w-100 value-group-2"><h6 class="design-matrix__title">Design Selections</h6><div class="design-matrix__items">' + $matrixItemMobile + '</div></div>');


    if ($('.sidemark-image-container').length) {
        $('.sidemark-image').hide();
        $('.sidemark-image-container').eq(0).prepend($designMatrix);
        $('.sidemark-image-container').eq(1).prepend($designMatrixMobile);
    }
}


/**
 * builds out the tile image display
 * @param {Object} v - currently selected configurator option
 */
function buildAndAppendMainImage(v) {
    /**
     * ECOMM-511: Product Configurator. Per ticket Added d-none class when at
     * endImage (sidemark-image), so last image is hidden.
     */
    var className = v.displayType === keys.swatch ? 'main-swatch-image' : 'sidemark-image d-none',
        img = $('.hidden-detail-image').data('details-img'),
        mainImage = '<div class="col-12 sidemark-image-container"><img class="'
            + className + '" src="'
            + ((className === 'sidemark-image' && img) ? img : v.image) + '" alt="'
            + v.name + '"></div>';

    $cache.tileContainer.prepend(mainImage);
    $cache.swatchTooltip.html(mainImage);

    if (v.displayType === keys.saveAndReview) {
        $cache.resultImage.html(mainImage);
    }

    if ($('.saveand-review-display').length) {
        buildDesignMatrix();
    }
}

/**
 * create list of classes for swatch depending on its state
 * @param {Object} op - currently selected configurator option
 * @param {Object} swatchObj - swatch object
 * @param {string} className - class based on displayType
 * @returns {string} list of classes
 */
function swatchClassList(op, swatchObj, className) {
    var $activeDropdown = getActiveDropdownById(op.id),
        selectedVal = $activeDropdown.find('select').val(),
        selectedId = $activeDropdown.find('option[selected=true], option[selected=selected]').attr('id'),
        active = swatchObj.displayValue === selectedVal && swatchObj.id === selectedId;
    return active ? className + ' active-swatch' : className;
}

/**
  * determines if all ValueGroup values are the same price and, if so, calculates price
  * @param {Object} op - currently selected configurator option
  * @param {Object} groupItem - current option ValueGroup
  * @returns {Object} pricing details
  */
function calculateGroupPricing(op, groupItem) {
    var groupPricing;
    var groupPrice;

    for (var i = 0; i < groupItem.Values.length; i++) {
        var itemData = op.values.filter(function (x) { return x.id === groupItem.Values[i] })[0]; // eslint-disable-line
        if (itemData && itemData.customerVisible) {
            var excluded = $('.dropdown-item[id="' + itemData.id + '"]').hasClass('d-none');
            if (!excluded) {
                if (!groupPricing) {
                    groupPrice = parseInt(itemData.priceValue);
                    groupPricing = true;
                } else if (parseInt(itemData.priceValue) !== groupPrice) {
                    groupPrice = null;
                    groupPricing = false;
                    break;
                }
            }
        }
    }

    return { groupPricing: groupPricing, groupPrice: groupPrice };
}

/**
 * modify display panel and dropdowns based on visible swatches - to be called after updateExcludedSwatches()
 * @param {Object} op - currently selected configurator option
 */
function processGroupPricing(op) {
    var valueGroupPricing = {};
    var currentDropdownMenu = $('#new-' + op.id).closest('.custom-dropdown').find('.dropdown-menu');

    if (op.valueGroups) {
        Object.entries(op.valueGroups).forEach(function (group) {
            var valueGroupName = group[0];
            var valueGroupObj = group[1];

            if (valueGroupObj.Values.length) {
                valueGroupPricing[valueGroupName] = calculateGroupPricing(op, valueGroupObj);
            }
        });
    }

    // UPDATE DISPLAY PANEL
    // for swatches
    if (op.displayType === keys.swatch) {
        $('.display-tile-container').find('.swatch-group-title:not(.d-none)').each(function () {
            // use first child to reliably determine current valueGroup
            var firstChildId = $(this).closest('.swatch-value-group').find('.item-textblock').attr('swatch-id');
            var firstChildOp = op.values.filter(function (v) { return v.id === firstChildId; })[0];
            var groupItem = Object.entries(op.valueGroups).filter(function (group) {
                return group[1].Values.findIndex(function (val) {
                    return val === firstChildOp.id;
                }) > -1;
            })[0];
            var groupItemName = groupItem[0];
            var groupItemObj = groupItem[1];

            // update headers
            if (valueGroupPricing[groupItemName].groupPricing) {
                $(this).text(valueGroupPricing[groupItemName].groupPrice > 0 ? groupItemObj.DisplayName + ' (+$' + numberWithCommas(valueGroupPricing[groupItemName].groupPrice) + ')' : groupItemObj.DisplayName);
            } else if (valueGroupPricing) {
                $(this).text(groupItemObj.DisplayName);
            }

            // update all swatches under the header
            groupItemObj.Values.forEach(function (val) {
                var currentOp = op.values.find(function (v) {
                    return v.id === val;
                });
                var tileName = $('.item-textblock[swatch-id=' + currentOp.id + '] p');
                var textContent = currentOp.displayValue ? currentOp.displayValue : currentOp.description;
                if (valueGroupPricing[groupItemName] && !valueGroupPricing[groupItemName].groupPricing && currentOp.priceValue && currentOp.priceValue > 0) {
                    textContent = textContent + ' (+$' + numberWithCommas(parseInt(currentOp.priceValue)) + ')';
                }
                tileName.text(textContent);
            });
        });
    // for non-swatches
    } else if (op.id !== keys.variation && op.displayType !== keys.overallHeight && op.displayType !== keys.sidemark && op.displayType !== keys.saveAndReview) {
        $('.display-tile-container').find('.tile-container-item').each(function () {
            var currentSwatchId = $(this).find('.item-textblock').attr('swatch-id');
            var groupItem = Object.entries(op.valueGroups).filter(function (group) {
                return group[1].Values.findIndex(function (val) {
                    return val === currentSwatchId;
                }) > -1;
            })[0];
            var groupItemName = groupItem[0];
            var groupItemObj = groupItem[1];

            // update headers
            if (valueGroupPricing[groupItemName].groupPricing) {
                $(this).find('.group-display-name').text(valueGroupPricing[groupItemName].groupPrice > 0 ? groupItemObj.DisplayName + ' (+$' + numberWithCommas(valueGroupPricing[groupItemName].groupPrice) + ')' : groupItemObj.DisplayName);
            } else if (valueGroupPricing) {
                $(this).find('.group-display-name').text(groupItemObj.DisplayName);
            }

            // update all swatches under the header
            groupItemObj.Values.forEach(function (val) {
                var currentOp = op.values.find(function (v) {
                    return v.id === val;
                });
                var itemName = $('.item-textblock[swatch-id=' + currentOp.id + '] .item-name');
                var textContent = currentOp.displayValue ? currentOp.displayValue : currentOp.description;
                if (valueGroupPricing[groupItemName] && !valueGroupPricing[groupItemName].groupPricing && currentOp.priceValue && currentOp.priceValue > 0) {
                    textContent = textContent + ' (+$' + numberWithCommas(parseInt(currentOp.priceValue)) + ')';
                }
                itemName.text(textContent);
            });
        });
    }

    // UPDATE DROPDOWNS
    // update selections
    if (op.id !== keys.variation && op.displayType !== keys.overallHeight && op.displayType !== keys.sidemark && op.displayType !== keys.saveAndReview) {
        currentDropdownMenu.find('.dropdown-item:not(d-none)').each(function () {
            var dropdownItem = $(this);
            var selectedOp = op.values.filter(function (v) { return v.id === dropdownItem.attr('id'); })[0];
            var selectedValueGroupName = Object.entries(op.valueGroups).filter(function (group) {
                return group[1].Values.findIndex(function (val) {
                    return val === selectedOp.id;
                }) > -1;
            })[0][0];
            if (valueGroupPricing[selectedValueGroupName] && valueGroupPricing[selectedValueGroupName].groupPricing) {
                $(this).text(selectedOp.displayValue);
            } else if (valueGroupPricing[selectedValueGroupName] && selectedOp.priceValue && selectedOp.priceValue > 0) {
                $(this).text(selectedOp.displayValue + ' (+$' + numberWithCommas(parseInt(selectedOp.priceValue)) + ')');
            }
        });

        // update headers
        $(currentDropdownMenu).find('.dropdown-header').each(function () {
            var currentHeader = $(this);
            var groupItem = Object.entries(op.valueGroups).filter(function (group) {
                return currentHeader.text().trim().length && currentHeader.text().trim().replace(/ (\(\+\$[\d,]+\))/g, '').replace(group[1].DisplayName.trim().replace(/ (\(\+\$[\d,]+\))/g, ''), '').length === 0;
            })[0];
            var groupItemName = groupItem[0];
            var groupItemObj = groupItem[1];

            if (valueGroupPricing[groupItemName].groupPricing) {
                $(this).text(valueGroupPricing[groupItemName].groupPrice > 0 ? groupItemObj.DisplayName + ' (+$' + numberWithCommas(valueGroupPricing[groupItemName].groupPrice) + ')' : groupItemObj.DisplayName);
            } else if (valueGroupPricing) {
                $(this).text(groupItemObj.DisplayName);
            }
        });
    }
}

/**
 * builds out swatch panel
 * @param {Object} op - currently selected configurator option
 * @param {string} className - list of classes
 * @param {Object} groupItem - swatches group
 * @param {string} index - swatch index
 * @param {Object} currentSwatch - current swatch element
 */
function buildAndAppendSwatchPanel(op, className, groupItem, index, currentSwatch) {
    var tiles = [];
    var classList = 'row swatch-value-group w-100 value-group-' + index + '';
    var valueGroupWrapper = '<div class="' + classList + '"></div>'; // wrapper for swatches group
    var groupTitle = '<h4 class="swatch-group-title col-12">'
        + groupItem.DisplayName
        + ' <span class="swatches-title"></span></h4>';

    // append group wrappers
    $cache.tileContainer.append(valueGroupWrapper);

    // Append title for each group
    $('.value-group-' + index + '').append(groupTitle);

    // build tiles for each swatch item
    groupItem.Values.forEach(function (v) {
        var swatchItem = op.values.filter(function (x) { return x.id === v; });
        var swatchObj = swatchItem[0]; // swatch assigned to the same group

        if (swatchObj && swatchObj.customerVisible) {
            // add active state for swatch
            var groupClassList = swatchClassList(op, swatchObj, className);

            if (swatchObj.swatchImage) {
                global.popupSwatchImages.push({ swatchObj: swatchObj, currentSwatch: currentSwatch });
                tiles.push(buildTile(swatchObj, groupClassList + ' '));
            }
        }
    });

    // remove dropdown-headers for empty price types
    if (tiles.length <= 0) {
        $('.value-group-' + index + '').find('.swatch-group-title').addClass('d-none');
        var currentDropdownMenu = $('#new-' + op.id).closest('.custom-dropdown').find('.dropdown-menu');
        $(currentDropdownMenu).find('.dropdown-header').each(function () {
            var selectionText = $(this).text();
            if (selectionText === groupItem.DisplayName) {
                $(this).addClass('cust-group-hidden');
            }
        });
    }

    // push swatches from the same group to wrapper
    tiles.forEach(function (tile) {
        $('.value-group-' + index + '').append(tile);
    });
}

/**
 * update swatch popup info
 * @param {Object} op - currently selected configurator option
 */
function updateSwatchPopupInfo(op) {
    $('.popup-swatch-title').html(op.name);
    $('.select-swatch span').html(op.name);
    $('.popup-swatch-info').html(op.description);
    $('.popup-swatch-img').attr('src', op.image);
}

/**
 * hide excluded swatches
 * @param {Object} op - currently selected configurator option
 */
function updateExcludedSwatches(op) {
    var excludedSwatches;
    var $activeDropdown = getActiveDropdownById(op.id);

    $activeDropdown.find('.dropdown-item').removeClass('d-none');
    $activeDropdown.find('.dropdown-header').removeClass('d-none');

    $('.custom-dropdown-container').each(function (index, element) {
        var data = $(element).data('dropdown-data');

        if (data && data.displayType !== keys.sidemark) {
            var $thisDropdown = getActiveDropdownById(data.id);
            var thisId = $thisDropdown.find('option:selected').attr('id') || $thisDropdown.find('option[selected=selected]').attr('id');
            var idData = data.values && data.values.filter(function (obj) {
                return obj.id === thisId;
            });
            excludedSwatches = idData && idData.length && idData[0].hasOwnProperty('optionValueRules') ? idData[0].optionValueRules : '';

            if (excludedSwatches && excludedSwatches[op.id]) {
                var optionValueExclusions = excludedSwatches[op.id].Exclude || [];
                $.each(optionValueExclusions, function (idx, el) {
                    // loop over the global.popupSwatchImages obj and remove excluded swatches
                    $.each(global.popupSwatchImages, function (globalI, globalEl) {
                        if (globalEl && globalEl.swatchObj && (globalEl.swatchObj.id === el)) {
                            global.popupSwatchImages.splice(globalI, 1);
                        }
                    });
                    var selectedVal = $activeDropdown.find('option:selected').attr('id') || $activeDropdown.find('option[selected=selected]').attr('id');

                    // hide excluded values from dropdown
                    $activeDropdown.find('.dropdown-menu #' + el + '').addClass('d-none');
                    // hide excluded images/swatches from left pane
                    if (op.displayType === keys.swatch) {
                        $cache.tileContainer.find('.swatch-modal img[swatch-id="' + el + '"]').parent().addClass('d-none');
                    } else {
                        $cache.tileContainer.find('.tile-container-item img[swatch-id="' + el + '"]').parent().addClass('d-none');
                        $cache.tileContainer.find('.tile-container-item div[swatch-id="' + el + '"]').parent().addClass('d-none');
                    }

                    // remove selection from dropdown if it's an excluded option
                    if (el === selectedVal) {
                        $activeDropdown.find('select').val('');
                        if ($activeDropdown.find('option:selected').attr('id')) {
                            $activeDropdown.find('option:selected').removeAttr('selected');
                        }
                        if ($activeDropdown.find('option[selected=selected]').attr('id')) {
                            $activeDropdown.find('option[selected=selected]').removeAttr('selected');
                        }
                        $activeDropdown.find('select').change();
                    }
                });

                // var totalNumSwatchesLeft = 0;
                // var lastSwatchId = 0;
                $('.swatch-value-group').each(function (itemIndex, item) {
                    var title = $(item).find('.swatch-group-title');

                    var swatchesLeftVisibleInSection = $(item).find('.swatch-modal:not(.d-none)');
                    var numSwatchesLeft = swatchesLeftVisibleInSection.length;
                    // totalNumSwatchesLeft += numSwatchesLeft;

                    if (numSwatchesLeft <= 2) { // No need to do this processing if more than two swatches left in section
                        var numNonTBDSwatchesLeft = 0;
                        var tbdSwatch;
                        var tbdSwatchId;

                        // Check to see if there are remaining swatches besides tbd
                        $(swatchesLeftVisibleInSection).each(function () {
                            var swatchId = $(this).find('img').attr('swatch-id');
                            if (swatchId.indexOf('tbd') !== -1) {
                                tbdSwatch = $(this);
                                tbdSwatchId = swatchId;
                                // totalNumSwatchesLeft--;
                            } else {
                                // lastSwatchId = swatchId;
                                numNonTBDSwatchesLeft += 1;
                            }
                        });

                        // If only tbd swatch is left, do not display
                        if (numNonTBDSwatchesLeft === 0) {
                            $(tbdSwatch).addClass('d-none');
                            $activeDropdown.find('.dropdown-menu #' + tbdSwatchId + '').addClass('d-none');
                        }
                    }

                    if ($(item).find('.swatch-modal:not(.d-none)').length) {
                        title.removeClass('d-none');
                    } else {
                        title.addClass('d-none');
                        $activeDropdown.find('.dropdown-header').each(function () {
                            if (title.text().trim() === $(this).text().trim()) {
                                $(this).addClass('d-none');
                            }
                        });
                    }
                });

                // remove empty headers from dropdown for non-swatch options
                if (op.displayType !== keys.swatch) {
                    $activeDropdown.find('.dropdown-header').each(function () {
                        var header = $(this);
                        var count = 0;
                        $('.display-tile-container').find('.tile-container-item:not(.d-none)').each(function () {
                            if ($(this).find('.group-display-name').text().trim().replace(/ (\(\+\$[\d,]+\))/g, '') === header.text().trim().replace(/ (\(\+\$[\d,]+\))/g, '')) { // eslint-disable-line
                                count += 1;
                            }
                        });

                        if (count <= 0) {
                            $(this).addClass('d-none');
                        }
                    });
                    $activeDropdown.find('.dropdown-header').removeAttr('style');
                    if ($activeDropdown.find('.dropdown-header').first().hasClass('d-none')) {
                        $($activeDropdown.find('.dropdown-header:visible').first()).attr('style', 'margin-top: 0px');
                    }
                }
            }
        }
    });

    // build swatch slider
    $(global.popupSwatchImages).each(function (index, element) {
        var groupSwatchText = element.swatchObj.id === 'tbd-ralcolor' || element.swatchObj.id === 'tbd-custom-paint' ? 'Custom' : element.currentSwatch;
        var swatchName = element.swatchObj.displayValue;
        var popupImg = element.swatchObj.image || global.grayBlockSrc,
            swatchImg = '<img current-group-swatch="' + groupSwatchText
                + '" src="' + popupImg
                + '" popup-slide-id="' + element.swatchObj.id
                + '" popup-swatch-name="' + swatchName
                + '">';

        if (popupImg) {
            $cache.swatchSlider.append(swatchImg);
        }
    });
}

/**
 * builds out the swatch display on the left
 * @param {Object} op - currently selected configurator option
 * @param {string} className - class based on displayType
 */
function buildAndAppendSwatchTiles(op, className) {
    // Sections that should be hidden/displayed fot this displayType
    buildPanelView(op.displayType);

    // append main image for swatch Panel
    buildAndAppendMainImage(op);

    Object.entries(op.valueGroups).forEach(function (group, index) {
        var valueGroupName = group[0];
        var valueGroupObj = group[1];

        // Build list of swatches in the left panel
        buildAndAppendSwatchPanel(op, className, valueGroupObj, index, valueGroupName);
    });

    updateSwatchPopupInfo(op);

    // hide excluded swatches
    updateExcludedSwatches(op);

    processGroupPricing(op);
}

/**
 * builds out the Overall Height section on the left
 * @param {Object} op - currently selected configurator option
 * @param {string} className - class based on displayType
 */
function buildAndAppendOverallHeight(op, className) {
    var $activeDropdown = getActiveDropdownById(op.id),
        $activeOverallClone = $activeDropdown.last().clone();

    var overallHeightText = $('.custom-dropdown-container[data-dropdown-id="' + op.id + '"]').children('.dropdown-overall-wrapper').first().data('overall-title'),
        overallHeightTitle = '<h5 class="text-center">' + overallHeightText + '</h5>',
        tileButton = document.createElement('button');

    buildPanelView(op.displayType);

    // Show dropdown overall height info
    $cache.overallHeightDropdown.html($activeOverallClone);
    $cache.overallHeightDropdown.find('.custom-dropdown-container').prepend(overallHeightTitle);
    $cache.tileContainer.append(buildTile(op, className));

    // Build tile button to be displayed for mobile
    $(tileButton).addClass('btn btn-lt-gray select-overall-option d-md-none w-100 text-center');

    tileButton.textContent = overallHeightText;
    $cache.overallHeightDropdown.append(tileButton);
}

/**
 * builds out the default tile display
 * @param {Object} op - currently selected configurator option
 *  @param {string} className - class based on displayType
 */
function buildAndAppendTiles(op, className) {
    var tiles = [],
        defaultDisplay = op.values.length > 1 ? 'col-md-6 tile-container-item' : 'col-md-12 tile-container-item',
        displayClass = className || defaultDisplay,
        addParens = op.addParens,
        showMobileCaption = op.showMobileCaption;

    buildPanelView(op.displayType);

    op.values.forEach(function (v) {
        var groupDisplayName;

        if (op.valueGroups) {
            Object.keys(op.valueGroups).forEach(function (key) {
                if (op.valueGroups[key].Values) {
                    if (op.valueGroups[key].Values.indexOf(v.id) > -1) {
                        groupDisplayName = op.valueGroups[key].DisplayName;
                    }
                }
            });
        }

        v.buttonType = CONST.BUTTON_TYPE.DEFAULT;

        tiles.push(buildTile(v, displayClass, addParens, showMobileCaption, groupDisplayName));
    });

    // hide excluded selections from left pane
    updateExcludedSwatches(op);

    processGroupPricing(op);

    if (op.id === keys.variation) {
        op.values.forEach(function (el) {
            $('.tile-container-item img[swatch-id="' + el.id + '"]')
                .parent()
                .next()
                .after('<span class="tile-price d-md-none">' + el.price.salesPrice.formatted + '</span>');
        });
    }
}

/**
 * Determine a value has been selected on all variation dropdowns within Configurator-Show/Variation
 * @returns {boolean} true, if all variation dropdowns have a selected value, otherwise false
 */
function isVariationSelected() {
    var selects = document.querySelectorAll('.mobile-select.variation'),
        result = !selects.length;

    if (!result) {
        selects = Array.from(selects);

        result = selects.every(el => {
            let opt = el.querySelector('option:checked');

            return /^[^-].+$/.test(opt.value);
        });
    }

    return result;
}

/**
 * Update the variation dropdowns with values based on the most recent variation attribute/value selection
 * @returns {void} No return
 */
function updateVariationDropdownValues() {
    var reqData = {};

    reqData.maps = [];

    $('.mobile-select.variation option:selected').each((idx, el) => {
        if (el.dataset.attrid && el.value) {
            reqData.maps.push({ attrId: el.dataset.attrid, value: el.value });
        }
    });

    reqData.pid = window.configurator && window.configurator.baseProductId;

    $.ajax({
        url: window.Urls.CONFIGURATOR_VARIATION_VALUES_UPDATE,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        dataType: 'json',
        data: JSON.stringify(reqData),
        async: false
    })
        .done(resp => {
            var data = null;

            // rebuild variationImages stored in window.configurator
            if (resp && resp.configuratorJSON) {
                window.configurator.variationImages = JSON.parse(resp.configuratorJSON).variationImages;
            }
            if (resp && resp.valuesUpdate) {
                data = resp.valuesUpdate;

                Object.keys(data).forEach(attrID => {
                    var $menu = $(`.dropdown-menu[data-attrid=${attrID}]`),
                        $select = $(`.mobile-select.variation[data-attrid=${attrID}]`),
                        $selectedOpt = $select.find('option:selected'),
                        selectedValue = $selectedOpt.length ? $selectedOpt.val() : null;

                    $menu.find('.dropdown-item').remove();
                    $select.find('option').filter(idx => { return idx > 0; }).remove();
                    if (!$menu.siblings('button').find('span').length) {
                        $menu.siblings('button').text('-');
                    }

                    data[attrID].forEach(valObj => {
                        var opt = document.createElement('option'),
                            item = document.createElement('div');

                        item.classList.add('dropdown-item');
                        item.dataset.attrid = attrID;
                        item.dataset.optionVal = valObj.value;
                        item.textContent = valObj.displayValue;

                        opt.dataset.attrid = attrID;
                        opt.value = valObj.value;
                        opt.text = valObj.value;

                        if (valObj.selected) {
                            $menu.siblings('button').val(valObj.value).text(valObj.value);
                            opt.selected = true;
                            $(opt).attr('selected', true);

                            if ($select.prop('disabled')) {
                                $select.trigger('change');
                            }
                        }

                        $menu.append(item);
                        $select.append(opt);
                    });

                    if (!$select.find('option:selected').length) {
                        $selectedOpt = $select.find(`option[value=${selectedValue}]`);

                        if ($selectedOpt.length) {
                            $selectedOpt.prop('selected', true);
                            $selectedOpt.attr('selected', true);
                        }
                    } else if ($select.find('option').length === 1) {
                        $select.find('option').prop('selected', true);
                        $select.find('option').attr('selected', true);
                    }
                });
            }
        });
}

/**
 * Checks if a tile has already been added to DOM to avoid duplication
 * @param {Object} tileInfo object containing tile info
 * @returns {boolean} return true if there is already a matching tile
 */
function tileAlreadyBuilt(tileInfo) {
    var alreadyBuilt = false;
    $cache.tileContainer.find('.tile-container-item button').each(function (idx, btn) {
        if ($(btn).data('attrid') === tileInfo.id && $(btn).attr('value') === tileInfo.value) {
            alreadyBuilt = true;
        }
    });
    return alreadyBuilt;
}

/**
 * builds out the variant tile display
 * @param {Object} op - currently selected configurator option
 * @param {string} className - class based on displayType (determines display settings)
 */
function buildAndAppendVariantTiles(op, className) {
    var defaultDisplay = op.values.length > 1 ? 'col-md-6 tile-container-item native-image-size' : 'col-md-12 tile-container-item native-image-size';
    var displayClass = className || defaultDisplay;
    displayClass += ' variant-tile-item';

    buildPanelView(op.displayType);

    updateVariationDropdownValues();

    $(`.dropdown-item[data-attrid="${op.id}"]`).each((idx, el) => {
        let v = {};

        v.id = op.id;
        v.images = window.configurator.variationImages[op.id][el.dataset.optionVal];
        v.value = el.dataset.optionVal;
        v.displayValue = el.textContent;
        v.buttonType = CONST.BUTTON_TYPE.VARIATION;

        // prevent async building of downstream variationAttr tiles as well as tile duplication due to async processing
        if (v.id === $cache.tileContainer[0].dataset.selectedId && !tileAlreadyBuilt(v)) {
            buildTile(v, displayClass);
        }
    });

    // hide excluded selections from left pane
    updateExcludedSwatches(op);
}

/**
 * builds out the sidemark display
 * @param {Object} op - currently selected configurator option
 */
function buildAndAppendSidemark(op) {
    buildPanelView(op.displayType);
    buildAndAppendMainImage(op);
}

/**
 * Build the custom selected input data based on the type of the custom dropdown
 * @param {number} dropdownID - drop down ID
 * @returns {string} custom data built as a string to be appended to the original value
 */
function getCustomColorString(dropdownID) {
    var customOption = global.shareCustomForms[dropdownID];

    if (!customOption || !customOption.selectedVal) {
        return '';
    }

    var initialValue = '';
    var initialSeparator = ' / ';
    var targetCustomValueMarker = '{{CUSTOM_VAL}}';
    var selectedCustomVal;

    switch (customOption.selectedVal) {
        // if different custom values needs to be formated differently, we can change this initial value
        case CONST.CUSTOM_COLOR.PAINT_SELECTION:
            initialValue = ' - ' + targetCustomValueMarker;
            selectedCustomVal = customOption.selectedCustomVal;
            break;
        case CONST.CUSTOM_COLOR.POWDER_COAT:
            initialValue = ' - ' + targetCustomValueMarker;
            selectedCustomVal = customOption.selectedCustomVal;
            break;
        case CONST.CUSTOM_COLOR.COM:
            initialValue = ' - ' + targetCustomValueMarker;
            // Only display non-blank keys on productSummary
            selectedCustomVal = customOption.selectedCustomVal.filter(function (val) {
                return val.value.length > 0;
            });
            break;
        default:
            break;
    }

    var customData = selectedCustomVal.reduce(function (accumulator, currentFormValue) {
        return accumulator !== '' ? accumulator + initialSeparator + currentFormValue.value : currentFormValue.value;
    }, '');

    return initialValue.replace(targetCustomValueMarker, customData);
}

/**
 * Update save and review displayed data for all resolutions
 */
function updateReviewData() {
    for (var dropdownItem in global.shareConfObj) { // eslint-disable-line
        var $item = $(".result-value[data-result-id='" + dropdownItem + "']"),
            val = null;

        if (global.shareConfObj[dropdownItem].selectedVal === keys.variant) {
            val = window.configurator && window.configurator.selectedVariant ? window.configurator.selectedVariant.id : null;

            if (val) {
                // eslint-disable-next-line no-loop-func
                $('.variation').data('dropdown-data').values.forEach(function (v) {
                    if (v.id === val) {
                        $('.variation-key-value').append('<span class="result-list-item">' + v.displayValue + '</span>');
                        $('.variation-key-value').removeClass('d-none');
                    }
                });
            }
        } else if (global.shareConfObj[dropdownItem].selectedVal === keys.sidemark) {
            val = global.shareConfObj[dropdownItem].selectedId;
        } else {
            val = global.shareConfObj[dropdownItem].selectedVal + getCustomColorString(dropdownItem);
        }

        if (val && val.length) {
            $item.append('<span class="result-list-item">' + val + '</span>');
            $item.removeClass('d-none');
        }
    }

    $('.review-product-name').text($('.sizedropdown select').val());
    $('.review-product-item-price').text($('.configurator-item-price').text());
    $('.review-product-quantity-value').text($('.configurator-item-quantity select').val());
    $('.review-product-total-price').text($('.configurator-total-price').text());
}

/**
 * builds out the save and review display
 * @param {Object} op - currently selected configurator option
 *  @param {string} className - class based on displayType
 */
function buildAndAppendSaveAndReview(op) {
    var $resultListItem = $('.result-list-item'),
        $excludedDropdowns = $('.custom-dropdown-container.d-none');

    // to not fire ajax call once form is submitted
    global.isSubmitPressed = true;

    // Update size object
    global.shareConfObj[keys.variant] = {
        selectedVal: keys.variant,
        selectedId: $('.sizedropdown option:selected').attr('id')
    };

    // remove excluded items from global.shareConfObj
    $excludedDropdowns.each(function (index, el) {
        var id = $(el).attr('data-dropdown-id');

        $(".result-value[data-result-id='" + id + "']").addClass('d-none');
        deleteIdFromShareConfObj(id);
    });

    // Update sidemark object
    global.shareConfObj[keys.sidemark] = {
        selectedVal: keys.sidemark,
        selectedId: $cache.sidemarkInput.val()
    };

    // Sections that should be hidden/displayed fot this displayType
    buildPanelView(op.displayType);

    // Add main image for save and review Panel
    buildAndAppendMainImage(op);

    // update displayed data for desktop and mobile
    $resultListItem.remove();
    updateReviewData();
}

/**
 * determines which displaytype to build
 * @param {Object} op - currently selected configurator option
 */
function buildDisplayBasedOnType(op) {
    if (op.isVariationAttribute) {
        switch (op.displayType) {
            case '4per-image':
                buildAndAppendVariantTiles(op, 'col-md-6 col-xl-3 tile-container-item');
                break;
            case '3per-image':
                buildAndAppendVariantTiles(op, 'col-md-4 tile-container-item');
                break;
            case '2per-image':
                buildAndAppendVariantTiles(op, 'col-md-6 tile-container-item');
                break;
            case '1per-image':
                buildAndAppendVariantTiles(op, 'col-md-12 justify-content-center tile-container-item');
                break;
            case '4per-image-native-size':
                buildAndAppendVariantTiles(op, 'col-md-6 col-xl-3 tile-container-item native-image-size');
                break;
            case '3per-image-native-size':
                buildAndAppendVariantTiles(op, 'col-md-4 tile-container-item native-image-size');
                break;
            case '2per-image-native-size':
                buildAndAppendVariantTiles(op, 'col-md-6 tile-container-item native-image-size');
                break;
            case '1per-image-native-size':
                buildAndAppendVariantTiles(op, 'col-md-12 justify-content-center native-image-size tile-container-item');
                break;
            case keys.swatch:
                buildAndAppendSwatchTiles(op, 'col-4 col-lg-3 col-xl-2 swatch-modal');
                break;
            case 'variant':
                buildAndAppendVariantTiles(op);
                break;
            default:
                buildAndAppendVariantTiles(op);
                break;
        }
    } else {
        switch (op.displayType) {
            case '4per-image':
                buildAndAppendTiles(op, 'col-md-6 col-xl-3 tile-container-item');
                break;
            case '3per-image':
                buildAndAppendTiles(op, 'col-md-4 tile-container-item');
                break;
            case '2per-image':
                buildAndAppendTiles(op, 'col-md-6 tile-container-item');
                break;
            case '1per-image':
                buildAndAppendTiles(op, 'col-md-12 justify-content-center tile-container-item');
                break;
            case '4per-image-native-size':
                buildAndAppendTiles(op, 'col-md-6 col-xl-3 tile-container-item native-image-size');
                break;
            case '3per-image-native-size':
                buildAndAppendTiles(op, 'col-md-4 tile-container-item native-image-size');
                break;
            case '2per-image-native-size':
                buildAndAppendTiles(op, 'col-md-6 tile-container-item native-image-size');
                break;
            case '1per-image-native-size':
                buildAndAppendTiles(op, 'col-md-12 justify-content-center tile-container-item native-image-size');
                break;
            case keys.overallHeight:
                buildAndAppendOverallHeight(op, 'col-md-12 justify-content-center overall-tile');
                break;
            case keys.swatch:
                buildAndAppendSwatchTiles(op, 'col-4 col-lg-3 col-xl-2 swatch-modal');
                break;
            case keys.sidemark:
                buildAndAppendSidemark(op, 'col-md-12 justify-content-center sidemark-wrapper');
                break;
            case keys.saveAndReview:
                buildAndAppendSaveAndReview(op, 'col-md-12 justify-content-center save-review-wrapper');
                break;
            default:
                buildAndAppendTiles(op);
                break;
        }
    }
}

/**
 * update text and attributes for active panel wrapper
 * @param {string} text - currently selected configurator name
 * @param {string} option - currently selected configurator option
 * @param {string} id - currently selected configurator id
 */
function updateAttrForDisplayedPanel(text, option, id) {
    $cache.optionName.text(text);
    $cache.optionName.attr('data-id', id);
    $cache.tileContainer.attr('data-selected-option', option);
    $cache.tileContainer.attr('data-selected-id', id);
}

/**
 * builds out the configurator display panel based on option
 * @param {Object} op - currently selected configurator option
 */
function buildDisplay(op) {
    if (!op) {
        return;
    }

    if (op.id && op.id === keys.sidemark) {
        updateAttrForDisplayedPanel(op.name, op.name, op.id);
    } else if (op.id && op.id === keys.saveAndReview) {
        updateAttrForDisplayedPanel('', op.name, op.id);
    } else if (op.name) {
        updateAttrForDisplayedPanel(op.name, op.name, op.id);
    } else {
        updateAttrForDisplayedPanel(op.description, op.id, op.id);
    }

    if (op.isVariationAttribute) {
        $cache.optionDescription.html('');
    } else {
        $cache.optionDescription.html(op.description || '');
        if ($cache.optionDescription.find('a').length) {
            $cache.optionDescription.find('a').attr({
                target: '_blank',
                rel: 'noopener'
            });

            if (op.descriptionLink) {
                $cache.optionDescription.find('a').attr({
                    href: op.descriptionLink
                });
            }
        }
    }

    buildDisplayBasedOnType(op);
}

/**
 * removes disabled classes
 * @param {jQuery} op - configurator option
 */
function enableOption(op) {
    op.removeClass('disabled');
    op.attr('data-disabled', false);
    op.children('.custom-dropdown').children('.custom-dropdown-toggle').attr('data-toggle', 'dropdown');
    op.children('.mobile-select-container').children('.mobile-select').attr('disabled', false);
}

/**
 * adds disabled classes
 * @param {jQuery} op - configurator option
 */
function disableOption(op) {
    op.addClass('disabled');
    op.attr('data-disabled', true);
    op.children('.custom-dropdown').children('.custom-dropdown-toggle').attr('data-toggle', 'dropdown');
    op.children('.mobile-select-container').children('.mobile-select').attr('disabled', true);
}
/**
 * Function to disable all Variation Options
 */
function disableVariationOptions() {
    $cache.variationDropdownCtnr.each(function () {
        disableOption($(this));
    });
}
/**
 * enables preceding siblings
 * @param {jQuery} op - configurator option
 */
function enablePrevSibling(op) {
    if (op.prev() && op.prev().hasClass('disabled')) {
        // if there is a sibling preceeding the current option
        // enable the previous sibling
        // rerun this function on the previous sibling to continue the chain
        enableOption(op.prev());
        enablePrevSibling(op.prev());
    }
}
/**
 * enables preceding siblings
 * @param {jQuery} op - configurator option
 */
function enableNextSibling(op) {
    if (op.next() && op.next().hasClass('disabled')) {
        // if there is a sibling preceeding the current option
        // enable the previous sibling
        // rerun this function on the previous sibling to continue the chain
        enableOption(op.next());
        enableNextSibling(op.next());
    }
}

/**
 * clears the value of the next sibling and conditionally sets disabled
 * @param {jQuery} op - configurator option
 * @param {string} name - name of option
 */
function clearNextSibling(op, name) {
    var mobileSelect = op.children('.mobile-select-container').children('.mobile-select');
    var opHasVal = mobileSelect.val() !== '';
    if (name !== keys.variation && !op.hasClass('disabled')) {
        disableOption(op);
    }
    if (opHasVal) {
        mobileSelect.val('');
        mobileSelect.change();
    }
    if (op.next() && op.next().hasClass('custom-dropdown-container')) {
        clearNextSibling(op.next());
    }
}

/**
 * update Panel view once dropdown is clicked / opened
 * @param {Object} el - dropdown item
 */
var updateDisplay = function (el) {
    let isEnabledAndDifferent = function () {
        let data = el.data('dropdown-data'),
            tileSelectedOption = $cache.tileContainer.attr('data-selected-id'),
            result = false;

        if (el.attr('data-disabled') !== 'true') {
            if (data && (data.id !== tileSelectedOption || data.isVariationAttribute)) {
                result = true;
            }
        }

        return result;
    };

    // check if clicked option is not the current option and not disabled
    if (isEnabledAndDifferent()) {
        // clear the display
        clearDisplay();
        // build the display
        buildDisplay(el.data('dropdown-data'));
    }
};

/**
 * disable saveAndReview button if invalid/disabled selects exist
 */
function manageSaveButton() {
    var disabledSelects = $('.custom-dropdown-container.disabled:not(".d-none")').length,
        invalidSelectes = $cache.configuratorForm.find('.invalid-dropdown').length,
        emptySelects = $('.custom-dropdown-container:not(".d-none")').find('select option:selected[value=""]').length;

    if (invalidSelectes || disabledSelects || emptySelects || !global.isUserLoggedIn) {
        $cache.saveConfiguratorBtn.attr('disabled', true);
    } else {
        $cache.saveConfiguratorBtn.attr('disabled', false);
    }
}

/**
 * Open not login popup if user is not logged in
 * @returns {boolean} display not logged in
 */
function openNotLoggedinPopup() { // eslint-disable-line
    if (!global.isUserLoggedIn && param.isSharedLink) {
        $cache.editNotLoggedinPopup.modal('show');
        return false;
    }
}

/**
 * Event that is fired once dropdown is clicked / opened
 * @param {string} currentVal - current price Value
 */
var updateTotalPrice = function (currentVal) {
    var currentPrice = parseInt($cache.priceSummaryFigure.attr('data-price'));
    var totalPrice = currentPrice * parseInt(currentVal);
    $('.configurator-total-summary-figure').text('$' + numberWithCommas(totalPrice) + '.00');

    if ($('.is-shared-page').length) {
        $('.review-product-total-price').text('$' + numberWithCommas(totalPrice) + '.00');
    }
};

/**
 * Event fired on quantity update to update estimated lead time
 * @param {string} currentVal - current price Value
 */
var updateLeadTime = function (currentVal) {
    var leadTimeData = $('.config-estimate-lead-time-range').data('prod-default') || null;
    if (leadTimeData) {
        if (currentVal <= leadTimeData.longLeadQty) {
            $('.config-estimate-lead-time-range').text(leadTimeData.displayValue);
        } else {
            $('.config-estimate-lead-time-range').text(window.Resources.LEAD_TIME_SPECIALIST_MSG);
        }
    }
};

/**
 * updates displays based on exclusion rules
 * @param {Object} optionVal - currently selected configurator option value
 * @param {Object} optionData - currently selected configurator option data
 */
function updateOptions(optionVal, optionData) {
    var hideOption = function (option) {
        if (!option.hasClass('d-none')) {
            option.addClass('d-none');

            option.find('option').removeAttr('selected');
            option.find('.custom-dropdown-toggle').text('-');
        }
    };
    var showOption = function (option) {
        if (option.hasClass('d-none')) {
            option.removeClass('d-none');
        }
    };
    var matchExclusionRules = function (exclusions, type) {
        exclusions.forEach(function (x) {
            $('.custom-dropdown-container').each(function () {
                var data = $(this).data('dropdown-data');
                if (data && data.id && data.id === x) {
                    if (type === 'exclude') {
                        hideOption($(this));
                    } else if (type === 'include') {
                        showOption($(this));
                    }
                }
            });
        });
    };
    var handleOptionRules = function (optionRules) {
        if (optionRules.Exclude) {
            matchExclusionRules(optionRules.Exclude, 'exclude');
        }
        if (optionRules.Include) {
            matchExclusionRules(optionRules.Include, 'include');
        }
    };

    var handleOptionValueRules = function (optionValueRules) {
        Object.keys(optionValueRules).forEach(function (key) {
            if (optionValueRules[key].Exclude) {
                var exclusions = optionValueRules[key].Exclude;
                $('.custom-dropdown-container').each(function () {
                    var data = $(this).data('dropdown-data');
                    var container = $(this);
                    if (data && data.id && data.id === key) {
                        var dropdownmenu = container.find('.dropdown-menu');
                        var dropdownchildren = dropdownmenu.children();
                        if (dropdownchildren) {
                            dropdownchildren.each(function () {
                                if (exclusions.indexOf($(this).attr('id')) > -1) {
                                    // if the option exists in the exclusion rules
                                    // hide the option value in the dropdown
                                    $(this).addClass('d-none');

                                    // check if the currently selected option is in the exclusions rules
                                    // if so, clear the mobile select and trigger a change
                                    var selectedId = container.find('option:selected').attr('id');
                                    if (exclusions.indexOf(selectedId) > -1) {
                                        var mobileselect = container.find('.mobile-select');
                                        mobileselect.val('');
                                        mobileselect.trigger('change');
                                    }
                                }
                            });
                        }
                    }
                });
            }
        });
    };

    var checkForMatchingVal = function (val, values) {
        values.forEach(function (v) {
            if (v.id === val) {
                if (v.optionRules) {
                    handleOptionRules(v.optionRules);
                }
                if (v.optionValueRules) {
                    handleOptionValueRules(v.optionValueRules);
                }
            }
        });
    };
    if (optionData.values) {
        checkForMatchingVal(optionVal, optionData.values);
    }
}

/**
 * Update dropdown attributes due to selected option
 * @param {Object} activeDropdownEl - active dropdown element
 * @param {string} val - select value
 * @param {boolean} triggerChange - trigger change for active select
 * @param {string} opId - option Id
 */
function updateActiveOptionAttr(activeDropdownEl, val, triggerChange, opId) {
    if (triggerChange && opId) {
        activeDropdownEl.find(`.dropdown-menu .dropdown-item[data-option-val="${opId}"]`).trigger('click');
    }
}

/**
 * Event that is fired once dropdown is clicked / opened
 * @param {Object} mutations - mutations
 */
var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
        var $customToggle = $(mutation.target).find('.custom-dropdown-toggle');

        // disable save button if errors appear
        manageSaveButton();
        // Open popup if user is not logged in
        openNotLoggedinPopup();

        if (($(mutation.target).hasClass('show') || $(mutation.target).hasClass('displayModal')) && !$(mutation.target).hasClass('quantity-desktop')) {
            // Deselect existing value so the image configurator doesn't filter unselected options out
            if ($(mutation.target).parent().data('dropdown-type') === 'variation') {
                var selectedOption = $(mutation.target).siblings('.mobile-select-container').find('option:selected');
                var selectedVal = selectedOption.val();

                selectedOption.attr('selected', false).prop('selected', false);
                $(mutation.target).find('.custom-dropdown-toggle').text(selectedVal);
            }
            $customToggle.append('<span>' + $customToggle.data('activetext-select') + '</span>');
            updateDisplay($(mutation.target).parent('.custom-dropdown-container'));
        } else {
            $(mutation.target).find('.custom-dropdown-toggle span').remove();
            // Add back existing selection, no change was made
            if ($(mutation.target).parent().data('dropdown-type') === 'variation') {
                var prevSelectedVal = $(mutation.target).find('.custom-dropdown-toggle').text();
                if (!prevSelectedVal) {
                    prevSelectedVal = $(mutation.target).find('.custom-dropdown-toggle').val();
                }
                if ($(mutation.target).siblings('.mobile-select-container').find('option:selected').val() !== prevSelectedVal) {
                    updateActiveOptionAttr($(mutation.target).parent(), prevSelectedVal, true, prevSelectedVal);
                }
            }
        }
    });
});

/**
 * scroll to the dropdown
 * @param {Object} dropdown - current size select
 */
function scrollToDropdown(dropdown) {
    var headerHeight = $('header').length ? $('header').height() : 0;

    $('html, body').animate({
        scrollTop: dropdown.offset().top - headerHeight
    }, 300);
}

/**
 * Swatch popup click event for desktop
 */
function selectPopupSwatch() {
    var activeID = $cache.tileContainer.attr('data-selected-id'),
        $activeDropdown = getActiveDropdownById(activeID),
        activeSwatch = $cache.swatchSlider.find('.slick-active').attr('popup-slide-id'),
        selectedVal = $activeDropdown.find('select .dropdown-item[id="' + activeSwatch + '"]').val(),
        findNextShownSiblingEl = function (element) {
            if (element.hasClass('d-none')) {
                if (element.next().length) {
                    return findNextShownSiblingEl(element.next());
                }
            } return element;
        },
        isShownDropdown = findNextShownSiblingEl($activeDropdown.next());

    global.isSwatchSelected = true;

    $cache.swatchSlider.removeClass('slider-fade');
    $cache.swatchModal.modal('hide');
    $cache.panelModal.modal('hide');
    scrollToDropdown(isShownDropdown);

    // add function at the end of queue so the next dropdown is triggered after modals are completely closed
    setTimeout(function () {
        updateActiveOptionAttr($activeDropdown, selectedVal, true, activeSwatch);
        manageSaveButton();
    }, 0);
}

/**
 * updates popup display for PaintSwatch and fill it with data
 * @param {Object} id - displayed swatch id in popup
 * @param {Object} form - displayed form in popup
 */
function updateCustomPaintSwatch(id, form) {
    var activeID = $cache.tileContainer.attr('data-selected-id'),
        containsAnyId = global.shareCustomForms.hasOwnProperty(activeID),
        haveSameId = containsAnyId ? (global.shareCustomForms[activeID].selectedId === id && global.shareCustomForms[activeID].selectedCustomVal) : '';

    if (haveSameId) {
        global.shareCustomForms[activeID].selectedCustomVal.forEach(function (element) {
            var $select = form.find('select[name=' + element.name + ']');
            if ($select.length) {
                $select.val(element.value);
                $select.trigger('change');
            } else {
                form.find('input[name=' + element.name + ']').val(element.value);
            }
        });
    } else {
        form.find('input').val('');
        form.find('select').val('');
        form.find('select').trigger('change');
    }

    // Add char-counters
    $(document).find('.popup-swatch-customPaint').find('input[maxlength]').each(function () {
        updateCharCounter($(this));
    });

    $(document).find('.popup-swatch-customPaint').removeClass('d-none');
}

/**
 * updates popup display for RalColor and fill it with data
 * @param {Object} id - displayed swatch id in popup
 * @param {Object} form - displayed form in popup
 */
function updateCustomRalColor(id, form) {
    var activeID = $cache.tileContainer.attr('data-selected-id'),
        containsAnyId = global.shareCustomForms.hasOwnProperty(activeID);

    if (containsAnyId && (global.shareCustomForms[activeID].selectedId === id) && global.shareCustomForms[activeID].hasOwnProperty('selectedCustomVal')) {
        global.shareCustomForms[activeID].selectedCustomVal.forEach(function (element) {
            form.find('select[name=' + element.name + ']').val(element.value);
            form.find('select[name=' + element.name + ']').trigger('change');
        });
    } else {
        var optionToSelect = form.find('select option:first').val();
        var selectElement = $('.select-RALColor');
        if (selectElement.length > 0 && selectElement.data('selected-option') && $(".select-RALColor option[id='" + selectElement.data('selected-option') + "']").length > 0) {
            optionToSelect = selectElement.data('selected-option');
        }
        form.find('select').val(optionToSelect);
        form.find('select').trigger('change');
    }

    // Add char-counters
    $(document).find('.popup-swatch-RALColor').find('input[maxlength]').each(function () {
        updateCharCounter($(this));
    });

    $(document).find('.popup-swatch-RALColor').removeClass('d-none');
}

/**
 * Com Shade Inputs on Modal Show
 */
function comShadeLoadInputs() {
    comFormGroup.eq(-1).addClass('d-show-mobile');
    comFormGroup.eq(-2).addClass('d-show-mobile');
    comFormGroup.eq(-3).addClass('d-show-mobile');
    comFormGroup.eq(0).removeClass('d-show-mobile');
    comFormGroup.eq(1).removeClass('d-show-mobile');
    comFormGroup.eq(2).removeClass('d-show-mobile');
    comBackButton.addClass('d-none');
    comNextButton.removeClass('d-none');
    comSubmit.addClass('d-show-mobile');
    $('.modal-action-number').html('<p>1 of 2</p>');
}

/**
 * updates popup display for COMShadeSwatch and fill it with data
 * @param {Object} id - displayed swatch id in popup
 * @param {Object} form - displayed form in popup
 */
function updateCOMShadeSwatch(id, form) {
    var activeID = $cache.tileContainer.attr('data-selected-id'),
        containsAnyId = global.shareCustomForms.hasOwnProperty(activeID),
        haveSameId = containsAnyId ? (global.shareCustomForms[activeID].selectedId === id && global.shareCustomForms[activeID].selectedCustomVal) : '';

    if (haveSameId) {
        global.shareCustomForms[activeID].selectedCustomVal.forEach(function (element) {
            var $select = form.find('select[name=' + element.name + ']');
            if ($select.length) {
                $select.val(element.value);
                $select.trigger('change');
            } else {
                form.find('input[name=' + element.name + ']').val(element.value);
            }
        });
    } else {
        form.find('input').val('');
        form.find('select').val('');
        form.find('select').trigger('change');
    }

    // Build COM-only Modal Title
    var comModal = $(document).find('.popup-swatch-comShade');
    var swatchInfo = global.popupSwatchImages.find(function (el) {
        return el.swatchObj.id === id;
    });
    comModal.find('.popup-swatch-title').html(swatchInfo.swatchObj.displayValue + ' Shade');

    // Add char-counters
    comModal.find('input[maxlength]').each(function () {
        updateCharCounter($(this));
    });

    comShadeLoadInputs();
    comModal.removeClass('d-none');
}

/**
 * updates swatch popup display according to its type
 * @param {Object} type - currently swatch type
 * @param {Object} id - currently swatch id
 */
function updateSwatchPopupDisplay(type, id) {
    var $swatchName = $('.popup-swatch-name'),
        activeSwatchName = $('.swatch-modal img[swatch-id="' + id + '"]').next().text(),
        $openedForm = $('.custom-swatch-form');

    $swatchName.html(activeSwatchName);
    $(document).find('.popup-swatch-data').addClass('d-none');

    // find type based on ModalId in productOptionsJSON. Fall back to passed in type (needed for paint or RAL until ModalId is added)
    var swatchDetail = global.popupSwatchImages.find(function (detail) {
        return detail.swatchObj.id === id;
    });
    var type = swatchDetail && swatchDetail.swatchObj.modalId ? swatchDetail.swatchObj.modalId : type; // eslint-disable-line

    switch (type) {
        case 'customPaint':
        case '1':
            updateCustomPaintSwatch(id, $openedForm);
            break;
        case 'RALColor':
        case '2':
            updateCustomRalColor(id, $openedForm);
            break;
        case '3':
            updateCOMShadeSwatch(id, $openedForm);
            break;
        default:
            $(document).find('.popup-swatch-default').removeClass('d-none');
            break;
    }
}

/**
 * Enable next dropdown
 * @param {Object} id - active ID
 * @param {Object} dropdown - active dropdown
 */
function enableNextDropdown(id, dropdown) {
    if (id) {
        updateOptions(id, dropdown.data('dropdown-data'));
    }

    var findNextShownSibling = function (element) {
        if (element.length && element.hasClass('d-none')) {
            if (element.next().length) {
                return findNextShownSibling(element.next());
            }
        }

        return element;
    };
    var nextDD = findNextShownSibling(dropdown.nextAll('.custom-dropdown-container:first'));

    if (nextDD.length) {
        var isDisabled = nextDD.hasClass('disabled');

        if (isDisabled) {
            // remove the disabled class from the next dropdown
            enableOption(nextDD);
        }

        // clear the display
        clearDisplay();
        // set the next option dropdown as selected
        // build the display based on the next option dropdown
        buildDisplay(nextDD.data('dropdown-data'));
        // required for mobile experience
        manageSaveButton();
    }
}

/**
 * Update price item on change event
 * @param {Object} select - current select
 */
function updatePriceOnChangeSelect(select) {
    var ddData = select ? select.data('dropdown-data') : null,
        thisValues = null,
        selectedVal = null,
        additionalPrice = 0,
        itemPriceWithSwatches = 0;

    // account for oah dropdown being duplicated underneath .display-tile-container when active
    if (!ddData || select.parent().hasClass('overall-height-select')) { return; }

    selectedVal = select.find('option[selected=selected], option[selected=true]').attr('id');
    thisValues = select.data('dropdown-data').values;

    thisValues.forEach(function (element) {
        if ((element.id === selectedVal) && element.priceValue) {
            additionalPrice += Math.abs(element.priceValue);
        }
    });

    global.swatchPrice += additionalPrice;

    itemPriceWithSwatches = parseInt(window.itemPriceVal) + global.swatchPrice;

    $cache.priceSummaryFigure.attr('data-price', itemPriceWithSwatches);
    $cache.priceSummaryFigure.text('$' + numberWithCommas(itemPriceWithSwatches) + '.00');
}

/**
 * hide all included / excluded dropdowns (preparation for updateOptions() to add back included dropdowns)
 */
function hideIncludedExcludedDropdowns() {
    var included;
    var excluded;

    $('.custom-dropdown-container').each(function (index, element) {
        var data = $(element).data('dropdown-data');

        if (data) {
            $.each(data.values, function (idx, el) {
                if (el.hasOwnProperty('optionRules')) {
                    included = el.optionRules.hasOwnProperty('Include') ? el.optionRules.Include : '';
                    excluded = el.optionRules.hasOwnProperty('Exclude') ? el.optionRules.Exclude : '';
                }
            });
        }

        $.each(included, function (idx, el) {
            var $includedDropdown = getActiveDropdownById(el);
            $includedDropdown.addClass('d-none');
        });

        $.each(excluded, function (idx, el) {
            var $excludedDropdown = getActiveDropdownById(el);
            $excludedDropdown.addClass('d-none');
        });
    });
}

/**
 * manage included / excluded dropdowns for handleVariationDropdown
 */
function hideExcludedDropdowns() {
    var included;
    var excluded;

    $('.custom-dropdown-container').each(function (index, element) {
        var data = $(element).data('dropdown-data');

        if (data && data.values) {
            var selectedValue = data.values.find(function (val) { return val.selected; }) || null;
            if (selectedValue && selectedValue.hasOwnProperty('optionRules')) {
                included = selectedValue.optionRules.hasOwnProperty('Include') ? selectedValue.optionRules.Include : '';
                excluded = selectedValue.optionRules.hasOwnProperty('Exclude') ? selectedValue.optionRules.Exclude : '';
            }
        }

        if (included && included.length > 0) {
            $.each(included, function (idx, el) {
                var $includedDropdown = getActiveDropdownById(el);
                $includedDropdown.removeClass('d-none');
            });
        }

        if (excluded && excluded.length > 0) {
            $.each(excluded, function (idx, el) {
                var $excludedDropdown = getActiveDropdownById(el);
                $excludedDropdown.addClass('d-none');
            });
        }
    });
}

/**
 * Get selected options
 * @returns {string} - Options string
 */
function getOptions() {
    var options = $('.custom-dropdown-container.option:not(.disabled):not(.d-none)').map(function () {
        var optionId = $(this).data('dropdown-id');
        var selectedValueId = $(this).find('.mobile-select option[selected=selected]').attr('id') || $(this).find('.mobile-select option:selected').attr('id');
        var selectedCustomVal = global.shareCustomForms[optionId];
        return {
            optionId: String(optionId),
            selectedValueId: String(selectedValueId),
            custom: selectedCustomVal
        };
    }).toArray();
    return JSON.stringify(options);
}

/**
 * Get selected custom form values
 * @returns {string} - Options string
 */
function getCustomFormOptions() {
    var customFormOptionsArr = [];
    for (var property in global.shareCustomForms) { // eslint-disable-line
        customFormOptionsArr.push({
            optionId: String(property),
            selectedValueId: String(global.shareCustomForms[property].selectedId),
            selectedCustomVal: global.shareCustomForms[property].selectedCustomVal
        });
    }

    return JSON.stringify(customFormOptionsArr);
}

/**
 * Get selected options
 * @returns {string} - Options string
 */
function getSharedOptions() {
    var options = $('.sharedOption').map(function () {
        var optionId = $(this).data('dropdown-id');
        var selectedValueId = $(this).data('value');
        return {
            optionId: String(optionId),
            selectedValueId: String(selectedValueId)
        };
    }).toArray();
    return JSON.stringify(options);
}


/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    if (window.SitePreferences.ADD_TO_CART_ACTION === 'showModal' && response.renderedTemplate && $(window).outerWidth() > globalConfig.bp.medium) {
        // show added to cart modal
        $('#addToCartModal').remove();
        $('body').append(response.renderedTemplate).addClass('static');
        $('#addToCartModal').modal('show');

        $('#addToCartModal .bonus-product-button').on('click', function () {
            $('#addToCartModal').modal('hide');
        });
    } else if (window.SitePreferences.ADD_TO_CART_ACTION === 'openMiniCart') {
        $('.minicart').trigger('cart:update', response);
    } else {
        // show add to cart toast
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').first().fadeOut(function () {
                this.remove();
            });
        }, globalConfig.toastTimeout);
    }
}

/**
 * Inits the captcha if it was not initiated, if it was resets it so i t can be used again
 * @param {function} callback - callback function to be called on captcha success
 */
function initRecaptcha(callback) {
    var formContainer = $('.share-form');
    var recaptchaContainer = formContainer.find('.js-recaptcha-input');
    var recaptchaResponseEl = recaptchaContainer.find('iframe');

    if (SitePreferences.SHARE_MODAL_RECAPTCHA_ENABLED && formContainer.length > 0) {
        if (recaptchaResponseEl.length === 0) {
            recaptcha.attach(formContainer, callback, true);
        } else {
            recaptcha.reset(formContainer);
        }
    }

    recaptcha.init();
}

/**
 * Share configurator submit function to be called if captcha is disabled, and
 * to be passed as callback function to captcha if it is enabled
 * @returns {boolean} always returns false
 */
function shareFormSubmit() {
    var $invelidMessage = $('.invalid-share-error');
    var form = $('.share-form');
    var url = form.attr('action');
    form.spinner().start();

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                $invelidMessage.addClass('d-block');
            } else {
                $('.js-share-send-to').val('');
                $('.js-share-send-cc').val('');
                $('.js-share-send-message').val('');
                $('#shareModal').modal('hide');
                $('#confirmationShareModal').modal('show');
                setTimeout(function () {
                    $('#confirmationShareModal').modal('hide');
                }, 5000);
                $invelidMessage.removeClass('d-block');
            }
            initRecaptcha(shareFormSubmit);
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            }
            form.spinner().stop();
            initRecaptcha(shareFormSubmit);
        }
    });
    return false;
}

/**
 * Function to apply custom paint & powder defaults
 * @param {Object} dropdownData - json string from html data element
 * @returns {Object} - dropdownData with some additions
 */
function getCustomsFromDropdownData(dropdownData) {
    var objectKeyNames = { ralColorDefault: 'ralColor', colorName: 'colorName', colorBrand: 'colorBrand', colorCode: 'colorCode', comManufacturer: 'comManufacturer', comName: 'comName', comColorway: 'comColorway', comMaterial: 'comMaterial', comOrientation: 'comOrientation', comSpecialNotes: 'comSpecialNotes' };
    var selectedValueId = dropdownData ? dropdownData.selectedValueId : null;
    var optionValues = dropdownData && dropdownData.hasOwnProperty('values') ? dropdownData.values : null;
    if (selectedValueId != null && optionValues != null) {
        for (var i = 0; i < optionValues.length; i++) {
            var currentValue = optionValues[i];
            if (currentValue.id.toString() === selectedValueId.toString()) {
                var objectKeys = Object.keys(objectKeyNames);
                for (var j = 0; j < objectKeys.length; j++) {
                    var valueId = objectKeys[j];
                    var dropdownId = objectKeyNames[valueId];
                    var customValue = currentValue[valueId];
                    if (customValue !== '' && customValue !== null) {
                        if (!dropdownData.hasOwnProperty('selectedCustomValues')) dropdownData.selectedCustomValues = {};
                        if (dropdownId === 'ralColor') {
                            customValue = customValue.replace('#', '');
                            // eslint-disable-next-line no-loop-func
                            var dropdownItem = $('.popup-swatch-RALColor').find('.dropdown-item').filter(function () {
                                return $(this).attr('data-option-val').toLowerCase() === customValue.toLowerCase();
                            });
                            customValue = dropdownItem.attr('data-option-val');
                        }
                        dropdownData.selectedCustomValues[dropdownId] = customValue;
                    }
                }
            }
        }
    }
    return dropdownData;
}
/**
 * Function to update configurator-results section with options from current variationProduct, instead of defaultVariation
 */
function refreshConfigResults() {
    $('.result-value').toArray().forEach(function (result) {
        if (!result.classList.contains('variant') && !result.classList.contains('variation-key-value') && !result.classList.contains('option-sidemark')) {
            $(result).remove();
        }
    });

    $('.result-value.variant').textContent = window.configurator.selectedVariant.id;

    $('.custom-dropdown-container.option').toArray().forEach(function (dd) {
        var newEl = document.createElement('p');

        newEl.classList.add('result-value', 'option-value');
        newEl.setAttribute('data-result-id', dd.dataset.dropdownId);
        newEl.textContent = ' ' + dd.dataset.dropdownName + ': ';

        $('.result-value.option-sidemark').before(newEl);
    });
}

module.exports = {
    initConfigurator: function () {
        var configData = $cache.tileContainer.data('config-data'),
            initialOp = $cache.tileContainer.data('selected-option'),
            selectedOp;

        if (initialOp) {
            if (initialOp === keys.size) {
                // if set to initial, select the first option which should be size
                selectedOp = configData[0];
            } else {
                configData.forEach(function (op) {
                    if (op.name === initialOp) {
                        selectedOp = op;
                    }
                    return;
                });
            }
        } else {
            initialOp = configData[0];
            var currentVariation = Object.keys(initialOp)[0];
            selectedOp = initialOp[currentVariation];

            // Disable all variation keys except the first
            if (Object.keys(initialOp).length > 1) {
                disableVariationOptions();
                $cache.variationDropdownCtnr.each(function () {
                    if ($(this).data('dropdown-id') === 'variation-' + currentVariation) {
                        enableOption($(this));
                    }
                });
            }
        }

        // auto-select variations dropdowns if only one value exists
        setTimeout(function () {
            const excludedURLs = ['configurator-edit', 'configurator-variation', 'configurator-shared'];

            let $ddMenus = $('.dropdown-menu.variation'),
                regex = new RegExp(excludedURLs.join('|'), 'gi');

            if (!regex.test(window.location.pathname)) {
                $ddMenus.each((idx, menu) => {
                    let items = $(menu).children('.dropdown-item');

                    if (items.length === 1) {
                        $(items).trigger('click');
                    }
                });
            }
        }, 0);

        buildDisplay(selectedOp);
        hideIncludedExcludedDropdowns();
        if ($('.is-shared-page').length) {
            buildDesignMatrix();
        }
    },

    applyCustomsToGlobal: function () {
        $('div[data-dropdown-type="configurator"][data-dropdown-id]').each(function (index, dropdown) {
            var dropdownData = $(dropdown).data('dropdown-data'),
                optionId = $(dropdown).data('dropdown-id');

            dropdownData = getCustomsFromDropdownData(dropdownData);

            if (dropdownData && dropdownData.selectedCustomValues) {
                if (dropdownData.selectedValueId !== '' && dropdownData.selectedValueId !== null) {
                    var selectedCustomVals = [];
                    var customKeys = Object.keys(dropdownData.selectedCustomValues);
                    for (var j = 0; j < customKeys.length; j++) {
                        selectedCustomVals.push({
                            name: customKeys[j],
                            value: dropdownData.selectedCustomValues[customKeys[j]]
                        });
                    }

                    if (selectedCustomVals.length > 0) {
                        global.shareCustomForms[optionId] = {
                            selectedId: dropdownData.selectedValueId,
                            selectedVal: dropdownData.selectedValueDisplayValue,
                            selectedCustomVal: selectedCustomVals
                        };
                    }
                }
            }
        });
    },

    enableOptions: function () {
        $cache.dropdownContainer.each(function () {
            if ($(this).data('selected')) {
                enableOption($(this));
                enablePrevSibling($(this));
            }
        });
    },

    enableNext: function () {
        $(document).on('change', '.mobile-select:not(.quantity)', function () { // eslint-disable-line
            var $this = $(this),
                dropdownmenu = $this.closest('.custom-dropdown-container'),
                selectedId,
                currentOptionID = $this.closest('.custom-dropdown-container').data('dropdown-id');

            global.swatchPrice = 0;

            if (dropdownmenu.hasClass('variation')) {
                selectedId = $this.find('option[selected=selected]').data('attrid') || $this.find('option:selected').data('attrid');
            } else {
                selectedId = $this.find('option[selected=selected]').attr('id') || $this.find('option:selected').attr('id');
            }

            $('.custom-dropdown-container.option:not(.disabled)').each(function () {
                updatePriceOnChangeSelect($(this));
            });

            // Trigger quantity to update total price
            $('.quantity.mobile-select').trigger('change');

            // Show popup with custom swatches to fill data
            if (!global.isSubmitPressed && global.openCustomSwatchPopup) {
                for (var index = 0; index < global.popupSwatchImages.length; index++) {
                    var el = global.popupSwatchImages[index],
                        isCusstomSwatch = (el.currentSwatch === 'customPaint' || el.currentSwatch === 'RALColor' || el.swatchObj.modalId) && el.swatchObj.id !== 'tbd-ralcolor' && el.swatchObj.id !== 'tbd-custom-paint';
                    if (el.swatchObj.id === selectedId && currentOptionID === Number(el.swatchObj.optionid) && isCusstomSwatch) {
                        global.activateSliderId = el.swatchObj.id;
                        // Don't show modal if the selected value has been cleared due to previous option change
                        if (!(global.shareConfObj.hasOwnProperty(currentOptionID) && !$(this).val())) {
                            $cache.swatchModal.modal('show');
                        }
                        if ($(this).val()) {
                            updateOptions(selectedId, dropdownmenu);
                            manageSaveButton();
                        }
                        return false;
                    } else if (global.shareConfObj.hasOwnProperty(currentOptionID) && el.swatchObj.id === selectedId && (el.currentSwatch !== 'customPaint' || el.currentSwatch !== 'RALColor' || !el.swatchObj.modalId)) {
                        deleteIdFromShareConfObj(currentOptionID);
                    }
                }
            }

            global.shareConfObj[currentOptionID] = {
                selectedVal: $this.val(),
                selectedId: selectedId
            };

            // When select is changed remove not custom item from global.shareCustomForms object
            if (global.shareCustomForms[currentOptionID] && global.shareCustomForms[currentOptionID].selectedId !== global.shareConfObj[currentOptionID].selectedId) {
                delete global.shareCustomForms[currentOptionID];
            }

            if ($(this).val()) {
                enableNextDropdown(selectedId, dropdownmenu);
            }
        });
    },

    updateQuantityPrice: function () {
        $(document).on('change', '.mobile-select.configurator-quantity', function () {
            updateTotalPrice($(this).val());
            updateLeadTime($(this).val());
        });
    },

    updateVariationPrice: function () {
        if (window.hasOwnProperty('configurator') && window.configurator.selectedVariant && window.configurator.selectedVariant.price) {
            var totalPrice = parseInt($cache.configuratorQuantity.val()) * parseInt(window.configurator.selectedVariant.price.value);

            window.itemPriceVal = window.configurator.selectedVariant.price.value;

            $cache.priceSummaryFigure.attr('data-price', window.configurator.selectedVariant.price.value);
            $cache.priceSummaryFigure.text('$' + numberWithCommas(window.configurator.selectedVariant.price.value) + '.00');

            $('.configurator-total-summary-figure').text('$' + numberWithCommas(totalPrice) + '.00');
        }
    },

    updateCharCounters: function () {
        $(document).on('keyup', 'input[maxlength]', function () {
            updateCharCounter($(this));
        });
    },

    swatchModalOpen: function () {
        $(document).on('click', '.swatch-modal img', function () {
            var isPartOfDesignMatrix = $(this).closest('.design-matrix').length > 0;
            if (!isPartOfDesignMatrix) {
                global.activateSliderId = $(this).attr('swatch-id');
                $cache.swatchModal.modal('show');
                global.isSwatchSelected = false;

                setTimeout(function () {
                    $cache.panelModal.modal('hide');
                }, keys.animationTimeout);
            }
        });
    },

    swatchModalShown: function () {
        $cache.swatchModal.on('shown.bs.modal', function () {
            if (!$cache.swatchSlider.hasClass('slick-initialized')) {
                var $productConfiguratorSwatches = new Carousel('productConfiguratorSwatches');

                $productConfiguratorSwatches.activate();
            }

            var activeImageType = $cache.swatchSlider.find('.slick-active').attr('current-group-swatch'),
                $activeImgId = $cache.swatchSlider.find('img[popup-slide-id="' + global.activateSliderId + '"]'),
                activeIndex = $activeImgId.data('slick-index');

            $cache.swatchSlider.slick('slickGoTo', activeIndex);
            updateSwatchPopupDisplay(activeImageType, global.activateSliderId);

            // Animation for modals
            setTimeout(function () {
                $cache.swatchSlider.addClass('slider-fade');
            }, keys.animationTimeout);
        });
    },

    swatchModalHide: function () {
        // Animation for modals and handle closing without saving
        $cache.swatchModal.on('hide.bs.modal', function () {
            var dropdownmenu = $('.custom-dropdown-container[data-dropdown-name="' + $cache.optionName.text() + '"]'),
                mobileSelectMenu = dropdownmenu.find('select'),
                curDropdownID = $cache.tileContainer.attr('data-selected-id');

            $cache.swatchSlider.removeClass('slider-fade');

            if (!global.isSwatchSelected) {
                $cache.panelModal.modal('show');
            }

            // if modal is cancelled, reset selection as if the modal was never opened
            if (!global.shareConfObj[curDropdownID]) {
                mobileSelectMenu.val('');
                if (mobileSelectMenu.find('option:selected').attr('id')) {
                    mobileSelectMenu.find('option:selected').removeAttr('selected');
                }
                if (mobileSelectMenu.find('option[selected=selected]').attr('id')) {
                    mobileSelectMenu.find('option[selected=selected]').removeAttr('selected');
                }
                dropdownmenu.find('.dropdown-toggle').text('-');
                global.isSwatchSelected = false;
            } else {
                updateActiveOptionAttr(dropdownmenu, global.shareConfObj[curDropdownID].selectedVal, false, global.shareConfObj[curDropdownID].selectedId);
                dropdownmenu.find('.dropdown-toggle').text(global.shareConfObj[curDropdownID].selectedVal);
            }
        });
    },

    swatchModalSlider: function () {
        $cache.swatchSlider.on('afterChange', function () {
            var $activeSwatch = $cache.swatchSlider.find('.slick-active'),
                activeSwatch = $activeSwatch.attr('popup-slide-id'),
                activeImageType = $activeSwatch.attr('current-group-swatch');

            updateSwatchPopupDisplay(activeImageType, activeSwatch);
        });
    },

    swatchModalSelectSwatch: function () {
        $(document).find('.select-swatch').on('click', function () {
            selectPopupSwatch();

            var curDropdownID = $cache.tileContainer.attr('data-selected-id');
            if (global.shareConfObj.hasOwnProperty(curDropdownID)) {
                deleteIdFromShareConfObj(curDropdownID);
            }
        });
    },

    swatchCustomModalSubmit: function () {
        $(document).on('submit', '.custom-swatch-form', function (e) {
            e.preventDefault();
            var $form = $(this),
                $selectEls = $form.find('.mobile-select'),
                activeId = $cache.swatchSlider.find('.slick-active').attr('popup-slide-id'),
                activeName = $('.slick-active').attr('popup-swatch-name'),
                dropdownmenu = $('.custom-dropdown-container[data-dropdown-name="' + $cache.optionName.text() + '"]'),
                curDropdownID = $cache.tileContainer.attr('data-selected-id');

            // clear custom-dropdown placeholder 'select' values from form
            $selectEls.each(function () {
                if ($(this).val() && $(this).val().toLowerCase() === 'select') {
                    $(this).val('');
                }
            });
            var formdataArr = $form.serializeArray();

            global.shareConfObj[curDropdownID] = {
                selectedVal: activeName,
                selectedId: activeId
            };

            global.shareCustomForms[curDropdownID] = {
                selectedVal: activeName,
                selectedId: activeId,
                selectedCustomVal: formdataArr
            };

            selectPopupSwatch();

            enableNextDropdown(activeId, dropdownmenu);
        });
    },

    swatchCustomModalDismiss: function () {
        $('.swatch-popup-close-wrapper button').on('click', function () {
            var dropdownmenu = $('.custom-dropdown-container[data-dropdown-name="' + $cache.optionName.text() + '"]'),
                mobileSelectMenu = dropdownmenu.find('select'),
                curDropdownID = $cache.tileContainer.attr('data-selected-id');
            // if modal is cancelled, reset selection as if the modal was never opened
            if (!global.shareConfObj[curDropdownID]) {
                mobileSelectMenu.val('');
                if (mobileSelectMenu.find('option:selected').attr('id')) {
                    mobileSelectMenu.find('option:selected').removeAttr('selected');
                }
                if (mobileSelectMenu.find('option[selected=selected]').attr('id')) {
                    mobileSelectMenu.find('option[selected=selected]').removeAttr('selected');
                }
                dropdownmenu.find('.dropdown-toggle').text('-');
                global.isSwatchSelected = false;
            } else {
                updateActiveOptionAttr(dropdownmenu, global.shareConfObj[curDropdownID].selectedVal, false, global.shareConfObj[curDropdownID].selectedId);
                dropdownmenu.find('.dropdown-toggle').text(global.shareConfObj[curDropdownID].selectedVal);
            }
            $cache.swatchSlider.removeClass('slider-fade');
            $cache.swatchModal.modal('hide');
            $cache.panelModal.modal('hide');
            manageSaveButton();
        });
    },

    mobileSelectClick: function () {
        $(document).on('click', '.mobile-select-container:not(.quantity-wrapper)', function () {
            let select = this.querySelector('select');

            if (select.dataset.attrid && select.dataset.attrid !== $cache.tileContainer.attr('data-selected-option')) {
                clearDisplay();
                buildDisplay($(this).parents('.custom-dropdown-container').data('dropdown-data'));
            }

            // Adding 'show' to trigger mutation so data in popup is updated
            $(this).siblings('.custom-dropdown').addClass('show displayModal');

            if (param.isSharedLink && !global.isUserLoggedIn) {
                return;
            }

            if (!$(this).hasClass('custom-modal-mobile-select')) {
                $cache.panelModal.modal('show');
            }
        });
    },

    oahNoteDisplay: function () {
        $(document).on('change', '.custom-dropdown-container[data-dropdown-id="7"] select', function () {
            // Display OAH only when "CHAIN" or "Angled Chain" is selected at Mounting
            var noteDisplaySelections = ['71', '299'];
            var curOAHSelection = $(this).children(':selected').attr('id');
            if (noteDisplaySelections.indexOf(curOAHSelection) > -1) {
                $('.oah-note').removeClass('d-none');
            } else {
                $('.oah-note').addClass('d-none');
            }
        });
    },

    mobileModalHide: function () {
        $cache.panelModal.on('hide.bs.modal', function () {
            $('.custom-dropdown').removeClass('show displayModal');
            if ($('.modal-backdrop').length) {
                $('.modal-backdrop').remove();
            }
        });
    },

    mobileModalHidden: function () {
        $cache.panelModal.on('hidden.bs.modal', function () {
            $('.custom-dropdown').removeClass('show');
            if ($('.modal-backdrop').length) {
                $('.modal-backdrop').remove();
            }
        });
    },

    mobileModalSelectOption: function () {
        $(document).on('click', '.select-mobile-option', function () {
            let activeID = $cache.tileContainer.attr('data-selected-id'),
                $activeDropdown = getActiveDropdownById(activeID),
                ddItem = $activeDropdown.find(`.dropdown-menu .dropdown-item[data-option-val="${this.value}"]`);

            if (ddItem.length) {
                ddItem.trigger('click');
            }

            $cache.panelModal.modal('hide'); // Trigger close button so mutation is fired
        });
    },

    mobileModalSelectOverallOption: function () {
        $(document).on('click', '.select-overall-option', function () {
            var $thisVal = $('.overall-height-select .mobile-select').val(),
                $thisId = $('.overall-height-select .mobile-select .dropdown-item[value="' + $thisVal + '"]').attr('id'),
                $activeOverallDropdown = $('.custom-dropdown.show').last(),
                ddItem = $activeOverallDropdown.find(`.dropdown-menu .dropdown-item[data-option-val="${$thisId}"]`);

            if (ddItem.length) {
                ddItem.trigger('click');
            }
            $cache.panelModal.modal('hide'); // Trigger close button so mutation is fired
        });
    },

    sidemarkFocusEvents: function () {
        $(document).on('focus', '.sidemark-input', function () {
            $(this).parent().addClass('show');
        }).on('focusout', '.sidemark-input', function () {
            var $this = $(this);

            $this.parent().removeClass('show');

            if ($this.val().length) {
                $cache.sidemarkPlaceholder.addClass('d-none');
            } else {
                $cache.sidemarkPlaceholder.removeClass('d-none');
            }
        });

        if ($cache.sidemarkInput.val() && $cache.sidemarkInput.val().length) {
            $cache.sidemarkPlaceholder.addClass('d-none');
        }
    },

    sidemarkApply: function () {
        $(document).on('click', '.apply-sidemark', function () {
            var sidemarkPopupVal = $('.sidemark-popup-input').val();

            $cache.sidemarkInput.val(sidemarkPopupVal);

            if ($cache.sidemarkInput.val() && $cache.sidemarkInput.val().length) {
                $cache.sidemarkPlaceholder.addClass('d-none');
            } else {
                $cache.sidemarkPlaceholder.removeClass('d-none');
            }

            $cache.panelModal.modal('hide'); // Trigger close button so mutation is fired
            $cache.sidemarkInput.trigger('focusout');
        });
    },

    sidemarkPlaceholderClick: function () {
        $(document).on('click', '.sidemark-placeholder', function () {
            var $this = $(this);

            $this.addClass('d-none');
            $this.next().focus();
        });

        $(document).on('focus', '.sidemark-input', function () {
            $cache.sidemarkPlaceholder.addClass('d-none');
        });
    },

    saveReviewSubmit: function () {
        $cache.configuratorForm.on('submit', function (e) {
            e.preventDefault();
            refreshConfigResults();
            $('html, body').animate({ scrollTop: 0 }, 500);
            buildDisplay($(this).data('dropdown-data'));
        });
    },

    editDropdownsClick: function () {
        $('.edit-configurator').on('click', function () {
            $cache.resultsWrapper.addClass('d-none');
            $cache.dropdownsWrapper.removeClass('d-none');
            $cache.configuratorPageWrapper.removeClass('saveand-review-display');

            global.isSubmitPressed = false;
        });
    },

    printReview: function () {
        $('.order-print-button').on('click', function () {
            window.print();
        });
    },

    shareModalOpen: function () {
        $('.share-configurator').on('click', function () {
            var $subjectInput = $('.share-send-subject');
            $('#shareModal').modal('show');
            var base = $(this).data('url');
            initRecaptcha(shareFormSubmit);
            // Add quantity
            global.shareConfObj.quantity = {
                selectedId: $('.quantity-wrapper .configurator-quantity').val()
            };

            var sizeParam = global.shareConfObj[keys.variant] && global.shareConfObj[keys.variant].selectedId;
            if (!sizeParam) {
                sizeParam = window.configurator.selectedVariant.id;
            }
            base += '?pid=' + sizeParam;
            base += $(this).data('restricted') ? '&s_share=1' : '';

            Object.keys(global.shareConfObj).forEach(function (k) {
                if (k === 'sidemark' || k === 'quantity') {
                    base += '&' + k + '=' + encodeURIComponent(global.shareConfObj[k].selectedId);
                } else if (k !== keys.variant) {
                    base += '&dwopt_' + sizeParam + '_' + k + '=' + global.shareConfObj[k].selectedId;
                }
            });

            if (!isObjEmpty(global.shareCustomForms)) {
                base += '&custom=' + JSON.stringify(global.shareCustomForms);
            }

            var userInfoText = $('.share-send-message').data('user-info');
            $('.share-send-without-price').prop('checked', true);
            var productName = $('.sizedropdown .mobile-select').val() || $('.configurator-results-wrapper').data('product-name') || $('.configurator-results-wrapper').find('.result-value.variation-key-value .result-list-item').text();
            $('.share-send-message').text(userInfoText);
            $('.shareURL').val(base);
            $('.sharePricing').val($('.configurator-total-price').html());

            $subjectInput.val(productName + ' ' + $subjectInput.data('company-name'));
        });
    },

    shareFormSubmit: function () {
        $('.share-form').on('submit', function (e) {
            e.preventDefault();

            if (SitePreferences.SHARE_MODAL_RECAPTCHA_ENABLED) {
                recaptcha.showReCaptcha('share-form');
            } else {
                shareFormSubmit();
            }
        });
    },

    prefillDropdownsWithParams: function () {
        if (param.isSharedLink || $('.is-shared-page').length) {
            if (!global.isUserLoggedIn) {
                $('.configurator-total-wrapper').addClass('d-none');
                $cache.sidemarkWrapper.addClass('d-none');
                $('.swatch-popup').addClass('d-none');

                $cache.saveConfiguratorBtn.attr('disabled', true);
            }

            $(function () {
                $cache.variationDropdownCtnr.each(function () {
                    var option = $(this).find('option:selected');

                    if (option.length) {
                        $(this).find('button').val(option.val()).text(option.text());
                    }
                });

                if ($cache.dropdownContainer.length) {
                    $cache.dropdownContainer.each(function (index, el) {
                        global.openCustomSwatchPopup = false;

                        enableNextSibling($(this));

                        if ($(el).hasClass('option')) {
                            $(el).find('select').trigger('change');
                        }
                    });
                }

                global.openCustomSwatchPopup = true;

                if (param.sharedQty) {
                    $cache.configuratorQuantity.val(param.sharedQty);
                }
                $cache.configuratorQuantity.trigger('change');

                if (param.sharedSidemark && param.sharedSidemark.length) {
                    var sidemarkVal = decodeURIComponent((param.sharedSidemark + '').replace(/\+/g, '%20'));
                    $('.sidemark-input').val(sidemarkVal);
                    $('.sidemark-placeholder').addClass('d-none');
                }

                if (param.customForm) {
                    var customFormObj = decodeURIComponent((param.customForm + '').replace(/\+/g, '%20'));
                    global.shareCustomForms = JSON.parse(customFormObj);
                    // include the custom prefs in the original form so we can render it under selected options
                    var $selectedOptions = $('.configurator-results');

                    Object.keys(global.shareCustomForms).forEach(function (dropdownID) {
                        var $customOption = $selectedOptions.find('.result-list-item[data-result-id=' + dropdownID + ']');
                        $customOption.text($customOption.text() + getCustomColorString(dropdownID));
                    });
                }

                // determine first unselected dropdown
                var firstUnselectedDropdown = $('.custom-dropdown-container.option:not(.disabled):not(.d-none)').filter(function () {
                    return !$(this).find('.mobile-select option[selected=selected]').attr('id') && !$(this).find('.mobile-select option:selected').attr('id');
                })[0];

                // Build the display based on the next option dropdown
                if (firstUnselectedDropdown) {
                    clearDisplay();
                    buildDisplay($(firstUnselectedDropdown).data('dropdown-data'));
                } else if ($('.custom-dropdown-container.dropdown-inited:not(.disabled):not(.d-none)').length) {
                    clearDisplay();
                    buildDisplay($('.custom-dropdown-container.dropdown-inited:not(.disabled):not(.d-none)').last().data('dropdown-data'));
                }
            });
        }
    },

    invalidOptionsEvent: function () {
        $(document).ready(function () {
            var dataValue = $cache.configuratorForm.data('invalid-options');

            if ($cache.configuratorForm && dataValue && dataValue.options.length) {
                dataValue.options.forEach(function (el, index) {
                    var invalidId = dataValue.options[index].split('__')[0],
                        $invalidDropdown = $('.custom-dropdown-container[data-dropdown-id="' + invalidId + '"]');

                    $invalidDropdown.addClass('invalid-dropdown');
                    $invalidDropdown.find('option').removeAttr('selected');
                    $invalidDropdown.find('.custom-dropdown-toggle').text('-');
                });

                $cache.invalidPopup.modal('show');
                $cache.saveConfiguratorBtn.attr('disabled', true);
            } else if ($cache.configuratorForm && dataValue && dataValue.nonInvalidatingOptions.length) {
                dataValue.nonInvalidatingOptions.forEach(function (el, index) {
                    var invalidId = dataValue.nonInvalidatingOptions[index].split('__')[0],
                        $invalidDropdown = $('.custom-dropdown-container[data-dropdown-id="' + invalidId + '"]');

                    $invalidDropdown.find('option').removeAttr('selected');
                    $invalidDropdown.find('.custom-dropdown-toggle').text('-');
                });
            } else if (global.isUserLoggedIn) {
                $cache.saveConfiguratorBtn.attr('disabled', false);
                manageSaveButton();
            }

            if ($cache.configuratorForm && $cache.configuratorForm.data('configurator-error')) {
                $cache.invalidPopup.modal('show');
            }
        });
    },
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl = null,
                pid = null,
                pidsObj = null;

            $('body').trigger('product:beforeAddToCart', this);

            pid = $('.variant .result-list-item').text();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = $('.configurator-form').attr('action');

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                quantity: $cache.configuratorQuantity.val()
            };

            if (!$('.shared-link').length) {
                form.options = getOptions();
            } else {
                form.options = getSharedOptions();
            }

            if (!isObjEmpty(global.shareCustomForms)) {
                form.customOptions = getCustomFormOptions();
            }

            var sideMark = $('[data-result-id="sidemark"] .result-list-item');

            if (sideMark) {
                form.sidemark = sideMark.text();
            }

            // $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);

                        $('body').trigger('product:afterAddToCart', data);

                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    updateCart: function () {
        $('body').on('click', '.update-cart-product', function (e) {
            e.preventDefault();

            var updateProductUrl = $(this).data('update-product-url');
            var selectedQuantity = $cache.configuratorQuantity.val();
            var sideMark = $('[data-result-id="sidemark"] .result-list-item');

            var uuid = $(this).data('uuid');

            var form = {
                uuid: uuid,
                pid: $('.variant .result-list-item').text(),
                quantity: selectedQuantity
            };
            form.options = getOptions();

            if (uuid) {
                form.uuid = uuid;
            }

            if (sideMark) {
                form.sidemark = sideMark.text();
            }

            if (!isObjEmpty(global.shareCustomForms)) {
                form.customOptions = getCustomFormOptions();
            }

            if (updateProductUrl) {
                $.spinner().start();
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('body').trigger('gtm:eec:updateItem', data);
                        if (data.redirectUrl) {
                            window.location.href = data.redirectUrl;
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    handleComShadeInputs: function () {
        comShadeLoadInputs();

        $('.next-comShade, .back-comShade').on('click', function () {
            if (this.value === 'next') {
                comFormGroup.eq(-1).removeClass('d-show-mobile');
                comFormGroup.eq(-2).removeClass('d-show-mobile');
                comFormGroup.eq(-3).removeClass('d-show-mobile');
                comFormGroup.eq(0).addClass('d-show-mobile');
                comFormGroup.eq(1).addClass('d-show-mobile');
                comFormGroup.eq(2).addClass('d-show-mobile');
                comBackButton.removeClass('d-none');
                comNextButton.addClass('d-none');
                comSubmit.removeClass('d-show-mobile');
                $('.modal-action-number').html('<p>2 of 2</p>');
            } else {
                comShadeLoadInputs();
            }
        });
    },

    watchShowClass: function () {
        $cache.dropdownContainer.each(function () {
            observer.observe($(this).children('.custom-dropdown')[0], {
                attributes: true,
                attributeFilter: ['class']
            });
        });
    },

    handleVariationDropdown: function () {
        $(document).on('click', '.dropdown-menu.variation .dropdown-item', function () {
            let $ctnr = $(this).parents('.custom-dropdown-container'),
                $select = $ctnr.find('select'),
                querystring = new URLSearchParams(document.location.search);

            $select.val(this.dataset.optionVal);
            $ctnr.find('button').val($select.val()).text($select.val());

            updateVariationDropdownValues();
            var reqData = null;

            if (isVariationSelected()) {
                reqData = {
                    pid: querystring.get('pid'),
                    variations: $('select.mobile-select.variation option:checked').toArray().map(el => {
                        return { attrID: $(el).data('attrid'), valueID: el.value };
                    })
                };

                $.ajax({
                    url: window.Urls.CONFIGURATOR_GET_VARIANT,
                    headers: { 'Content-Type': 'application/json' },
                    method: 'post',
                    dataType: 'json',
                    data: JSON.stringify(reqData),
                    async: false
                }) /* eslint-disable */
                .done(data => {
                    if (data) {
                        $.ajax({
                            url: window.Urls.CONFIGURATOR_VARIATION_AJAX,
                            data: { pid: data.id },
                            dataType: 'html',
                            async: false
                        })
                            .done(ddHtml => {
                                var optCtrn = null;

                                document.querySelectorAll('.custom-dropdown-container.option').forEach(el => {
                                    el.remove();
                                });

                                $(ddHtml).insertAfter('.custom-dropdown-container.variation:last');

                                optCtrn = /.*-[edit|variation]/i.test(location.pathname) ? $('.custom-dropdown-container.option.disabled') : $('.custom-dropdown-container.option.disabled').eq(0);
                                optCtrn.each((idx, opt) => {
                                    enableOption($(opt));
                                });

                                // Reinit observer
                                document.querySelectorAll('.custom-dropdown-container.option .custom-dropdown').forEach(el => {
                                    observer.observe(el, {
                                        attributes: true,
                                        attributeFilter: ['class']
                                    });
                                });

                                // Reinit dropdowns
                                $(document).trigger('initDropDowns:reinit');
                                hideIncludedExcludedDropdowns();
                                hideExcludedDropdowns();

                                $select.trigger('change');

                                global.isSubmitPressed = false;

                                // Disable save and review cta
                                $cache.saveConfiguratorBtn.attr('disabled', true);

                                // Re-enable save and review cta if all fields are completed
                                manageSaveButton();
                            })
                            .fail(() => {
                                $cache.invalidPopup.modal('show');
                            });
                    }

                    if (data) {
                        $cache.tileContainer.data('config-data', [data.configurator.variants].concat(data.options));
                        window.configurator.selectedVariant = $.extend(window.configurator.selectedVariant, data);
                        require('./configurator').updateVariationPrice();
                    }
                }); /* eslint-enable */
            } else {
                clearNextSibling($ctnr.next(), keys.variation);
                $select.trigger('change');
            }
        });
    },

    updatePriceOnChangeSelect: updatePriceOnChangeSelect
};
