'use strict';
var root = document.documentElement;

// eslint-disable-next-line require-jsdoc
function openVideo(vimeoVideos, videoUrlId) {
    vimeoVideos.forEach(function (videoUrl) {
        if (videoUrl.getAttribute('data-vimeo-url').indexOf('/video/' + videoUrlId) > -1) {
            videoUrl.click();
        }
    });
}

module.exports = {
    initAltHeroSlide: function () {
        $(document).on('click', '.alternate-hero .slide-down', function (e) {
            e.preventDefault();
            var $parent = $(this).closest('.grid-block.alternate-hero');
            var $header = $('header');
            var headerHeight = $header ? $header.height() : 0;
            var target = ($parent.offset().top + $parent.height()) - headerHeight;
            $('html, body').animate({ scrollTop: target }, 1000);
        });
    },
    initVimeo: function () {
        $(document).on('click', '.js-vimeo-modal-trigger', function (e) {
            e.preventDefault();
            if ($('#vimeoModal').length !== 0) {
                $('#vimeoModal').remove();
            }
            var vimeoUrl = $(this).data('vimeoUrl');
            var htmlString = '<!-- Modal -->'
                + '<div class="modal fade" id="vimeoModal" role="dialog">'
                + '<span class="enter-message sr-only" ></span>'
                + '<div class="modal-dialog quick-view-dialog modal-dialog-centered">'
                + '<!-- Modal content-->'
                + '<div class="modal-content">'
                + '<div class="modal-header">'
                + '<button type="button" class="close pull-right icon-close" data-dismiss="modal">'
                + '<span class="sr-only">Close</span>'
                + '</button>'
                + '</div>'
                + '<div class="modal-body">'
                + '<div class="vimeo-video-container">'
                + '<iframe src="' + vimeoUrl + '?color=ffffff&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>'
                + '</div>'
                + '</div>'
                + '</div>'
                + '</div>'
                + '</div>';
            $('body').append(htmlString);
            $('#vimeoModal').modal('show');

            $('#vimeoModal').on('hidden.bs.modal', function () {
                $('#vimeoModal').remove();
            });
        });
    },
    initOpenVimeo: function () {
        if (window.location.href.indexOf('?videoid=') > -1) {
            var videoUrlId = window.location.href.split('?videoid=').pop();
            var vimeoVideos = document.querySelectorAll('.page-designer-reference #video .js-vimeo-modal-trigger');

            openVideo(vimeoVideos, videoUrlId);
        }
    },
    initBlockWithModal: function () {
        $(document).on('click', '.block-with-modal-trigger', function () {
            var id = $(this).data('uuid');
            $('#' + id).modal('show');
        });
    },
    initJumpToAnchor: function () {
        var headerHeight = '-' + ($('header').height() * 2) + 'px';
        root.style.setProperty('--jump-anchor-top', headerHeight);
    },
    theCurrentCarouselMobile: function () {
        var $carousel = $('.the-current-slick'),
            toggleSlick = function () {
                if ($carousel.find('.the-current-slide').length > 2) {
                    if ($(window).width() < 768) {
                        if ($carousel.hasClass('slick-initialized')) {
                            $carousel.slick('refresh');
                        } else {
                            $carousel.slick({
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                arrows: true,
                                infinite: true
                            });
                        }
                    } else {
                        $carousel.slick('unslick');
                    }
                }
            };
        $(window).on('resize', function () {
            toggleSlick();
        });
        toggleSlick();
    }
};
