'use strict';

var Carousel = require('../components/carousel');
var focusHelper = require('base/components/focus');
var formValidation = require('base/components/formValidation');

/**
 * Inits the carousel for product sets
 * @param {string} selector - selector class used for carousel init
 */
function initCarouselProductSet(selector) {
    var $imageCarousel = new Carousel('pdpProductSetImageCarousel', (selector || null));

    // activate image Carousel
    $imageCarousel.activate();
}

/**
 * Inits the edit product in cart modal carousel
 */
function initEditModalCarousel() { // eslint-disable-line no-unused-vars
    var $imageCarousel = new Carousel('editModalImageCarousel');

    // activate image Carousel
    $imageCarousel.activate();
}

/**
 * Inits the quickview carousel
 */
function initQuickViewCarousel() { // eslint-disable-line no-unused-vars
    var $imageCarousel = new Carousel('quickViewImageCarousel');

    // activate image Carousel
    $imageCarousel.activate();
}

/**
 * Inits the quickview carousel
 */
function initBonusModalCarousel() { // eslint-disable-line no-unused-vars
    var $imageCarousel = new Carousel('bonusImageCarousel');

    // activate image Carousel
    $imageCarousel.activate();
}

/**
 * Inits the carousel for product sets
 * @param {string} selector - selector class used for carousel init
 * @param {jQuery} $productContainer - jQuery DOM object for product container
 *
 * @return {boolean} - true/false
 */
function initCarousel(selector, $productContainer) {
    if ($('.product-set-detail').length > 0) {
        initCarouselProductSet(selector);
        $.spinner().stop();
        return true;
    }

    if ($productContainer && !!$productContainer.parents('#editProductModal').length) {
        initEditModalCarousel();
        $.spinner().stop();
        return true;
    }

    if ($productContainer && $productContainer.hasClass('product-quickview')) {
        initQuickViewCarousel();
        $.spinner().stop();
        return true;
    }

    var $imageCarousel = new Carousel('pdpImageCarousel');
    var $thumbnailCarousel = new Carousel('pdpImageThumbnailsCarousel');
    var $lifestyleImageCarousel = new Carousel('pdpLifestyleGalleryCarousel');

    // activate image Carousel
    $imageCarousel.activate();

    // activate lifestyle image Carousel
    $lifestyleImageCarousel.activate();

    enquire.register('screen and (min-width: ' + (globalConfig.bp.large + 1) + 'px)', {
        match: function () {
            if (!$thumbnailCarousel.$element.hasClass('slick-initialized')) {
                $thumbnailCarousel.activate();
            }
        }
    }).register('screen and (max-width: ' + globalConfig.bp.large + 'px)', {
        match: function () {
            if ($thumbnailCarousel.$element.hasClass('slick-initialized')) {
                $thumbnailCarousel.$element.slick('unslick');
            }
        }
    });

    $.spinner().stop();
    return true;
}

/**
 * Finds zoom carousel modal images and updates with current variation images
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {string} imagesHtml - image dom from Product-Variant Controller to replace content with
 * @param {Array} images - image Urls for carousel
 */
function updateContainerImages($productContainer, imagesHtml) {
    var $imageWrapper = $productContainer.find('.primary-images');
    var outerHeight = $imageWrapper.outerHeight();

    $imageWrapper.replaceWith($(imagesHtml).css('min-height', outerHeight + 'px'));
    var $newImageWrapper = $productContainer.find('.primary-images');

    $newImageWrapper.waitForImages(function () {
        $newImageWrapper.removeAttr('style');
        initCarousel($newImageWrapper.find('.carousel.main'), $productContainer);
    });
}


/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $swatchButton.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $swatchButton.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');

        // Set non-selectable options to look disabled
        if (!attrValue.selectable) {
            $swatchButton.addClass('unselectable');
        } else {
            $swatchButton.removeClass('unselectable');
        }
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *     process if in view details view, even if no swatch images
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];
    var attrsWithButtons = ['variationKey'];

    attrs.forEach(function (attr) {
        var isVariationAttr = $productContainer.find('[data-attr="' + attr.id + '"]')[0].classList.contains('variation-attribute');

        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else if (attrsWithButtons.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else if (isVariationAttr) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div>';
            availabilityValue += message;
            if (response.product.availability.backInStockNotification) {
                availabilityValue += ' <span><a class="notifyme" href="'
                    + response.product.availability.backInStockUrl
                    + '">'
                    + response.product.availability.backInStockMessage
                    + '</a></span>';
            }
            availabilityValue += '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
function getPromotionsHtml(promotions) {
    if (!promotions) {
        return '';
    }

    var html = '<hr>';

    promotions.forEach(function (promotion) {
        html += '<div class="callout" title="' + promotion.details + '">' + promotion.calloutMsg +
            '</div>';
    });

    return html;
}

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id
            + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {Object} product - product data to pull pricing info from
 * @returns {Object} - object container list and sales price
 */
function getProductPrice(product) {
    var price = 0;

    if (product.price.hasOwnProperty('list') && product.price.list !== null && product.price.list !== 'undefined') {
        price = product.price.list.value;
    }

    if (product.price.hasOwnProperty('sales') && product.price.sales !== 'undefined') {
        price = product.price.sales.value;
    }

    if (product.price.hasOwnProperty('type') && product.price.type === 'tiered') {
        price = product.price.startingFromPrice.sales.value;
    }

    if (product.price.hasOwnProperty('type') && product.price.type === 'range') {
        price = product.price.min.sales.value;
    }

    return price;
}

/**
 * Updates the product data data attribute on the .product-detail.product-wrapper element for GTM
 * @param {Object} response - response from AJAX call to update product info
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateGTMData(response, $productContainer) {
    var oldData = $productContainer.data('productData');

    // combine old and new data
    var newData = $.extend({}, oldData, {
        id: response.product.id,
        name: response.product.productName,
        price: getProductPrice(response.product)
    });

    $productContainer.attr('data-product-data', JSON.stringify(newData));

    $('body').trigger('gtm:attribute:update');
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    updateContainerImages($productContainer, response.product.imagesHtml);

    // Update promotions
    $('.promotions').empty().html(getPromotionsHtml(response.product.promotions));

    updateAvailability(response, $productContainer);
    updateGTMData(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.product-attributes').empty()
        .html(response.product.attributesHtml);

    $productContainer.find('.product-attributes')
        .append(response.product.additionalLinks);
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}
/**
 * Updates the details image
 * @param {obj} images - the image object
 */
function updateImages(images) {
    if (images.details) {
        $('.pdp-details-image-container').empty();
        images.details.forEach(function (img) {
            var newImg = "<img src='" + img.url + "' alt='" + img.alt + "' />";
            $('.pdp-details-image-container').append(newImg);
        });
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.options, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                updateImages(data.product.images);
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog modal-dialog-centered choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <h4>' + data.labels.selectprods + '</h4>'
        + '    <button type="button" class="close pull-right icon-close" data-dismiss="modal">'
        + '        <span class="sr-only">Close</span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $('#chooseBonusProductModal').on('shown.bs.modal', function () {
        $(document).trigger('bonus:modal:shown');

        $('.modal-body').waitForImages(function () {
            initBonusModalCarousel();
        });
    });

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    if (window.SitePreferences.ADD_TO_CART_ACTION === 'showModal' && response.renderedTemplate && $(window).outerWidth() > globalConfig.bp.medium) {
        // show added to cart modal
        $('#addToCartModal').remove();
        $('body').append(response.renderedTemplate).addClass('static');
        $('#addToCartModal').modal('show');

        $('#addToCartModal .bonus-product-button').on('click', function () {
            $('#addToCartModal').modal('hide');
            chooseBonusProducts(response.newBonusDiscountLineItem);
        });
    } else if (window.SitePreferences.ADD_TO_CART_ACTION === 'openMiniCart') {
        $('.minicart').trigger('cart:update', response);
    } else if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        // show bonus product modal
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        // show add to cart toast
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').first().fadeOut(function () {
                this.remove();
            });
        }, globalConfig.toastTimeout);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: String($(this).data('option-id')),
                selectedValueId: String(selectedValueId)
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Render schematic drawings images
 * @param {obj} images - the image object
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateSpecImages(images, $productContainer) {
    if (images.details) {
        images.details.forEach(function (img) {
            var newImg = "<img src='" + img.url + "' alt='" + img.alt + "' />";
            $productContainer.append(newImg);
        });
    }
}

/**
 * Render schematic drawings images
 * @param {obj} response - product information object
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateSpecInfo(response, $productContainer) {
    $productContainer.html(response.product.attributesHtml);
}

/**
 * renders product's Schematic Drawings specs
 * @param {string} variationItemUrl - the Urls for the variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function loadSchematicDrawings(variationItemUrl, $productContainer) {
    if (variationItemUrl) {
        $.ajax({
            url: variationItemUrl,
            method: 'GET',
            success: function (data) {
                updateSpecImages(data.product.images, $productContainer);
            }
        });
    }
}

/**
 * renders product's text specs
 * @param {string} variationItemUrl - the Urls for the variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function loadTextSpecifications(variationItemUrl, $productContainer) {
    if (variationItemUrl) {
        $.ajax({
            url: variationItemUrl,
            method: 'GET',
            success: function (data) {
                updateSpecInfo(data, $productContainer);
            }
        }).done(function () {
            $productContainer.find('.content').addClass('p-0');
            $productContainer.find('.collapsible-xl').addClass('pl-0 mb-5').removeClass('collapsible-xl');
        });
    }
}

/**
 * craete modal element
 */
function getStatusModalHtmlElement() {
    if ($('#cartError').length !== 0) {
        $('#cartError').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="cartError" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog status-dialog modal-dialog-centered">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-body text-center">'
        + '<h2 class="dashboard-heading mb-4"></h2>'
        + '<p class="modal-content-text medium mb-4"></p>'
        + '<i class="icon-logo-uec"></i>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * display status modal
 * @param {Object} msg msg to display
 * @param {boolean} success success modal
 */
function displayStatusModal(msg, success) {
    // Init modal
    getStatusModalHtmlElement();
    $('#cartError .modal-body .modal-content-text').empty();
    $('#cartError .modal-body .dashboard-heading').html(success ? 'Success' : 'Error!');
    $('#cartError .modal-body .modal-content-text').html(msg);
    $('#cartError').modal('show');
}

module.exports = {
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },
    focusChooseBonusProductModal: function () {
        $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'true');
            $('#chooseBonusProductModal .close').focus();
        });
    },
    onClosingChooseBonusProductModal: function () {
        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
            $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
        });
    },
    trapChooseBonusProductModalFocus: function () {
        $('body').on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect($(this).attr('data-url'), $productContainer);
        });
    },
    variantAttribute: function () {
        $(document).on('click', '.variation-attribute button', function (e) {
            e.preventDefault();

            if ($(this).attr('disabled')) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.details-view');
            }
            if (!$(this).hasClass('selected')) {
                attributeSelect($(this).attr('data-url'), $productContainer);
            }
        });
    },
    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },
    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0 && !$(this).hasClass('quantity-selectpdp')) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();
            var $sampleRalColor = $('.sample_ral_color');
            var $sampleColorBrand = $('.sample_brand');
            var $sampleColorName = $('.sample_name');
            var $sampleColorCode = $('.sample_code');

            var $additionalNotes = $('.pliNotes');
            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };
            if ($additionalNotes.length) {
                form.notes = $additionalNotes.val();
            }

            if ($sampleRalColor.length > 0) {
                form[$sampleRalColor.attr('name')] = $sampleRalColor.children('option:selected').val();
            }

            if ($sampleColorBrand.length > 0) {
                form[$sampleColorBrand.attr('name')] = $sampleColorBrand.val();
            }

            if ($sampleColorName.length > 0) {
                form[$sampleColorName.attr('name')] = $sampleColorName.val();
            }

            if ($sampleColorCode.length > 0) {
                form[$sampleColorCode.attr('name')] = $sampleColorCode.val();
            }

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if (!data.success) {
                            // update form errors
                            formValidation($('.sample-form'), data);
                        } else if (data.error || data.errorMessage) {
                            // add an error message pop up
                        } else {
                            handlePostCartAdd(data);
                            $('body').trigger('product:afterAddToCart', data);
                            if ($('#sampleModal').hasClass('show')) {
                                $('#sampleModal').modal('hide');
                            }
                        }
                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.loggedin === false) {
                            $('body').trigger('display:loginModal');
                            $.spinner().stop();
                        } else if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            displayStatusModal(err.responseJSON.errorMessage, false);
                            $.spinner().stop();
                        }
                    }
                });
            }
        });
    },
    initCarousel: initCarousel,
    initQuickViewCarousel: initQuickViewCarousel,
    initEditModalCarousel: initEditModalCarousel,
    initBundleCarousel: function () {
        if ($('.product-detail.bundle').length > 0) {
            var $imageCarousel = new Carousel('bundleImageCarousel');
            var $thumbnailCarousel = new Carousel('bundlepdpImageThumbnailsCarousel');

            // activate image Carousel
            $imageCarousel.activate();

            enquire.register('screen and (min-width: ' + (globalConfig.bp.large + 1) + 'px)', {
                match: function () {
                    if (!$thumbnailCarousel.$element.hasClass('slick-initialized')) {
                        $thumbnailCarousel.activate();
                    }
                }
            }).register('screen and (max-width: ' + globalConfig.bp.large + 'px)', {
                match: function () {
                    if ($thumbnailCarousel.$element.hasClass('slick-initialized')) {
                        $thumbnailCarousel.$element.slick('unslick');
                    }
                }
            });
        }
    },
    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt($(this).parents('.choice-of-bonus-product').find('.bonus-quantity-select').val(), 10);
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $(this).parents('.choice-of-bonus-product').find('.product-option').data('option-id');
            var valueId = $(this).parents('.choice-of-bonus-product').find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml = ''
                    + '<div class="selected-pid row" '
                    + 'data-pid="' + pid + '"'
                    + 'data-qty="' + submittedQty + '"'
                    + 'data-optionID="' + (optionID || '') + '"'
                    + 'data-option-selected-value="' + (valueId || '') + '"'
                    + '>'
                    + '<div class="col-sm-11 col-9 bonus-product-name" >'
                    + $choiceOfBonusProduct.find('.product-name').html()
                    + '</div>'
                    + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
                    + '</div>'
                    ;
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));
            var pid = response.product.id;
            $('button.select-bonus-product').data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption =
                    parseInt($(this)
                        .data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option]
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');

            $('.cart-line-items').spinner().start();

            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('.error-choice-of-bonus-products')
                            .html(data.errorMessage);
                    } else {
                        $('.cart-line-items').empty().append(data.renderedTemplate);
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append(
                                '<div class="add-to-cart-messages"></div>'
                            );
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"'
                            + ' role="alert">'
                            + data.msgSuccess + '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 3000);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    waitForImagesInit: function () {
        $.fn.waitForImages = function (callback) {
            var $img = $('img', this),
                totalImg = $img.length;

            var waitImgLoad = function () {
                totalImg--;
                if (!totalImg) {
                    callback();
                }
                $(this).on('error', callback);
            };

            $img.each(function () {
                if (this.complete) {
                    waitImgLoad();
                }
            });

            $img.on('load', waitImgLoad);
        };
    },

    loadSpecifications: function () {
        $('.spec-details-image-container').each(function () {
            loadSchematicDrawings($(this).data('url'), $(this));
        });

        $('.spec-details-text-container').each(function () {
            loadTextSpecifications($(this).data('url'), $(this));
        });
    },
    updateAddToCartBtn: function () {
        $('body').on('click', '.update-cart-product-pdp', function (e) {
            e.preventDefault();

            var updateProductUrl = $(this).data('update-product-url');
            var selectedQuantity = $('.quantity-select').val();
            var uuid = $(this).data('uuid');

            var $sampleRalColor = $('.sample_ral_color');
            var $sampleColorBrand = $('.sample_brand');
            var $sampleColorName = $('.sample_name');
            var $sampleColorCode = $('.sample_code');

            var form = {
                uuid: uuid,
                pid: $(this).data('pid'),
                quantity: selectedQuantity
            };

            if ($sampleRalColor.length > 0) {
                form[$sampleRalColor.attr('name')] = $sampleRalColor.children('option:selected').val();
            }

            if ($sampleColorBrand.length > 0) {
                form[$sampleColorBrand.attr('name')] = $sampleColorBrand.val();
            }

            if ($sampleColorName.length > 0) {
                form[$sampleColorName.attr('name')] = $sampleColorName.val();
            }

            if ($sampleColorCode.length > 0) {
                form[$sampleColorCode.attr('name')] = $sampleColorCode.val();
            }

            form.options = getOptions($(this));

            if (uuid) {
                form.uuid = uuid;
            }

            if (updateProductUrl) {
                $.spinner().start();
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        if (data.redirectUrl) {
                            window.location.href = data.redirectUrl;
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    }
};
