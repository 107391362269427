/* eslint-disable no-new, no-undef */
'use strict';

/**
 * @package     Blueacorn/LazyLoading
 * @version     1.0
 * @author      Jamie Smith <jamie@blueacorn.com>
 * @copyright   Copyright © 2016 Blue Acorn.
 */

module.exports = function () {
    var LazyLoading = {
        settings: {
            debug: false,
            moduleName: 'lazyLoading'
        },

        init: function () {
            this.bLazy = new Blazy({
                selector: '.js-blazy',
                loadInvisible: true,
                success: function (ele) {
                    $(ele).closest('.js-blazy-wrapper').addClass('js-blazy-wrapper--finished');
                }
            });
        },

        revalidate: function () {
            this.bLazy.revalidate();
        },

        initObserver: function () {
            var self = this;

            $(document).on('search:updated collapse:open', function () {
                self.init();
                self.revalidate();
            });

            $(document).on('blazy:revalidate', function () {
                self.revalidate();
            });
        }
    };

    // Init
    LazyLoading.init();
    LazyLoading.initObserver();
};
/* eslint-enable no-new, no-undef */
