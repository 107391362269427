'use strict';

module.exports = function () {
    // Overlay that is displayed untill page is fully loaded
    $(window).on('pageshow', function () {
        $('.white-overlay').css('opacity', '1');
    });

    $(window).on('load', function () {
        $('.white-overlay').css('opacity', '1');

        $(document).trigger('pageLoaded:init');
    });

    $(document).ready(function () {
        $('.white-overlay').css('opacity', '1');
    });
};
