'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var root = document.documentElement;

module.exports = {
    initSrollLinks: function () {
        $(document).on('click', '.deep-link', function () {
            var scrollTo = $(this).data('link-to');
            scrollAnimate($(scrollTo));
        });
    },

    initCustomSliderCounter: function () {
        $(document).on('afterChange', '.custom-slider', function (event, slick, currentSlide) {
            var $counter = $(this).closest('.custom-carousel-wrapper').find('.custom-slider-counter');

            $counter.text(currentSlide + 1);
        });
    },

    replaceHomePageCarets: function () {
        var homepageid = document.getElementById('homepage');
        if (homepageid) {
            $('.link-arrow').addClass('caret-font-link').append('<span class="caret-font"> > </span>');
        }
    },
    setCarouselSlideCreditWidthMobile: function () {
        if ($('.experience-layouts-carousel')) {
            var screenWidth = screen.width;
            var mobileWidth = $('.experience-layouts-carousel .carousel.slick-slider .slick-list .slide.slick-active .carousel-slide-mobile-width').val();
            var imageCredit = $('.experience-layouts-carousel .carousel.slick-slider .slick-list .slide .carousel-slide .image-credit');
            var imageCreditP = imageCredit.find('p');
            var carouselStyles = document.querySelectorAll('.hidden-styles .carousel-credit-style');
            root.style.setProperty('--carousel-credit-width-mobile', mobileWidth);
            imageCreditP.attr('style', 'opacity: 0;');

            carouselStyles.forEach(function (style) {
                var cssName = style.name;
                var cssValue = style.value;
                root.style.setProperty(
                    cssName, cssValue
                );
            });

            $(document).ready(function () {
                setTimeout(function () {
                    imageCreditP.attr('style', 'opacity: 1;transition: opacity 0.5s;');
                }, 700);
            });

            $(window).on('resize', function () {
                screenWidth = screen.width;
            });

            $(document).on('click', '.experience-layouts-carousel .carousel.slick-slider .slick-arrow', function () {
                if (screenWidth < 768) {
                    imageCredit.fadeOut(200);
                }
            });

            $(document).on('afterChange', '.experience-layouts-carousel .slick-slider', function () {
                if (screenWidth < 768) {
                    var mobileWidthChanged = $('.experience-layouts-carousel .carousel.slick-slider .slick-list .slide.slick-active .carousel-slide-mobile-width').val();
                    root.style.setProperty('--carousel-credit-width-mobile', mobileWidthChanged);
                    imageCredit.fadeIn(200);
                }
            });
        }
    }
};
