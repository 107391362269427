'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var formValidation = require('base/components/formValidation');
var recaptcha = require('ba-base/components/reCaptcha');
var utils = require('./utils');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

// Login popup events
var loginEvents = {
    // Event to open login modal in header login icon
    openPopup: function () {
        $('.login-button.logged-out, .edit-notloggedin-button').on('click', function (e) {
            e.preventDefault();
            $('body').trigger('display:loginModal');
        });
    },

    // Focus the first input when popup is opened
    openFocusEmail: function () {
        $('#loginModal').on('shown.bs.modal', function () {
            $('#login-form-email').focus();
        });
    },
    // Remove white background when login popup is closed
    removeBackgroundPopup: function () {
        $('#loginModal').on('hide.bs.modal', function () {
            $('checkoutLoginModal').removeClass('modal-backdrop-white');
        });
    },
    // Open create account popup with 2 buttons
    openCreateAccPopup: function () {
        $(document).on('click', '.btn-create-new-account', function () {
            $('#loginModal').modal('hide');
            $('#createAccountModal').modal('show');
        });
    },
    // The submit function goes to the callback of recaptcha.attach
    loginSubmit: function (e) {
        var form = $('form.login');
        var url = form.attr('action');
        $('form.login').trigger('login:submit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                if (!data.success) {
                    formValidation(form, data);
                    $('form.login').trigger('login:error', data);
                } else {
                    $('form.login').trigger('login:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    $('form.login').trigger('login:error', data);
                }
            }
        });
        return false;
    },
    openLoginModal: function () {
        if (this.getUrlParameter('login') === 'true') {
            $('body').trigger('display:loginModal');
            $('body').addClass('modal-backdrop-white');
        }
    },
    getUrlParameter: function (sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    }
};

module.exports = function () {
    var $formLogin = $('form.login');

    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };

    $(function () {
        loginEvents.openLoginModal();
    });

    loginEvents.openPopup();
    enquire.register('screen and (min-width: ' + globalConfig.bp.medium + 'px)', {
        match: function () {
            loginEvents.openFocusEmail();
        }
    });
    loginEvents.removeBackgroundPopup();
    loginEvents.openCreateAccPopup();

    if (SitePreferences.RECAPTCHA_ENABLED && $formLogin.length > 0) {
        recaptcha.attach($formLogin, loginEvents.loginSubmit);
    }
    recaptcha.init();

    $(document).on('submit', 'form.login', function (e) {
        e.preventDefault();

        if (SitePreferences.RECAPTCHA_ENABLED) {
            recaptcha.showReCaptcha('login-form');
        } else {
            loginEvents.loginSubmit(e);
        }
    });

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    if (utils.getParameter('discontinued')) {
        $('#productNotAvailable').modal('show');
        var discontinuedArray = utils.getParameter('discontinued').split(',');
        var containerVal = [];

        $.each(discontinuedArray, function (index, el) {
            var decodedVal = decodeURIComponent(decodeURI(el));

            containerVal.push(decodedVal);
        });

        $('.notavailable-cart-item').html(containerVal.join(', '));
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]').not('.custom-dropdown-toggle')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
    });

    /**
     * Open mega menu
     */
    function openMegaMenu() {
        $('.minicart .popover').removeClass('show');
        $('.notifications .popover').removeClass('show');
        $('#btn-my-account').attr('aria-expanded', 'false');
        $('.navbar-header .user .popover').removeClass('show');

        $('.main-menu').toggleClass('in');
        $('.menu-toggleable-left').removeClass('position-static-mobile');
        $('.modal-background').show();
        $('.modal-background').addClass('open-menu');

        $('.main-menu').removeClass('d-none');
        $('.main-menu').attr('aria-hidden', 'false');
        $('.main-menu').siblings().attr('aria-hidden', 'true');
        $('header').siblings().attr('aria-hidden', 'true');

        $('.main-menu .nav.navbar-nav .nav-link').first().focus();
    }

    /**
     * Close mega menu
     */
    function closeMegaMenu() {
        $('.menu-toggleable-left').removeClass('in');
        $('.modal-background').hide();
        $('.modal-background').removeClass('open-menu');
        $('.main-menu').attr('aria-hidden', 'true');
    }

    $('.navbar-toggler').click(function () {
        openMegaMenu();
    });

    $('.navbar>.top-bar>.close-menu>.close-button').on('click', function () {
        closeMegaMenu();
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.user .popover li.nav-item');
            return $popover;
        }
    );

    // Header User Menu
    var selUserMenu = '.navbar-header .user';
    var selPopover = selUserMenu + ' .popover';
    var selNavItem = '.user-nav-item';
    var btnMyAccount = '#btn-my-account';

    /**
     * Opens user menu popover
     */
    function openPopover() {
        if ($(selPopover).length > 0) {
            $(selPopover).addClass('show');
            $(btnMyAccount).attr('aria-expanded', 'true');
        }

        $('.modal-background').show();
        $('.modal-background').removeClass('open-menu');
    }

    /**
     * Closes user menu popover
     */
    function closePopover() {
        $(btnMyAccount).attr('aria-expanded', 'false');
        $(selPopover).removeClass('show');

        $('.modal-background').hide();
    }

    $(document).on('click', btnMyAccount, function (event) {
        event.preventDefault();

        $('.minicart .popover').removeClass('show');
        $('.notifications .popover').removeClass('show');

        closeMegaMenu();

        if ($(selPopover).hasClass('show')) {
            closePopover(true);
        } else {
            openPopover(true);
        }
    });

    $('body').on('click', '#myaccount', function () {
        event.preventDefault();
    });

    keyboardAccessibility(selUserMenu, {
        40: function ($navItems) { // down
            var $focused = $navItems.find('a:focus');

            if ($focused.length) {
                $focused.closest(selNavItem).next().children().first()
                    .focus();
            } else {
                openPopover(true);
            }
        },
        38: function ($navItems) { // up
            var $focused = $navItems.find('a:focus');

            if ($navItems.children('a').first().is(':focus')) {
                closePopover(true);
            } else if ($focused.length && $focused.closest(selNavItem).prev().length) {
                $focused.closest(selNavItem).prev().children('a').first()
                    .focus();
            }
        },
        27: function () { // escape
            closePopover(true);
        },
        9: function () { // tab
            closePopover(true);
        }
    },
    function () {
        return $(selNavItem);
    });

    enquire.register('screen and (min-width: ' + (globalConfig.bp.large + 1) + 'px)', {
        match: function () {
            $('.navbar>.close-menu>.close-button').click();
        }
    });

    $('body').on('click', function (e) {
        if (($('.navbar-header').has(e.target).length <= 0) && ($('.menu-toggleable-left').has(e.target).length <= 0)) {
            // on 320 we want .modal-background to show for the share modal
            // .modal-background gets hidden by closePopover()
            if (e.target.hasClass === 'icon-share') {
                $(btnMyAccount).attr('aria-expanded', 'false');
                $(selPopover).removeClass('show');
            } else {
                closePopover(true);
            }

            $('.navbar-nav').find('.top-category').detach();
            $('.navbar-nav').find('.nav-menu').detach();
            $('.navbar-nav').find('.show').removeClass('show');
            $('.menu-toggleable-left').removeClass('in');

            $('.main-menu').siblings().attr('aria-hidden', 'true');
            $('header').siblings().attr('aria-hidden', 'true');

            $('.minicart .popover').removeClass('show');
            $('.notifications .popover').removeClass('show');

            $('.modal-background').removeClass('open-menu');
        }
    });

    $(document).on('click', '.experience-layouts-carousel .slide-down', function (e) {
        e.preventDefault();
        var $parent = $(this).closest('.experience-layouts-carousel');
        var $header = $('header');
        var headerHeight = $header ? $header.height() : 0;
        var target = ($parent.offset().top + $parent.height()) - headerHeight;
        $('html, body').animate({ scrollTop: target }, 1000);
    });

    $('body').on('display:loginModal', function () {
        var $loginModal = $('#loginModal');
        var url = $loginModal.data('url');

        var rurlExists = utils.getParameter('rurl');
        var dataAction = $('.page').length ? $('.page').data('action') : null;
        if (rurlExists || (dataAction && ['Product-Show', 'Product-ShowInCategory', 'Search-Show'].indexOf(dataAction) !== -1)) {
            var currentUrl = utils.removeParam('login', rurlExists ? decodeURIComponent(rurlExists) : window.location.href);
            var rurl = utils.updateQueryStringParameter(url, 'rurl', encodeURIComponent(currentUrl));

            url = rurl;
        }
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $loginModal.find('.modal-body').empty().html(data);
                $loginModal.modal('show');
                $('body').addClass('modal-backdrop-white');
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.main-menu .dropdown-link', e => {
        var $subnav = $(e.target),
            $topnav = $subnav.parents('.nav-item').find('a.nav-link.title');

        window.dataLayer.push({
            event: 'taxonomyNav',
            taxonomy_string: `${$topnav.text()} > ${$subnav.text()}`
        });
    });
};
